import { observer } from "mobx-react-lite";
import OrdersPageStyled from "./orders.page.styled";
import PageTemplateComponent from "../../../../components/page-template/page-template.component";
import { useStore } from "../../../../hooks/store.hook";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PanelType } from "../../../../enums/panel-type.enum";
import CreateOrderPanel from "../../../../components/create-order-panel/create-order-panel.component";
import { deleteOrderCommand, getAllOrders, cancelOrderCommand, updateOrderStatusCommand, confirmOrderBulk, getDictionaryOrderStatuses } from "../../../../utils/requests";
import { displayCustomToast } from "../../../../utils/display-custom-toast";
import TableComponent, { CustomRendererPropsType, TableDataPropsType } from "../../../../components/table/table.component";
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import { HeaderTypeEnum } from "../../../../enums/header-type.enum";
import { DeleteOutlined, EditOutlined, DoNotDisturbOutlined, SyncAltOutlined } from "@mui/icons-material";
import { DialogResponseTypeEnum } from "../../../../enums/dialog-response-type.enum";
import { isEmpty } from "lodash";
import { OrderStatusEnum } from "../../../../enums/order-status.enum";
import { DatePicker } from "@mui/x-date-pickers";
import TextFieldComponent from "../../../../components/text-field/text-field.component";
import { formatDate } from "../../../../utils/utils";
import TooltipComponent from "../../../../components/tooltip/tooltip.component";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ReportExcelModal from "./components/report-excel-modal/report-excel-modal.component";
import { Chip } from "@mui/material";
import { themeVariables } from "../../../../theme/variables";

export const statusColor: any = {
    [OrderStatusEnum.New]: "#A4ABB6",
    [OrderStatusEnum.Submitted]: themeVariables.colors.success,
    [OrderStatusEnum.Cancelled]: themeVariables.colors.error,
}

const OrdersPage = observer(() => {

    const uiStore = useStore('uiStore');
    const [refreshData, setRefreshData] = useState<boolean>(true);
    const [data, setData] = useState<any>();
    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [filtersTable, setFilters] = useState<any>({
        pageIndex: 0,
        pageSize: 5,
        selectFilterValue: null,
        secondSelectFilterValue: null,
        thirdSelectFilterValue: null,
        tabFilterValue: undefined,
        searchText: null
    });
    const [actionItemsClick, setActionItemsClick] = useState<any>();
    const [orderDate, setOrderDate] = useState<any>(null)
    const [deliveryDate, setDeliveryDate] = useState<any>(null);
    const [orderStatues, setOrderStatues] = useState<any[]>([]);


    const createData = useCallback(
        () => {
            uiStore.openPanel({
                key: PanelType.CreateOrder,
                component: <CreateOrderPanel />,
                panelWidth: '800px',
                title: `Creare comanda`,
                onDismiss: (data: any) => {
                    if(!data) return;
                    
                    if(data?.refreshData)
                        setRefreshData(() => true);
                }
            });
        },
        []
    )

    const confirmOrderBulkAction = useCallback(
        async () => {
            const response = await uiStore.openDialog({
                title: "Proceseaza toate comenzile noi",
                message: "Esti sigur ca vrei sa treci toate comenzile din statusul Noua in Procesata?"
            })

            if(response.value === DialogResponseTypeEnum.Confirm) {
                try{
                    setDataLoading(() => true);
                    await confirmOrderBulk();
                    displayCustomToast("Statusul comenzii a fost actualizat");
                    setDataLoading(() => false);
                    setRefreshData(() => true);
                }catch (error: any) {
                    displayCustomToast(error.content, true);
                    setDataLoading(() => false);
                }
            }
        },
        []
    )

    const getOrderStatues = async () => {
        
        let orderStatues = await getDictionaryOrderStatuses();
        setOrderStatues(() => orderStatues);
    }

    async function getData() {
        try {
            setDataLoading(() => true)

            await getOrderStatues();

            let fetchData = await getAllOrders({
                pageIndex:filtersTable.pageIndex + 1,
                pageSize:filtersTable.pageSize,
                searchText:filtersTable.searchText,
                partnerId: filtersTable.selectFilterValue?.value,
                salePointId: isEmpty(filtersTable.selectFilterValue?.value?.toString())  ? null : filtersTable.secondSelectFilterValue?.value,
                deliveryRouteId: filtersTable.thirdSelectFilterValue?.value,
                orderStatusId: filtersTable.tabFilterValue,
                orderDate: isEmpty(orderDate) ? null : formatDate(orderDate),
                deliveryDate: isEmpty(deliveryDate) ? null : formatDate(deliveryDate)
            });
            setData(() => fetchData);
            setDataLoading(() => false)
        } catch (error: any) {
            displayCustomToast(error?.content, true);
            setDataLoading(() => false)
        }
    }

    useEffect(
        () => {            
            if(!refreshData) return;

            getData();
            setRefreshData(() => false);
            
        },
        [filtersTable, refreshData]
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!data) return {
                data: [],
                headers: []
            }

            return {
                data: data.data,
                headers: [
                   {
                       id: 'id',
                       label: 'Id',
                       headerType: HeaderTypeEnum.Numeric,
                       alignment: 'left',
                       sortable: false
                   },
                   {
                        id: 'partner',
                        label: 'Partener',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'description',
                        label: 'Descriere',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'orderStatus',
                        label: 'Status comanda',
                        headerType: HeaderTypeEnum.Boolean,
                        alignment: 'center',
                        sortable: false
                    }
                ],
                totalElements: data.totalNumber
            }
        },
        [data]
    )

    const actionItems: any[] = useMemo(
        () => {
            return [
                {
                    text: "Editeaza comanda",
                    icon: <EditOutlined />,
                    color: "black",
                    fOnClick: (row: any) => {
                        uiStore.openPanel({
                            key: PanelType.EditItem,
                            component: <CreateOrderPanel 
                                orderId={row.id} 
                                orderDetails={row}
                            />,
                            panelWidth: '800px',
                            title: `Editeaza comanda: #${row.id}`,
                            onDismiss: (data: any) => {
                                if(!data) return;
                                
                                if(data?.refreshData)
                                    setRefreshData(() => true);
                            }
                        });
                    }
                },
                {
                    text: actionItemsClick?.nextOrderStatus?.name,
                    icon: <SyncAltOutlined />,
                    color: "black",
                    async fOnClick(row: any){
                        if(row){
                            const response = await uiStore.openDialog({
                                title: "Schimba status comanda...",
                                message: "Esti sigur ca vrei sa schimbi statusul comenzii?"
                            })

                            if(response.value === DialogResponseTypeEnum.Confirm) {
                                try{
                                    setDataLoading(() => true);
                                    await updateOrderStatusCommand({
                                        orderId: row.id,
                                        orderStatusId: row.nextOrderStatus.id
                                    });
                                    displayCustomToast("Statusul comenzii a fost actualizat");
                                    setDataLoading(() => false);
                                    setRefreshData(() => true);
                                }catch (error: any) {
                                    displayCustomToast(error.content, true);
                                    setDataLoading(() => false);
                                }
                            }
                        }
                    },
                    canDisplay: (row: any) => row.nextOrderStatus?.id !== 0
                },
                {
                    text: "Anuleaza comanda",
                    icon: <DoNotDisturbOutlined />,
                    color: "green",
                    async fOnClick(row: any){
                        if(row){
                            const response = await uiStore.openDialog({
                                title: "Anuleaza comanda...",
                                message: "Esti sigur ca vrei sa anulezi comanda?"
                            })

                            if(response.value === DialogResponseTypeEnum.Confirm) {
                                try{
                                    setDataLoading(() => true);
                                    await cancelOrderCommand({
                                        orderId: row.id
                                    });
                                    displayCustomToast("Comanda a fost anulata");
                                    setDataLoading(() => false);
                                    setRefreshData(() => true);
                                }catch (error: any) {
                                    displayCustomToast(error.content, true);
                                    setDataLoading(() => false);
                                }
                            }
                        }
                    },
                    canDisplay: (row: any) => row.nextOrderStatus?.id !== OrderStatusEnum.Cancelled
                },
                {
                    text: "Sterge comanda",
                    icon: <DeleteOutlined />,
                    color: "red",
                    async fOnClick(row: any){
                        if(row) {   
                            const response = await uiStore.openDialog({
                                title: "Stergi comanda...",
                                message: "Esti sigur ca vrei sa continui?",
                            })    

                            if(response.value === DialogResponseTypeEnum.Confirm) {
                                try {
                                    setDataLoading(() => true);
                                    await deleteOrderCommand({
                                        orderId: row.id
                                    });
                                    displayCustomToast("Comanda a fost stearsa");
                                    setDataLoading(() => false);
                                    setRefreshData(() => true);
                                }
                                catch (error: any) {
                                    displayCustomToast(error.content, true);
                                    setDataLoading(() => false)
                                }
                            }
                                
                        } 
                    }
                }
            ]
        },
        [actionItemsClick]
    )

    const customRenderer: CustomRendererPropsType = useMemo(
        () => {
            return {
                description: (row: any) => {
                    return (
                        <>
                        {
                            isEmpty(row.description) ?
                                "-"
                                :
                                <TooltipComponent title={row.description ?? ''} titleLimit={90} />
                        }
                        </>
                    )
                },
                orderStatus: (row: any) => {
                    return (
                        <>
                            <Chip 
                                label={row.orderStatus.name} 
                                sx={{
                                    color: statusColor[row.orderStatus.id],
                                    border: `1px solid ${statusColor[row.orderStatus.id]}`
                                }}
                                variant="outlined" 
                            />
                        </>
                    )
                },
                partner: (row: any) => {
                    return (
                        <>
                            {row.partner.name}
                        </>
                    )
                },
            }
        },
        []
    )

    var tabsOptions = useMemo(
        () => {
            return [
                {
                    label: "Toate",
                    value: null,
                },
                ...orderStatues.filter(f => f.isActive).map(status => ({
                    label: status.name,
                    value: status.id
                }))
            ]
        },
        [orderStatues]
    )

    const downloadRaport = async () => {

        uiStore.openModal({
            title: "Descarcă raport",
            component: <ReportExcelModal />
        })
    }

    return (
        <OrdersPageStyled>
            <PageTemplateComponent 
                title="Comenzi"
                buttons={[
                    // {
                    //     fOnClick: () => {
                    //         confirmOrderBulkAction()
                    //     },
                    //     text: "Confirmare comenzi",
                    // },
                    {
                        fOnClick: () => {
                            createData()
                        },
                        text: "Creează"
                    }
                ]}
            >
                {/* <div
                    style={{
                        display: "flex",
                        marginBottom: "1rem"
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            padding: "0.5rem"
                        }}
                    >
                        <DatePicker
                            value={orderDate}
                            renderInput={(params) => <TextFieldComponent 
                                {...params} 
                                fullWidth={true} 
                                variant="outlined" 
                                label="Data comanda"
                            />}
                            onChange={newValue =>{ 
                                setOrderDate(() => newValue)
                                setRefreshData(() => true);
                            }}
                            mask="__.__.____"
                            
                        />
                    </div>
                    
                    <div
                        style={{
                            width: "100%",
                            padding: "0.5rem"
                        }}
                    >
                        <DatePicker
                            value={deliveryDate}
                            renderInput={(params) => <TextFieldComponent 
                                {...params} 
                                fullWidth={true} 
                                variant="outlined" 
                                label="Data livrare" 
                            />}
                            onChange={newValue => {
                                setDeliveryDate(() => newValue)
                                setRefreshData(() => true);
                            }}
                            mask="__.__.____"
                        />
                    </div>
                </div> */}

                <TableComponent
                    tableKey={TableTypeEnum.AllOrders} 
                    viewType="normal"
                    tableData={tableData}
                    // searchBarPlaceholder="Cauta dupa nume"
                    withoutSearchBar
                    withoutSelect
                    isLoading={dataLoading}
                    tableState={filtersTable}
                    setTableState={setFilters}
                    actionItemsClick={setActionItemsClick}
                    setRefreshData={setRefreshData}
                    customRenderer={customRenderer}
                    actionItems={actionItems}
                    tabs={tabsOptions}
                />  
            </PageTemplateComponent>
        </OrdersPageStyled>
    )
})

export default OrdersPage;