import { observer } from "mobx-react-lite";
import CreateOrderPanelStyled from "./create-order-panel.component.styled";
import { useEffect, useMemo, useState } from "react";
import { useStore } from "../../hooks/store.hook";
import { cloneDeep, isEmpty } from "lodash";
import { StateFieldType } from "../../types/form.types";
import { displayCustomToast } from "../../utils/display-custom-toast";
// import { FormHelperText, Grid, LinearProgress } from "@mui/material";
import ButtonComponent from "../button/button.component";
import TableComponent, { ActionItemPropsType, CustomRendererPropsType, TableActionsPropsType, TableDataPropsType } from "../table/table.component";
import { HeaderTypeEnum } from "../../enums/header-type.enum";
import { TableTypeEnum } from "../../enums/table-type.enum";
import { createOrderAdminCommand, getOrderDetails, updateOrderAdminCommand } from "../../utils/requests";
import CloseIcon from '@mui/icons-material/Close';
import { themeVariables } from "../../theme/variables";
import TextFieldComponent from "../text-field/text-field.component";
import Grid from "@mui/material/Grid";
import FormHelperText from "@mui/material/FormHelperText";
import { PanelType } from "../../enums/panel-type.enum";
import { Add } from "@mui/icons-material";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import SelectOrderProductsComponent from "./components/select-order-products/select-order-products.component";
import SelectOrderItemsComponent from "./components/select-order-items/select-order-items.component";
import LoaderComponent from "../loader/loader.component";
import { Box } from "@mui/material";
import AsyncSelectComponent from "../async-select/async-select.component";
import { OrderStatusEnum } from "../../enums/order-status.enum";

export type CreateOrderPanelPropsType = {
    orderId?: number,
    orderDetails?: any
}

export type StateType = {
    fields: {
        description: StateFieldType<string>;
        orderItems: StateFieldType<any[]>,
        orderItemsInventory: StateFieldType<any[]>,
        orderStatusId?: StateFieldType<number>,
        partner: StateFieldType<any>
    };
    shouldDisplayError: boolean;
}

const CreateOrderPanel = observer(({
    orderId,
    orderDetails
}: CreateOrderPanelPropsType) => {

    const [formGroup, setFormGroup] = useState<StateType>({
        fields: {
            description: {
                value: "",
                isValid: true,
                noValidation: true
            },
            orderStatusId: {
                value: 0,
                isValid: true,
                noValidation: true
            },
            orderItemsInventory: {
                value: [],
                isValid: false,
                noValidation: false
            },
            orderItems: {
                value: [],
                isValid: false,
                noValidation: false
            },
            partner: {
                value: null,
                isValid: false,
                noValidation: false
            },
        },
        shouldDisplayError: false
    });

    const uiStore = useStore('uiStore');
    const [filtersTable, setFilters] = useState<any>({
        pageIndex: 0,
        pageSize: 5,
      //   selectFilterValue: null,
      //   secondSelectFilterValue: null,
      //   tabFilterValue: undefined,
        searchText: null
    });
    const [actionItemsClick, setActionItemsClick] = useState<any>();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [loadingData, setLoadingData] = useState<boolean>(orderId != undefined ? true : false);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const updateState =  <T extends keyof any>(field: T, newValue: any) => {
        setFormGroup((state: any) => ({
            ...state,
            fields: {
                ...state.fields,
                [field]: {
                    ...state.fields[field],
                    isValid: isEmpty(newValue?.toString()) ? false : true,
                    value: newValue
                }
            }
        }))
    }

    useEffect(
        () => {
            setIsLoading(() => false);
        },
        []
    )

    const editOrderDetails = async (orderId: number) => {

        try {
            setLoadingData(() => true);
            let orderDetails = await getOrderDetails(orderId);
            setLoadingData(() => false);
            
            updateState('description', orderDetails.description);

            updateState('orderStatusId', orderDetails.orderStatusId);

            updateState('partner', {
                value: orderDetails.partnerId,
                label: orderDetails.partnerName
            });

            updateState('orderItems', orderDetails.orderItems.map((m: any) => ({
                ...m,                
                orderItemId: m.id,
                id: m.itemId,
                name: m.itemName,
            })));

            updateState('orderItemsInventory', orderDetails.orderItemsInventory.map((m: any) => ({
                ...m,
                id: m.inventoryId,
                name: m.item.name,
            })));

        } catch (error: any) {
            displayCustomToast(error.content, true);
            setLoadingData(() => false);
        }
    }

    useEffect(
        () => {
            if(!orderId) return;

            editOrderDetails(orderId);
        },
        [orderId]
    )

    const createAction = async () => {
        setIsSaving(() => true);
        if(!formGroup) return;

        const isNotValid = Object.values(formGroup.fields).filter((f: any) => f.noValidation === false).some((field: any) => !field.isValid);
        if (isNotValid) {
            setIsSaving(() => false);
            setFormGroup((prevState: any) => ({
                ...prevState,
                shouldDisplayError: true
            }))
            displayCustomToast("Datele nu sunt valide!", true);
            return;
        };

        let copyOrderItemsInventory = cloneDeep(formGroup.fields.orderItemsInventory.value);

        copyOrderItemsInventory = copyOrderItemsInventory.map((v: any) => {
            if(v.quantity < 0)
                v.error = true
            else 
                v.error = false

            return {
                ...v
            }
        })

        setFormGroup((prevState: any) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                orderItemsInventory: {
                    ...prevState.fields.orderItemsInventory,
                    value: prevState.fields.orderItemsInventory.value.map((v: any) => {
                        if(v.quantity < 0)
                            return {
                                ...v,
                                error: true
                            }
                        else 
                            return {
                                ...v,
                                error: false
                            }
                    })
                }
            }
        }))

        const isNotValidOrderItemsInventory = copyOrderItemsInventory.some((field: any) => field.error);
        
        if (isNotValidOrderItemsInventory) {
            setIsSaving(() => false);
            displayCustomToast("Nu ati introdus cantitati pentru produsele selectate!", true);
            return;
        }; 

        // let copyReceiptProducts = cloneDeep(receipt.receiptProducts);

        // copyReceiptProducts = copyReceiptProducts.map((r: any) => {
        //     let sum = formGroup.fields.itemReceiptRawProducts.value
        //         .filter(f => f.productType.id === r.productType.id)
        //         .map(m => parseFloat(isEmpty(m.quantity.toString()) ? 0 : m.quantity))
        //         .reduce((prev: number, current: number) => prev + current, 0);

        //     if(sum !== r.value) {
                
        //         setReceipt((prev: any) => ({
        //             ...prev,
        //             receiptProducts: prev.receiptProducts.map((m: any) => ({
        //                 ...m,
        //                 id: m.receiptProductId,
        //                 isError: true
        //             }))
        //         }));

        //         return {
        //             ...r,
        //             isError: true
        //         }
        //     }
        //     else {

        //         setReceipt((prev: any) => ({
        //             ...prev,
        //             receiptProducts: prev.receiptProducts.map((m: any) => ({
        //                 ...m,
        //                 id: m.receiptProductId,
        //                 isError: false
        //             }))
        //         }));

        //         return {
        //             ...r,
        //             isError: false
        //         }
        //     }
        // });

        // const isNotValidReceiptProductsSums = copyReceiptProducts.some((field: any) => field.isError);
    

        // if (isNotValidReceiptProductsSums) {
        //     setIsSaving(() => false);
        //     displayCustomToast("Cantitatile nu sunt valide!", true);
        //     return;
        // };

        let body = {
            orderItemsInventory: formGroup.fields.orderItemsInventory.value.map((v: any) => ({
                inventoryId: v.id,
                measurementUnitId: v.measurementUnit.id,
                quantity: v.quantity,
                itemId: v.item.id
            })),
            orderItems: formGroup.fields.orderItems.value.map((v: any) => ({
                itemId: v.id,
                quantity: formGroup.fields.orderItemsInventory.value
                    .filter(f => f.item.id == v.id)
                    .reduce(
                        (prev: string, current: any) => parseFloat(prev) + parseFloat(current.quantity) ?? 0, 0
                    )
            })),
            description: formGroup.fields.description.value,
            partnerId: formGroup.fields.partner?.value?.value
        }

        try {
            if(orderId != undefined){
                let bodyUpdate = {
                    orderId: orderId,
                    ...body
                }
                await updateOrderAdminCommand(bodyUpdate)
            }
            else
                await createOrderAdminCommand(body);

            setIsSaving(() => false);
            displayCustomToast("Datele au fost salvate cu succes!");
            uiStore.dismissPanel({
                data: {
                    refreshData: true
                }
            });
        } catch (error: any) {
            displayCustomToast(error.content, true);
            setIsSaving(() => false);
        }
    }

    const renderExpandable = (row: any, tableActions: TableActionsPropsType) => {

        let tableData: any;

        if(isEmpty(formGroup.fields.orderItemsInventory.value.filter(f => f.item.id == row.id))) 
            tableData = {
                data: [],
                headers: []
            }
            else
            tableData = {
                data: formGroup.fields.orderItemsInventory.value.filter(f => f.item.id == row.id),
                headers: [
                    {
                        id: 'name',
                        label: 'Nume',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'left',
                        sortable: false
                    },
                    {
                        id: 'availableQuantity',
                        label: 'Cantitate disponibila',
                        headerType: HeaderTypeEnum.Numeric,
                        alignment: 'center',
                        sortable: false
                    }, 
                    {
                        id: 'quantity',
                        label: 'Cantitate',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'left',
                        sortable: false
                    },                         
                    {
                        id: 'measurementUnit',
                        label: 'Unitate de masura',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },               
                ],

            }

        const customRenderer: CustomRendererPropsType = {
                    measurementUnit: (row: any) => {
                        return (
                            <>
                                {row.measurementUnit?.name}
                            </>
                        )
                    },
                    quantity: (row: any) => {
                        return (
                            <>
                                <TextFieldComponent
                                    label="Cantitate"
                                    variant="outlined"
                                    value={row.quantity}
                                    error={row.error && row.quantity < 0}
                                    onTextChange={value => {
                                        updateState('orderItemsInventory', formGroup.fields.orderItemsInventory.value.map((m: any) => ({
                                            ...m,
                                            quantity: m.id === row.id ? 
                                                m.availableQuantity - parseFloat(value) < 0 ?
                                                    m.availableQuantity
                                                    :
                                                    value
                                                : 
                                                m.quantity
                                        })));
                                    }}
                                    type="number"
                                    required
                                    style={{
                                        marginRight: 0
                                    }}
                                    width={120}
                                    disabled={[OrderStatusEnum.Cancelled, OrderStatusEnum.Submitted].includes(formGroup.fields.orderStatusId?.value ?? 0)}
                                />
                            </>
                        )
                    },
                    name: (row: any) => {
                        return (
                            <>
                                {
                                    row.item.name
                                }
                            </>
                        )
                    },
        }

        const actionItems: any[] = [
            {
                text: "Elimina produsul",
                icon: <CloseIcon color="error"  />,
                color: "red",
                fOnClick: (row: any) => {
                    setFormGroup((state: any) => ({
                        ...state,
                        fields: {
                            ...state.fields,
                            orderItemsInventory: {
                                ...state.fields.orderItemsInventory,
                                value: state.fields.orderItemsInventory.value.filter((f: any) => f.id != row.id)
                            }
                        }
                    }))
                },
                canDisplay: () => ![OrderStatusEnum.Cancelled, OrderStatusEnum.Submitted].includes(formGroup.fields.orderStatusId?.value ?? 0)
            }
        ]

        return (
            <TableComponent
                tableKey={TableTypeEnum.SelectedItems} 
                viewType="read-only"
                tableData={tableData}
                searchBarPlaceholder="Cauta dupa nume"
                withoutSelect
                tableState={filtersTable}
                setTableState={setFilters}
                withoutSearchBar
                withoutDenseSwitch
                denseByDefault
                customRenderer={customRenderer}
                actionItems={actionItems}
                actionItemsClick={setActionItemsClick}
                noRequest
            />
        )
    }

    const onChooseProducts = () => {
        uiStore.openPanel({
            key: PanelType.AddProductOrder,
            title: "Selecteaza produsele",
            panelWidth: '800px',
            component: <SelectOrderItemsComponent
                selectedItems={formGroup.fields.orderItems.value}
                setSelectedItems={(value) => updateState('orderItems', value.map((m: any) => ({
                    ...m,
                    error: false
                })))}
            />,
            onDismiss: (data) => {
                setFilters((prevFilters: any) => ({
                    ...prevFilters,
                    pageIndex: 0
                }))
                if (!data) return;
            }
        })
    }

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!formGroup.fields.orderItems.value) return {
                data: [],
                headers: []
            }

            return {
                data: formGroup.fields.orderItems.value,
                headers: [
                    {
                         id: 'name',
                         label: 'Nume',
                         headerType: HeaderTypeEnum.String,
                         alignment: 'left',
                         sortable: false
                     }
                 ],
                 totalElements: formGroup.fields.orderItems.value.length
            }
        },
        [formGroup.fields.orderItems.value]
    )

    const actionItems: ActionItemPropsType[] = [
        {
            text: "Adauga cantitate",
            icon: <AddBoxOutlinedIcon />,
            color: "black",
            fOnClick: (row: any) => {
                let copyOrderItemsInventory = cloneDeep(formGroup.fields.orderItemsInventory.value);
                
                uiStore.openPanel({
                    key: PanelType.AddProductOrder,
                    title: "Selecteaza produsele",
                    panelWidth: '800px',
                    component: <SelectOrderProductsComponent
                        itemId={row.id}
                        orderItemId={row.orderItemId}
                        orderStatusId={formGroup.fields.orderStatusId?.value}
                        selectedItems={copyOrderItemsInventory.filter(f => f.item.id == row.id)}
                        setSelectedItems={(value) => updateState('orderItemsInventory', [
                            ...copyOrderItemsInventory.filter(f => f.item.id !== row.id),
                            ...value.map((m: any) => ({
                                ...m,
                                quantity: formGroup.fields.orderItemsInventory.value?.find(f => f.id === m.id)?.quantity ?? 0,
                                error: false
                            }))
                        ])}
                    />,
                    onDismiss: (data) => {
                        setFilters((prevFilters: any) => ({
                            ...prevFilters,
                            pageIndex: 0
                        }))
                        if (!data) return;
                    }
                })
            },
            canDisplay: (row: any) => ![OrderStatusEnum.Cancelled, OrderStatusEnum.Submitted].includes(formGroup.fields.orderStatusId?.value ?? 0)
        },
    ]

    return (
        <CreateOrderPanelStyled>
            {
                isLoading || loadingData ? (
                    <Box
                        sx={{
                            position: "absolute",
                            top: "40vh",
                            left: "50%",
                            transform: "translate(-50%, -50%)"
                        }}
                    >
                        <LoaderComponent />
                    </Box>
                )
                :
                <Grid container spacing={2}>
                   <Grid item xs={12}>
                        <AsyncSelectComponent
                            label="Partener *"
                            url={'/Partner/GetAllPartners'}
                            isAsync
                            propertyName='data'
                            value={formGroup.fields.partner?.value}
                            onOptionChange={e => updateState('partner', e)}
                            isOptionEqualToValue={(option, value) => option.label === value.label}
                            renderOption={(props, option) => (
                                <Box component="li" {...props} key={option?.value}>
                                <div>
                                    <div>{[option.label]}</div>
                                </div>
                                </Box>
                            )}
                            error={formGroup?.shouldDisplayError && !formGroup.fields.partner?.isValid}
                            disabled={!isEmpty(orderDetails)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <label htmlFor="description">Descriere:</label>
                        <textarea
                            id="description"
                            className="text-area"
                            rows={3}
                            placeholder="Descriere"
                            value={formGroup.fields.description?.value ?? ""}
                            onChange={e => updateState('description', e.target.value)}
                            disabled={[OrderStatusEnum.Cancelled, OrderStatusEnum.Submitted].includes(formGroup.fields.orderStatusId?.value ?? 0)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <ButtonComponent
                            variant="text"
                            onClick={onChooseProducts}
                            size="medium"
                            startIcon={<Add />}
                            disabled={[OrderStatusEnum.Cancelled, OrderStatusEnum.Submitted].includes(formGroup.fields.orderStatusId?.value ?? 0)}
                        >
                        {
                                isEmpty(formGroup.fields.orderItems?.value) ?
                                    "Selecteaza produse *"
                                    : 
                                    "Editeaza produsele *"
                        }
                        </ButtonComponent>
                    </Grid>

                    <Grid item xs={12}>
                        {
                            !isEmpty(formGroup.fields.orderItems.value) ?
                                <TableComponent
                                    tableKey={TableTypeEnum.SelectedItems} 
                                    viewType="panel"
                                    tableData={tableData}
                                    searchBarPlaceholder="Cauta dupa nume"
                                    withoutSelect
                                    tableState={filtersTable}
                                    setTableState={setFilters}
                                    withoutSearchBar
                                    withoutDenseSwitch
                                    denseByDefault
                                    actionItems={actionItems}
                                    expandableRenderer={renderExpandable}
                                    actionItemsClick={setActionItemsClick}
                                    noRequest
                                />
                                :
                                <FormHelperText 
                                    sx={{ 
                                        fontSize: `${themeVariables.fontSizes.normal}`,
                                        marginLeft: 0,
                                        color: `${isEmpty(formGroup.fields.orderItems.value) && formGroup.shouldDisplayError ? '#cb5765' : 'rgba(0, 0, 0, 0.6)'}`
                                    }}
                                >
                                    Nu ati adaugat produse
                                </FormHelperText>
                        }
                    </Grid>

                    <Grid item xs={12}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginBottom: "1rem"
                            }}
                        >
                            <ButtonComponent 
                                onClick={createAction} 
                                isLoading={isSaving}
                                sx={{
                                    width: "5rem"
                                }}
                                disabled={[OrderStatusEnum.Cancelled, OrderStatusEnum.Submitted].includes(formGroup.fields.orderStatusId?.value ?? 0)}
                            >
                                Salveaza
                            </ButtonComponent>
                        </div>
                    </Grid>
                </Grid>
            }
        </CreateOrderPanelStyled>
    )
})

export default CreateOrderPanel;