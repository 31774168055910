import { observer } from "mobx-react-lite";
import SelectItemReceiptRawProductsComponentStyled from "./select-item-receipt-raw-products.component.styled";
import { useStore } from "../../../../hooks/store.hook";
import { useEffect, useMemo, useState } from "react";
import { getRawProducts } from "../../../../utils/requests";
import { displayCustomToast } from "../../../../utils/display-custom-toast";
import TableComponent, { CustomRendererPropsType, TableDataPropsType } from "../../../table/table.component";
import { HeaderTypeEnum } from "../../../../enums/header-type.enum";
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import ButtonComponent from "../../../button/button.component";
import { Chip } from "@mui/material";
import { statusColor } from "../../../../modules/main/pages/raw-products/raw-products.page";
import { RawProductStatusEnum } from "../../../../enums/raw-product-status.enum";
import { isEmpty } from "lodash";
import TooltipComponent from "../../../tooltip/tooltip.component";

export type SelectItemReceiptRawProductsComponentPropsType = {
    productTypeId: number,
    itemReceiptId?: number,
    selectedItems?: any[];
    setSelectedItems?: (value: any) => void;
}

const SelectItemReceiptRawProductsComponent = observer(({
    productTypeId,
    itemReceiptId,
    selectedItems,
    setSelectedItems
}: SelectItemReceiptRawProductsComponentPropsType) => {

    const uiStore = useStore('uiStore');

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>();
    const [refreshData, setRefreshData] = useState<boolean>(true);

    const [filtersTable, setFilters] = useState<any>({
        pageIndex: 0,
        pageSize: 5,
      //   selectFilterValue: null,
      //   secondSelectFilterValue: null,
        tabFilterValue: undefined,
        searchText: null
    });

    useEffect(
        () => {
            async function getData() {
                try {
                    setIsLoading(() => true)
                    let fetchJobs = await getRawProducts({
                        pageIndex: filtersTable.pageIndex + 1,
                        pageSize: filtersTable.pageSize,
                        searchText: filtersTable.searchText,
                        productTypeId: productTypeId,
                        itemReceiptId: itemReceiptId,
                        statusIds: [RawProductStatusEnum.New, RawProductStatusEnum.InProgress, RawProductStatusEnum.PartialProcessed]
                    });
                    setData(() => fetchJobs)
                    setIsLoading(() => false)
                } catch (error: any) {
                    displayCustomToast(error.content, true);
                    setIsLoading(() => false)
                }
            }
            
            if(!refreshData) return;

            getData();
            setRefreshData(() => false);
            
        },
        [filtersTable, refreshData]
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!data) return {
                data: [],
                headers: []
            }

            return {
                data: data.data,
                headers: [
                   {
                        id: 'productType',
                        label: 'Nume',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'left',
                        sortable: false
                    },
                   {
                        id: 'availableQuantity',
                        label: 'Cantitate disponibila',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'defaultMeasurementUnit',
                        label: 'Unitate de masura',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'batch',
                        label: 'Lot',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'description',
                        label: 'Descriere',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'status',
                        label: 'Status',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    }
                ],
                totalElements: data.totalNumber
            }
        },
        [data]
    )

    const customRenderer: CustomRendererPropsType = {
        productType: (row: any) => {
            return (
                <>
                    {row.productType.name}
                </>
            )
        },
        defaultMeasurementUnit: (row: any) => {
            return (
                <>
                    {row.productType.defaultMeasurementUnit.name}
                </>
            )
        },
        batch: (row: any) => {
            return (
                <>
                    {row.batch.name}
                </>
            )
        },
        status: (row: any) => {
            return (
                <>
                    <Chip 
                        label={row.status.name} 
                        sx={{
                            color: statusColor[row.status.id],
                            border: `1px solid ${statusColor[row.status.id]}`
                        }}
                        variant="outlined" 
                    />
                </>
            )
        },
        description: (row: any) => {
            return (
                <>
                {
                    isEmpty(row.description) ?
                        "-"
                        :
                        <TooltipComponent title={row.description ?? ''} titleLimit={20} />
                }
                </>
            )
        },
    }

    const onSelectProducts = () => {
        uiStore.dismissPanel({
            data: {
                dismissed: true
            }
        });
    }

    return (
        <SelectItemReceiptRawProductsComponentStyled>
            <TableComponent
                tableKey={TableTypeEnum.AllProductTypes} 
                viewType="panel"
                tableData={tableData}
                searchBarPlaceholder="Cauta dupa nume"
                isLoading={isLoading}
                tableState={filtersTable}
                setTableState={setFilters}
                setRefreshData={setRefreshData}
                noSelectAllCheckbox
                preselectedItems={selectedItems}
                onSelectRows={(value) =>  setSelectedItems && setSelectedItems(value.selectedItems)}
                customRenderer={customRenderer}
            />

            <div className="button-container">
                <ButtonComponent 
                    onClick={onSelectProducts}
                >
                    Selecteaza
                </ButtonComponent>
            </div>
        </SelectItemReceiptRawProductsComponentStyled>
    )
});

export default SelectItemReceiptRawProductsComponent;