import { observer } from "mobx-react-lite";
import FinishItemReceiptProcessModalStyled from "./finish-item-receipt-process-modal.component.styled";
import { useState } from "react";
import ButtonComponent from "../button/button.component";
import { Box, Container, Typography } from "@mui/material";
import { themeVariables } from "../../theme/variables";
import TextFieldComponent from "../text-field/text-field.component";
import { isEmpty } from "lodash";
import { StateFieldType } from "../../types/form.types";
import { updateItemReceiptStatus } from "../../utils/requests";
import { ItemReceiptStatusEnum } from "../../enums/item-receipt-status.enum";
import { displayCustomToast } from "../../utils/display-custom-toast";
import { useStore } from "../../hooks/store.hook";

type FinishItemReceiptProcessModalPropsTypes = {
    itemReceiptId: number,
    doneQuantity: any
}

export type StateType = {
    fields: {
        quantity: StateFieldType<any>;
    };
    shouldDisplayError: boolean;
}

const FinishItemReceiptProcessModal = observer(({
    itemReceiptId,
    doneQuantity
}: FinishItemReceiptProcessModalPropsTypes) => {

    const [formGroup, setFormGroup] = useState<StateType>({
        fields: {
            quantity: {
                value: isEmpty(doneQuantity.toString()) ? 0 : doneQuantity,
                isValid: false,
                noValidation: false
            },
        },
        shouldDisplayError: false
    });

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const uiStore = useStore('uiStore');

    const updateState =  <T extends keyof any>(field: T, newValue: any) => {
        setFormGroup((state: any) => ({
            ...state,
            fields: {
                ...state.fields,
                [field]: {
                    ...state.fields[field],
                    isValid: isEmpty(newValue?.toString()) ? false : true,
                    value: newValue
                }
            }
        }))
    }


    const saveAction = async () => {

        try {
            setIsSaving(() => true);

            await updateItemReceiptStatus({
                itemReceiptId: itemReceiptId,
                itemReceiptStatusId: ItemReceiptStatusEnum.Done,
                quantity: formGroup.fields.quantity?.value
            })

            setIsSaving(() => false);
            displayCustomToast(`Procesul a fost finalizat cu succes`);
            uiStore.dismissModal({
                data: {
                    refreshData: true
                }
            });
        } catch (error) {
            setIsSaving(() => false);
            displayCustomToast(`${error}`, true);
        }
    }

    return (
        <FinishItemReceiptProcessModalStyled>
            <Box
                width="100%"
            >
                <Container
                    sx={{
                        padding: "0 !important"
                    }}
                >
                    <Typography
                        variant="h6"
                        fontWeight={themeVariables.fontWeights.bold}
                        marginBottom="0.5rem"
                    >
                        Adauga cantitate:
                    </Typography>
                </Container>
                <Container
                    sx={{
                        padding: "0 !important"
                    }}
                >
                    <TextFieldComponent
                        label="Cantitate (kg)"
                        variant="outlined"
                        fullWidth={true}
                        value={formGroup.fields.quantity?.value}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.quantity?.isValid}
                        onTextChange={(value: any) => updateState('quantity', value)}
                        type="number"
                        required
                    />
                </Container>

            </Box>

            <div className="save-button">
                <ButtonComponent 
                    onClick={saveAction}
                    isLoading={isSaving}
                    sx={{
                        width: "5rem"
                    }}
                >
                    Salveaza
                </ButtonComponent>
            </div>
        </FinishItemReceiptProcessModalStyled>
    )
});

export default FinishItemReceiptProcessModal;