import styled from "styled-components";

const FinishItemReceiptProcessModalStyled = styled.div`

    padding: 2rem;

    .save-button {
        position: absolute;
        bottom: 2rem;
        right: 2rem;        
    }
    
`;

export default FinishItemReceiptProcessModalStyled;