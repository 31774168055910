import { observer } from "mobx-react-lite";
import ItemReceiptsPageStyled from "./item-receipts.page.styled";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../hooks/store.hook";
import { PanelType } from "../../../../enums/panel-type.enum";
import { displayCustomToast } from "../../../../utils/display-custom-toast";
import { deleteItemReceiptCommand, getItemReceipts } from "../../../../utils/requests";
import PageTemplateComponent from "../../../../components/page-template/page-template.component";
import TableComponent, { CustomRendererPropsType, TableDataPropsType } from "../../../../components/table/table.component";
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import { HeaderTypeEnum } from "../../../../enums/header-type.enum";
import { DialogResponseTypeEnum } from "../../../../enums/dialog-response-type.enum";
import { DeleteOutlined, EditOutlined, SyncAltOutlined } from "@mui/icons-material";
import ItemReceiptPanel from "../../../../components/item-receipt-panel/item-receipt-panel.component";
import FinishItemReceiptProcessModal from "../../../../components/finish-item-receipt-process-modal/finish-item-receipt-process-modal.component";
import { ItemReceiptStatusEnum } from "../../../../enums/item-receipt-status.enum";
import { Chip } from "@mui/material";
import { themeVariables } from "../../../../theme/variables";

export const statusColor: any = {
    [ItemReceiptStatusEnum.InProgress]: themeVariables.colors.primary,
    [ItemReceiptStatusEnum.Done]: themeVariables.colors.success
}

const ItemReceiptsPage = observer(() => {

    const [refreshData, setRefreshData] = useState<boolean>(true);
    const [data, setData] = useState<any>();
    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [filtersTable, setFilters] = useState<any>({
          pageIndex: 0,
          pageSize: 5,
        //   selectFilterValue: null,
        //   secondSelectFilterValue: null,
        //   tabFilterValue: undefined,
          searchText: null
    });
    const uiStore = useStore('uiStore');
    const [actionItemsClick, setActionItemsClick] = useState<any>();

    const createData = useCallback(
        () => {
            uiStore.openPanel({
                key: PanelType.CreateItemReceipt,
                component: <ItemReceiptPanel />,
                panelWidth: '850px',
                title: `Creare`,
                onDismiss: (data: any) => {
                    if(!data) return;
                    
                    if(data?.refreshData)
                        setRefreshData(() => true);
                }
            });
        },
        []
    )

    useEffect(
        () => {
            async function getData() {
                try {
                    setDataLoading(() => true)
                    let fetchJobs = await getItemReceipts({
                        pageIndex:filtersTable.pageIndex + 1,
                        pageSize:filtersTable.pageSize,
                        searchText:filtersTable.searchText
                    });
                    setData(() => fetchJobs)
                    setDataLoading(() => false)
                } catch (error: any) {
                    displayCustomToast(error.content, true);
                    setDataLoading(() => false)
                }
            }
            
            if(!refreshData) return;

            getData();
            setRefreshData(() => false);
            
        },
        [filtersTable, refreshData]
    )

    const customRenderer: CustomRendererPropsType  = {
        item: (row: any) => {
            return (
                <>
                    {row.item.name}
                </>
            )
        },
        status: (row: any) => {
            return (
                <>
                    <Chip 
                        label={row.status.name} 
                        sx={{
                            color: statusColor[row.status.id],
                            border: `1px solid ${statusColor[row.status.id]}`
                        }}
                        variant="outlined" 
                    />
                </>
            )
        },
    }

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!data) return {
                data: [],
                headers: []
            }

            return {
                data: data.data,
                headers: [
                    {
                        id: 'id',
                        label: 'Id',
                        headerType: HeaderTypeEnum.Numeric,
                        alignment: 'left',
                        sortable: false
                    },
                   {
                        id: 'item',
                        label: 'Nume',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'left',
                        sortable: false
                    },
                   {
                        id: 'estimatedQuantity',
                        label: 'Cantitate obtinuta estimativ',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'totalQuantity',
                        label: 'Cantitate totala',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'doneQuantity',
                        label: 'Cantitate finalizata',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'status',
                        label: 'Status',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    }
                ],
                totalElements: data.totalNumber
            }
        },
        [data]
    )

    const actionItems: any[] = useMemo(
        () => {
            return [
                {
                    text: "Editeaza",
                    icon: <EditOutlined />,
                    color: "black",
                    fOnClick: (row: any) => {
                        uiStore.openPanel({
                            key: PanelType.EditItemReceipt,
                            component: <ItemReceiptPanel 
                                itemReceiptId={row.id}
                            />,
                            panelWidth: '850px',
                            title: `Editeaza: ${row.item.name}`,
                            onDismiss: (data: any) => {
                                if(!data) return;
                                
                                if(data?.refreshData)
                                    setRefreshData(() => true);
                            }
                        });
                    }
                },
                {
                    text: "Sterge",
                    icon: <DeleteOutlined />,
                    color: "red",
                    async fOnClick(row: any){
                        if(row) {   
                            const response = await uiStore.openDialog({
                                title: "Stergi...",
                                message: "Esti sigur ca vrei sa continui?",
                            })    

                            if(response.value === DialogResponseTypeEnum.Confirm) {
                                try {
                                    setDataLoading(() => true);
                                    await deleteItemReceiptCommand({
                                        id: row.id
                                    });
                                    displayCustomToast("Stergere realizata cu succes");
                                    setDataLoading(() => false);
                                    setRefreshData(() => true);
                                }
                                catch (error: any) {
                                    displayCustomToast(error.content, true);
                                    setDataLoading(() => false)
                                }
                            }    
                        } 
                    }
                },
                
                actionItemsClick?.status?.id === ItemReceiptStatusEnum.Done ? undefined 
                : 
                {
                    text: 'Finalizeaza proces',
                    icon: <SyncAltOutlined />,
                    color: 'green',
                    fOnClick: (row: any) => {
                        uiStore.openModal({
                            title:'Finalizeaza proces',
                            component: <FinishItemReceiptProcessModal
                                doneQuantity={row.doneQuantity}
                                itemReceiptId={row.id}
                            />,
                            onDismiss: (data: any) => {
                                if(!data) return;
                                
                                if(data?.refreshData)
                                    setRefreshData(() => true);
                            }
                        })
                    }
                },
            ].filter(f => f)
        },
        [actionItemsClick]
    )

    return (
        <ItemReceiptsPageStyled>
            <PageTemplateComponent  
                title="Proces de productie"
                buttons={[
                    {
                        fOnClick: () => {
                            createData()
                        },
                        text: "Creeaza"
                    }
                ]}
            >
                <TableComponent
                    tableKey={TableTypeEnum.AllItemReceipts} 
                    viewType="normal"
                    tableData={tableData}
                    searchBarPlaceholder="Cauta dupa nume"
                    withoutSelect
                    isLoading={dataLoading}
                    tableState={filtersTable}
                    setTableState={setFilters}
                    actionItems={actionItems}
                    actionItemsClick={setActionItemsClick}
                    setRefreshData={setRefreshData}
                    customRenderer={customRenderer}
                />  
            </PageTemplateComponent>
        </ItemReceiptsPageStyled>
    )
});

export default ItemReceiptsPage;