import * as React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { Autocomplete, AutocompleteRenderOptionState } from '@mui/material';
import TextFieldComponent from '../text-field/text-field.component';
import { AsyncSelectOptionType } from '../async-select/async-select.component';

interface FilterSelectPropsType {
  options: {
    label: string;
    value: any;
  }[],
  selectFilterValue: any,
  placeholder: string,
  onValueChange: (newValue: any) => void,
  renderOption?: (props: React.HTMLAttributes<HTMLLIElement>, option: AsyncSelectOptionType, state: AutocompleteRenderOptionState) => React.ReactNode,
  getOptionLabel?: (option: any) => any,
  customStyle?: {
    marginRight?: string;
    marginBottom?: string;
  },
  autcompleteStyle?: any,
  error?: boolean,
  multiple?: boolean,
  loading?: boolean,
  disabled?: boolean,
  width?: number,
}

const BasicSelect = ({
  options, 
  selectFilterValue, 
  onValueChange,
  placeholder,
  customStyle,
  autcompleteStyle,
  error,
  multiple,
  loading,
  disabled,
  width,
  renderOption,
  getOptionLabel
}:FilterSelectPropsType) => {

  return (
    <Box  sx = {{
        display: "flex",
        flex: 1,
        marginRight: customStyle ? customStyle.marginRight : "0.625rem",
        marginBottom: customStyle ? customStyle.marginBottom : '0'
    }}>
      <FormControl 
        fullWidth
      >
        <Autocomplete 
            options={(options ?? []).map((option: any) => option)}
            renderInput={(params) => <TextFieldComponent 
                error={error} 
                {...params} 
                label={placeholder}
                width={width} 
              />}
            getOptionLabel={(option) => getOptionLabel ? getOptionLabel(option) : option?.label}
            onChange={(_, value) => onValueChange(value)}
            isOptionEqualToValue={(option, value) => option?.value === value?.value}
            value={selectFilterValue}
            sx={{fontFamily: 'Poppins, sans-serif !important', ...autcompleteStyle}}
            multiple={multiple}
            loading={loading}
            disabled={disabled}
            renderOption={renderOption}
        />

      </FormControl>
    </Box>
  );
}

export default BasicSelect;
