import styled from "styled-components";
import { themeVariables } from "../../theme/variables";


export type TextFieldComponentStyledPropsType = {
    fullWidth?: boolean;
    width?: number;
}

const TextFieldComponentStyled = styled.div<TextFieldComponentStyledPropsType>`

    position: relative;

    width: ${props => props.fullWidth ? '100%' : 'initial'};
    width: ${props => props.width}px;

    .MuiOutlinedInput-root {
        border-radius: 10px;

        input {
            font-family: 'Poppins', sans-serif;
        }
    }

    label {
        font-family: 'Poppins', sans-serif;
    }

    label.Mui-focused {
        color: ${() => themeVariables.colors.primary};
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: ${() => themeVariables.colors.primary};
    }

    .password-icon-position {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-50%, -50%);
    }

    .password-icon-center {
        display: flex;
        align-items: center;
    }

`;

export default TextFieldComponentStyled