import { observer } from "mobx-react-lite";
import BatchesPageStyled from "./batches.page.styled";
import PageTemplateComponent from "../../../../components/page-template/page-template.component";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../hooks/store.hook";
import { PanelType } from "../../../../enums/panel-type.enum";
import { displayCustomToast } from "../../../../utils/display-custom-toast";
import { deleteBatchCommand, getBatches } from "../../../../utils/requests";
import TableComponent, { ActionItemPropsType, CustomRendererPropsType, TableDataPropsType } from "../../../../components/table/table.component";
import { HeaderTypeEnum } from "../../../../enums/header-type.enum";
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import BatchPanel from "../../../../components/batch-panel/batch-panel.component";
import { DeleteOutlined, EditOutlined } from "@mui/icons-material";
import { DialogResponseTypeEnum } from "../../../../enums/dialog-response-type.enum";

const BatchesPage = observer(() => {

    const [refreshData, setRefreshData] = useState<boolean>(true);
    const [data, setData] = useState<any>();
    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [filtersTable, setFilters] = useState<any>({
          pageIndex: 0,
          pageSize: 5,
        //   selectFilterValue: null,
        //   secondSelectFilterValue: null,
        //   tabFilterValue: undefined,
          searchText: null
    });
    const uiStore = useStore('uiStore');
    const [actionItemsClick, setActionItemsClick] = useState<any>();

    const createData = useCallback(
        () => {
            uiStore.openPanel({
                key: PanelType.CreateProductCategroyType,
                component: <BatchPanel />,
                panelWidth: '700px',
                title: `Creare tip de categorie de produse`,
                onDismiss: (data: any) => {
                    if(!data) return;
                    
                    if(data?.refreshData)
                        setRefreshData(() => true);
                }
            });
        },
        []
    )

    useEffect(
        () => {
            async function getData() {
                try {
                    setDataLoading(() => true)
                    let fetchJobs = await getBatches({
                        pageIndex:filtersTable.pageIndex + 1,
                        pageSize:filtersTable.pageSize,
                        searchText:filtersTable.searchText
                    });
                    setData(() => fetchJobs)
                    setDataLoading(() => false)
                } catch (error: any) {
                    displayCustomToast(error.content, true);
                    setDataLoading(() => false)
                }
            }
            
            if(!refreshData) return;

            getData();
            setRefreshData(() => false);
            
        },
        [filtersTable, refreshData]
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!data) return {
                data: [],
                headers: []
            }

            return {
                data: data.data,
                headers: [
                   {
                        id: 'name',
                        label: 'Nume',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'left',
                        sortable: false
                    },
                   {
                        id: 'description',
                        label: 'Descriere',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'type',
                        label: 'Tip',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    }
                ],
                totalElements: data.totalNumber
            }
        },
        [data]
    )

    const actionItems: ActionItemPropsType[] = useMemo(
        () => {
            return [
                {
                    text: "Editeaza lot",
                    icon: <EditOutlined />,
                    color: "black",
                    fOnClick: (row: any) => {
                        uiStore.openPanel({
                            key: PanelType.EditBatch,
                            component: <BatchPanel 
                                batchId={row.id}
                                batchDetails={row}
                            />,
                            panelWidth: '700px',
                            title: `Editeaza lotul: ${row.name}`,
                            onDismiss: (data: any) => {
                                if(!data) return;
                                
                                if(data?.refreshData)
                                    setRefreshData(() => true);
                            }
                        });
                    }
                },
                {
                    text: "Sterge lotul",
                    icon: <DeleteOutlined />,
                    color: "red",
                    async fOnClick(row: any){
                        if(row) {   
                            const response = await uiStore.openDialog({
                                title: "Stergi lotul...",
                                message: "Esti sigur ca vrei sa continui?",
                            })    

                            if(response.value === DialogResponseTypeEnum.Confirm) {
                                try {
                                    setDataLoading(() => true);
                                    await deleteBatchCommand({
                                        id: row.id
                                    });
                                    displayCustomToast("Lotul a fost sters");
                                    setDataLoading(() => false);
                                    setRefreshData(() => true);
                                }
                                catch (error: any) {
                                    displayCustomToast(error.content, true);
                                    setDataLoading(() => false)
                                }
                            }    
                        } 
                    }
                }
            ]
        },
        [actionItemsClick]
    )

    const customRenderer: CustomRendererPropsType = {
        type: (row: any) => {
            return (
                <>
                    {row.type.name}
                </>
            )
        }
    }
    
    return (
        <BatchesPageStyled>
            <PageTemplateComponent 
                title="Loturi"
                buttons={[
                    {
                        fOnClick: () => {
                            createData()
                        },
                        text: "Creeaza"
                    }
                ]}
            >
                <TableComponent
                    tableKey={TableTypeEnum.AllBatches} 
                    viewType="normal"
                    tableData={tableData}
                    searchBarPlaceholder="Cauta dupa nume"
                    withoutSelect
                    isLoading={dataLoading}
                    tableState={filtersTable}
                    setTableState={setFilters}
                    actionItems={actionItems}
                    actionItemsClick={setActionItemsClick}
                    setRefreshData={setRefreshData}
                    customRenderer={customRenderer}
                />  
            </PageTemplateComponent>
        </BatchesPageStyled>    
    )
});

export default BatchesPage;