import { observer } from "mobx-react-lite";
import ProductTypePanelStyled from "./product-type-panel.component.styled";
import { StateFieldType } from "../../types/form.types";
import { useEffect, useState } from "react";
import { useStore } from "../../hooks/store.hook";
import { isEmpty } from "lodash";
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Grid } from "@mui/material";
import TextFieldComponent from "../text-field/text-field.component";
import { themeVariables } from "../../theme/variables";
import ButtonComponent from "../button/button.component";
import { createProductTypeCommand, editProductTypeCommand, getDictionaryProductCategoryTypes, getMeasurementUnitType } from "../../utils/requests";
import { displayCustomToast } from "../../utils/display-custom-toast";
import BasicSelect from "../dropdown-table-component/dropdown-table-component.component";

export type ProductTypePanelPropsTypes = {
    productTypeId?: number,
    productTypeDetails?: any
}

export type StateType = {
    fields: {
        name: StateFieldType<string>;
        productCategoryType: StateFieldType<any>;
        measurementUnitType: StateFieldType<any>;
        hasBatch: StateFieldType<boolean>;
        isActive: StateFieldType<any>;
    };
    shouldDisplayError: boolean;
}

const ProductTypePanel = observer(({
    productTypeDetails,
    productTypeId
}: ProductTypePanelPropsTypes) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [formGroup, setFormGroup] = useState<StateType>({
        fields: {
            name: {
                value: "",
                isValid: false,
                noValidation: false
            },
            hasBatch: {
                value: false,
                isValid: true,
                noValidation: true
            },
            productCategoryType: {
                value: null,
                isValid: false,
                noValidation: false 
            },
            measurementUnitType: {
                value: null,
                isValid: false,
                noValidation: false 
            },
            isActive: {
                value: true,
                isValid: true,
                noValidation: true
            },
        },
        shouldDisplayError: false
    })
    const uiStore = useStore('uiStore');
    const [productCategoryType, setProductCategoryType] = useState<any[]>([]);
    const [measurementUnitType, setMeasurementUnitType] = useState<any[]>([]);

    const updateState =  <T extends keyof any>(field: T, newValue: any) => {
        setFormGroup((state: any) => ({
            ...state,
            fields: {
                ...state.fields,
                [field]: {
                    ...state.fields[field],
                    isValid: isEmpty(newValue?.toString()) ? false : true,
                    value: newValue
                }
            }
        }))
    }

    const getBasicSelectOptions = async () => {
        try {
            setIsLoading(() => true);

            let productCategoryTypes = await getDictionaryProductCategoryTypes();
            let measurementUnitTypes = await getMeasurementUnitType();
            
            setProductCategoryType(() => productCategoryTypes);
            setMeasurementUnitType(() => measurementUnitTypes);

            setIsLoading(() => false);

        } catch (error: any) {

            displayCustomToast(error.content, true);
            setIsLoading(() => false);
        }
    }

    useEffect(
        () => {
            getBasicSelectOptions();
        },
        []
    )

    // Editare
    useEffect(
        () => {
            if(productTypeId === undefined) return;
            
            updateState('name', productTypeDetails.name);
            updateState('hasBatch', productTypeDetails.hasBatch);
            updateState('isActive', productTypeDetails.isActive);
            updateState('productCategoryType', {
                label: productTypeDetails.productCategoryType.name,
                value: productTypeDetails.productCategoryType.id
            });
            updateState('measurementUnitType', {
                label: productTypeDetails.measurementUnitType.name,
                value: productTypeDetails.measurementUnitType.id
            });
            
        },
        [productTypeDetails, productTypeId]
    )

    const createAction = async () => {
        setIsSaving(() => true);
        if(!formGroup) return;

        const isNotValid = Object.values(formGroup.fields).filter((f: any) => f.noValidation === false).some((field: any) => !field.isValid);
        if (isNotValid) {
            setIsSaving(() => false);
            setFormGroup((prevState: any) => ({
                ...prevState,
                shouldDisplayError: true
            }))
            displayCustomToast("Datele nu sunt valide!", true);
            return;
        };

        let body = {
            name: formGroup.fields.name.value,
            productCategoryTypeId: formGroup.fields.productCategoryType.value.value,
            hasBatch: formGroup.fields.hasBatch.value,
            measurementUnitTypeId: formGroup.fields.measurementUnitType.value.value,
        }

        try {
            if(productTypeId != undefined){
                let bodyUpdate = {
                    id: productTypeId,
                    isActive: formGroup.fields.isActive.value,
                    ...body
                }
                await editProductTypeCommand(productTypeId, bodyUpdate)
            }
            else
                await createProductTypeCommand(body);

            setIsSaving(() => false);
            displayCustomToast("Datele au fost salvate cu succes!");
            uiStore.dismissPanel({
                data: {
                    refreshData: true
                }
            });
        } catch (error: any) {
            displayCustomToast(error.content, true);
            setIsSaving(() => false);
        }
    } 


    return (
        <ProductTypePanelStyled>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextFieldComponent
                        label="Nume"
                        variant="outlined"
                        fullWidth={true}
                        value={formGroup.fields.name?.value}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.name?.isValid}
                        onTextChange={(value: any) => updateState('name', value)}
                        style={{}}
                        required
                    />
                </Grid>

                <Grid item xs={12}>
                    <BasicSelect
                        placeholder="Tip categorie produs *"
                        options={productCategoryType?.map((m: any) => ({
                            label: m?.name,
                            value: m?.id
                        }))}
                        selectFilterValue={formGroup.fields.productCategoryType?.value}
                        onValueChange={e => updateState('productCategoryType', e)}
                        loading={isLoading}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.productCategoryType?.isValid}
                        customStyle={{
                            marginRight: "0"
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <BasicSelect
                        placeholder="Tip unitate de masura *"
                        options={measurementUnitType?.map((m: any) => ({
                            label: m?.name,
                            value: m?.id
                        }))}
                        selectFilterValue={formGroup.fields.measurementUnitType?.value}
                        onValueChange={e => updateState('measurementUnitType', e)}
                        loading={isLoading}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.measurementUnitType?.isValid}
                        customStyle={{
                            marginRight: "0"
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormControl
                        error={!formGroup.fields.hasBatch?.value}
                    >
                        <FormControlLabel
                            control={<Checkbox
                                checked={formGroup.fields.hasBatch?.value}
                                onChange={e => updateState('hasBatch', e.target.checked)}
                            />}
                            label="Are lot"
                        />
                    </FormControl>
                </Grid>

                {
                    !(productTypeId === undefined) ?
                        <Grid item xs={12}>
                            <FormControl
                                error={!formGroup.fields.isActive?.value}
                            >
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={formGroup.fields.isActive?.value}
                                        onChange={e => updateState('isActive', e.target.checked)}
                                    />}
                                    label="Activ"
                                />
                                {
                                    !formGroup.fields.isActive?.value ?
                                        <FormHelperText 
                                            sx={{ 
                                                fontSize: `${themeVariables.fontSizes.normal}`,
                                                marginLeft: 0
                                            }}>
                                                Tipul de produs o sa devina inactiv
                                            </FormHelperText>
                                        :
                                        <></>
                                }
                            </FormControl>
                        </Grid>
                        :
                        <></>
                    }
                <Grid item xs={12}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end"
                        }}
                    >
                        <ButtonComponent 
                            onClick={createAction} 
                            isLoading={isSaving}
                            sx={{
                                width: "5rem"
                            }}
                        >
                            Salveaza
                        </ButtonComponent>
                    </div>
                </Grid>

            </Grid>
        </ProductTypePanelStyled>
    )
});

export default ProductTypePanel;