import styled from "styled-components";
import { themeVariables } from "../../theme/variables";
import { grey } from "../../utils/utils";

const BatchPanelComponent = styled.div`
    .text-area {
        width: 100%;
        box-sizing: border-box;
        font-family: IBM Plex Sans, sans-serif;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 12px;
        border-radius: 12px 12px 0 12px;
        color: ${grey[900]};
        background: ${'#fff'};
        border: 2px solid ${grey[400]};
        box-shadow: 0px 2px 2px ${grey[50]};

        &:hover {
        border-color: ${themeVariables.colors.primary};
        }

        &:focus {
            border-color: ${themeVariables.colors.primary};
        }

        // firefox
        &:focus-visible {
        outline: 0;
        }
    }
`;

export default BatchPanelComponent;