import { observer } from "mobx-react-lite";
import SelectReceiptsComponentStyled from "./select-receipt-items.component.styled";
import { useStore } from "../../../../hooks/store.hook";
import { useEffect, useMemo, useState } from "react";
import { displayCustomToast } from "../../../../utils/display-custom-toast";
import { getDictionaryProductCategoryTypes, getProductTypes } from "../../../../utils/requests";
import TableComponent, { CustomRendererPropsType, TableDataPropsType } from "../../../table/table.component";
import { HeaderTypeEnum } from "../../../../enums/header-type.enum";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import ButtonComponent from "../../../button/button.component";
import { isEmpty } from "lodash";

export type SelectReceiptsComponentPropsType = {
    selectedItems?: any[];
    setSelectedItems?: (value: any) => void;
}

const SelectReceiptsComponent = observer(({
    selectedItems,
    setSelectedItems
}: SelectReceiptsComponentPropsType) => {
    
    const uiStore = useStore('uiStore');

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>();
    const [refreshData, setRefreshData] = useState<boolean>(true);

    const [filtersTable, setFilters] = useState<any>({
        pageIndex: 0,
        pageSize: 5,
        selectFilterValue: null,
      //   secondSelectFilterValue: null,
        // tabFilterValue: undefined,
        searchText: null
    });

    const [productCategoryTypes, setProductCategoryTypes] = useState<any[]>([]);

    const getBasicSelectOptions = async () => {
        try {

            let productCategoryTypes = await getDictionaryProductCategoryTypes();
            
            setProductCategoryTypes(() => productCategoryTypes);

        } catch (error: any) {

            displayCustomToast(error.content, true);
        }
    }

    useEffect(
        () => {
            async function getData() {
                try {
                    setIsLoading(() => true);

                    await getBasicSelectOptions();

                    let fetchData = await getProductTypes({
                        pageIndex:filtersTable.pageIndex + 1,
                        pageSize:filtersTable.pageSize,
                        searchText:filtersTable.searchText,
                        productCategoryTypeId: isEmpty(filtersTable.selectFilterValue) ? null : filtersTable.selectFilterValue.value
                    });
                    setData(() => fetchData)
                    setIsLoading(() => false)
                } catch (error: any) {
                    displayCustomToast(error.content, true);
                    setIsLoading(() => false)
                }
            }
            
            if(!refreshData) return;

            getData();
            setRefreshData(() => false);
            
        },
        [filtersTable, refreshData]
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!data) return {
                data: [],
                headers: []
            }

            return {
                data: data.data,
                headers: [
                   {
                        id: 'name',
                        label: 'Nume',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'left',
                        sortable: false
                    },
                   {
                        id: 'productCategoryType',
                        label: 'Tip categorie produs',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'left',
                        sortable: false
                    },
                    {
                        id: 'measurementUnitType',
                        label: 'Tip unitate de masura',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'left',
                        sortable: false
                    },
                    {
                        id: 'hasBatch',
                        label: 'Are lot',
                        headerType: HeaderTypeEnum.Boolean,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                        id: 'isActive',
                        label: 'Activ',
                        headerType: HeaderTypeEnum.Boolean,
                        alignment: 'center',
                        sortable: false
                    }
                ],
                totalElements: data.totalNumber
            }
        },
        [data]
    )

    const customRenderer: CustomRendererPropsType = useMemo(
        () => {
            return {
                isActive: (row: any) => {
                    return (
                        <>
                            {
                                row.isActive ?
                                    <CheckIcon color="success"  />
                                    :
                                    <CloseIcon color="error"  />
                            }
                        </>
                    )
                },
                hasBatch: (row: any) => {
                    return (
                        <>
                            {
                                row.hasBatch ?
                                    <CheckIcon color="success"  />
                                    :
                                    <CloseIcon color="error"  />
                            }
                        </>
                    )
                },
                productCategoryType: (row: any) => {
                    return (
                        <>
                            {row.productCategoryType.name}
                        </>
                    )
                },
                measurementUnitType: (row: any) => {
                    return (
                        <>
                            {row.measurementUnitType.name}
                        </>
                    )
                },
            }
        },
        []
    )

    const onSelectProducts = () => {
        uiStore.dismissPanel({
            data: {
                dismissed: true
            }
        });
    }

    return (
        <SelectReceiptsComponentStyled>
            <TableComponent
                tableKey={TableTypeEnum.AllProductTypes} 
                viewType="panel"
                tableData={tableData}
                searchBarPlaceholder="Cauta dupa nume"
                isLoading={isLoading}
                tableState={filtersTable}
                dropdownPlaceholder="Tip categorie produs"
                dropdownOptions={productCategoryTypes?.map(p => ({
                    label: p.name,
                    value: p.id
                }))}
                setTableState={setFilters}
                setRefreshData={setRefreshData}
                noSelectAllCheckbox
                preselectedItems={selectedItems}
                onSelectRows={(value) =>  setSelectedItems && setSelectedItems(value.selectedItems)}
                customRenderer={customRenderer}
            />

            <div className="button-container">
                <ButtonComponent 
                    onClick={onSelectProducts}
                >
                    Selecteaza
                </ButtonComponent>
            </div>
        </SelectReceiptsComponentStyled>
    )
});

export default SelectReceiptsComponent;