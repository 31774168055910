import { observer } from "mobx-react-lite";
import InventoryPanelStyled from "./inventory-panel.component.styled";
import { StateFieldType } from "../../types/form.types";
import { useEffect, useState } from "react";
import { useStore } from "../../hooks/store.hook";
import { isEmpty } from "lodash";
import { createInventoryCommand, editInventoryCommand, getDictionarItemReceipts, getDictionaryItems, getDictionaryItemsList, getMeasurementUnit, getMeasurementUnitList } from "../../utils/requests";
import { displayCustomToast } from "../../utils/display-custom-toast";
import { Grid } from "@mui/material";
import BasicSelect from "../dropdown-table-component/dropdown-table-component.component";
import TextFieldComponent from "../text-field/text-field.component";
import ButtonComponent from "../button/button.component";

export type InventoriesPanelPropsTypes = {
    inventoryId?: number,
    inventoryDetails?: any
}

export type StateType = {
    fields: {
        description: StateFieldType<string>;
        initialQuantity: StateFieldType<number>;
        totalQuantity: StateFieldType<number>;
        usedQuantity: StateFieldType<number>;
        item: StateFieldType<any>;
        itemReceipt: StateFieldType<any>;
        measurementUnit: StateFieldType<any>;
    };
    shouldDisplayError: boolean;
}

const InventoryPanel = observer(({
    inventoryDetails,
    inventoryId
}: InventoriesPanelPropsTypes) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [formGroup, setFormGroup] = useState<StateType>({
        fields: {
            description: {
                value: "",
                isValid: false,
                noValidation: false
            },
            initialQuantity: {
                value: 0,
                isValid: true,
                noValidation: true
            },
            totalQuantity: {
                value: 0,
                isValid: false,
                noValidation: false
            },
            usedQuantity: {
                value: 0,
                isValid: true,
                noValidation: true
            },
            item: {
                value: null,
                isValid: false,
                noValidation: false
            },
            itemReceipt: {
                value: null,
                isValid: true,
                noValidation: true
            },
            measurementUnit: {
                value: null,
                isValid: false,
                noValidation: false
            },

        },
        shouldDisplayError: false
    })
    const uiStore = useStore('uiStore');

    const [items, setItems] = useState<any[]>([]);
    const [measurementUnits, setMeasurementUnits] = useState<any[]>([]);
    const [itemReceipts, setItemReceipts] = useState<any[]>([]);

    const updateState =  <T extends keyof any>(field: T, newValue: any) => {
        setFormGroup((state: any) => ({
            ...state,
            fields: {
                ...state.fields,
                [field]: {
                    ...state.fields[field],
                    isValid: isEmpty(newValue?.toString()) ? false : true,
                    value: newValue
                }
            }
        }))
    }

    const getBasicSelectOptions = async () => {
        try {
            setIsLoading(() => true);

            let items = await getDictionaryItemsList();
            let measurementUnit = await getMeasurementUnitList();
            let itemReceipts = await getDictionarItemReceipts();

            setItems(() => items);
            setMeasurementUnits(() => measurementUnit);
            setItemReceipts(() => itemReceipts);

            setIsLoading(() => false);

        } catch (error: any) {

            displayCustomToast(error.content, true);
            setIsLoading(() => false);
        }
    }

    useEffect(
        () => {
            getBasicSelectOptions();
        },
        []
    )

    // Editare
    useEffect(
        () => {
            if(inventoryId === undefined) return;
            
            updateState('description', inventoryDetails.description);
            updateState('item', {
                label: inventoryDetails.item.name,
                value: inventoryDetails.item.id,
                measurementUnitTypeId: inventoryDetails.measurementUnitType.id
            });
            updateState('initialQuantity', inventoryDetails.initialQuantity);
            updateState('totalQuantity', inventoryDetails.totalQuantity);
            updateState('usedQuantity', inventoryDetails.usedQuantity);            
            updateState('itemReceipt', inventoryDetails.itemReceipt == null ? null : {
                label: inventoryDetails.itemReceipt?.name,
                value: inventoryDetails.itemReceipt?.id
            });
            
        },
        [inventoryDetails, inventoryId]
    )

    useEffect(
        () => {
            if(isEmpty(formGroup.fields.item.value) || isEmpty(measurementUnits)) return;
            
            let defaultMeasurementUnit = measurementUnits?.filter(f => f.isDefault)
                .find(n => n.typeId == formGroup.fields.item?.value?.measurementUnitTypeId);
            
            updateState('measurementUnit', {
                    label: defaultMeasurementUnit.name,
                    value: defaultMeasurementUnit.id
                }
            );
        },
        [formGroup.fields.item.value, measurementUnits]
    )

    const createAction = async () => {
        setIsSaving(() => true);
        if(!formGroup) return;

        const isNotValid = Object.values(formGroup.fields).filter((f: any) => f.noValidation === false).some((field: any) => !field.isValid);
        if (isNotValid) {
            setIsSaving(() => false);
            setFormGroup((prevState: any) => ({
                ...prevState,
                shouldDisplayError: true
            }))
            displayCustomToast("Datele nu sunt valide!", true);
            return;
        };

        let body = {
            description: formGroup.fields.description.value,
            initialQuantity: formGroup.fields.initialQuantity.value,
            totalQuantity: formGroup.fields.totalQuantity.value,
            usedQuantity: formGroup.fields.usedQuantity.value,
            itemId: formGroup.fields.item.value.value,
            // itemReceiptId: formGroup.fields.itemReceipt.value.value,
            measurementUnitId: formGroup.fields.measurementUnit.value.value,
            isGenerated: false
        }

        try {
            if(inventoryId != undefined){
                let bodyUpdate = {
                    id: inventoryId,
                    ...body
                }
                await editInventoryCommand(inventoryId, bodyUpdate)
            }
            else
                await createInventoryCommand(body);

            setIsSaving(() => false);
            displayCustomToast("Datele au fost salvate cu succes!");
            uiStore.dismissPanel({
                data: {
                    refreshData: true
                }
            });
        } catch (error: any) {
            displayCustomToast(error.content, true);
            setIsSaving(() => false);
        }
    }

    return (
        <InventoryPanelStyled>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <label htmlFor="description">Descriere:</label>
                    <textarea
                        id="description"
                        className="text-area"
                        rows={3}
                        placeholder="Descriere"
                        value={formGroup.fields.description?.value ?? ""}
                        onChange={e => updateState('description', e.target.value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <BasicSelect
                        placeholder="Produse *"
                        options={items?.map((m: any) => ({
                            label: m?.name,
                            value: m?.id,
                            measurementUnitTypeId: m?.measurementUnitType?.id 
                        }))}
                        selectFilterValue={formGroup.fields.item?.value}
                        onValueChange={e => updateState('item', e)}
                        loading={isLoading}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.item?.isValid}
                        customStyle={{
                            marginRight: "0"
                        }}
                        disabled={inventoryId !== undefined}
                    />
                </Grid>

                {/* <Grid item xs={12}>
                    <BasicSelect
                        placeholder="Proces de productie *"
                        options={itemReceipts?.map((m: any) => ({
                            label: m?.name,
                            value: m?.id
                        }))}
                        selectFilterValue={formGroup.fields.itemReceipt?.value}
                        onValueChange={e => updateState('itemReceipt', e)}
                        loading={isLoading}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.itemReceipt?.isValid}
                        customStyle={{
                            marginRight: "0"
                        }}
                        disabled={inventoryId !== undefined}
                    />
                </Grid> */}

                <Grid item xs={12}>
                    <BasicSelect
                        placeholder="Unitate de masura *"
                        options={measurementUnits?.filter(f => f.isDefault)
                            .map((m: any) => ({
                                label: m?.name,
                                value: m?.id
                            }))
                        }
                        selectFilterValue={formGroup.fields.measurementUnit?.value}
                        onValueChange={e => updateState('measurementUnit', e)}
                        loading={isLoading}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.measurementUnit?.isValid}
                        customStyle={{
                            marginRight: "0"
                        }}
                        disabled
                    />
                </Grid>

                {
                    inventoryId !== undefined ?
                        <Grid item xs={12}>
                            <TextFieldComponent
                                label="Cantiate initiala"
                                variant="outlined"
                                fullWidth={true}
                                value={formGroup.fields.initialQuantity?.value}
                                error={formGroup?.shouldDisplayError && !formGroup.fields.initialQuantity?.isValid}
                                onTextChange={(value: any) => updateState('initialQuantity', value)}
                                type="number"
                                required
                                disabled
                            />
                        </Grid>
                        : 
                        <></>
                }

                <Grid item xs={12}>
                    <TextFieldComponent
                        label="Cantiate totala"
                        variant="outlined"
                        fullWidth={true}
                        value={formGroup.fields.totalQuantity?.value}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.totalQuantity?.isValid}
                        onTextChange={(value: any) => updateState('totalQuantity', value)}
                        type="number"
                        required
                    />
                </Grid>

                
                {
                    inventoryId !== undefined ?
                        <Grid item xs={12}>
                            <TextFieldComponent
                                label="Cantiate folosita"
                                variant="outlined"
                                fullWidth={true}
                                value={formGroup.fields.usedQuantity?.value}
                                error={formGroup?.shouldDisplayError && !formGroup.fields.usedQuantity?.isValid}
                                onTextChange={(value: any) => updateState('usedQuantity', value)}
                                type="number"
                                required
                                disabled
                            />
                        </Grid>
                        :
                        <></>
                }

                <Grid item xs={12}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end"
                        }}
                    >
                        <ButtonComponent 
                            onClick={createAction} 
                            isLoading={isSaving}
                            sx={{
                                width: "5rem"
                            }}
                        >
                            Salveaza
                        </ButtonComponent>
                    </div>
                </Grid>
            </Grid>
        </InventoryPanelStyled>
    )
});

export default InventoryPanel;