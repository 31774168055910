import { deleteRequest, formDataPostRequest, getRequest, postRequest, putRequest } from "./http-requests"
import { ContentTypeEnum } from "../enums/content-type.enum";
import { DictionaryTypeEnum } from "../enums/dictionary-type-enum";
import axios from "axios";
import envVars from "../config/env.config";
import { StorageEnum } from "../enums/storage.enum";

export type BaseDto<T> = {
    id: T;
};

export type DictionaryDto = {
    name: string;
    tag?: string;
    isActive?: boolean;
    isDefault?: boolean;
} & BaseDto<number>;

export type PhoneNumberDto = {
    prefixCode: string;
    content: string;
    prefixId: number;
}

export type TemplateQuestionDto = {
    question: string;
    tag: string;
    order: number;
    isStandard: boolean;
    saveTamplate?: boolean;
    languageTag?: string
} & BaseDto<number>;

export type AddJobQuestionDto = {
    jobId?: number;
    question: string;
    saveTemplate: boolean;
    tag?: string;
    languageTag?: string;
}

export type CountyDto = DictionaryDto & { countryId: number; }

// ----------------------------- GRIGOTEC -----------------------------------------

export const createCategoryCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Category`,
        body: body,
    });
}

export const updateCategoryCommand = async (id: number, body: any): Promise<void> => {
    await putRequest({
        url: `/Category/${id}`,
        body: body,
    });
}

export const createIngredientCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Ingredients`,
        body: body,
    });
}

export const updateIngredientCommand = async (id: number, body: any): Promise<void> => {
    await putRequest({
        url: `/Ingredients/${id}`,
        body: body,
    });
}

export const getAllCategories = async (filters: any) => {
    const { data } = await getRequest({
        url: `/Category/GetListCategories`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const getAllIngredients = async (filters: any) => {
    const { data } = await getRequest({
        url: `/Ingredients/GetListIngredients`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const createItemCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Items`,
        body: body,
    });
}

export const getAllItems = async (filters: any) => {
    const { data } = await getRequest({
        url: `/Items/GetListItems`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const getAllDeliveryRoutes = async (filters: any) => {
    const { data } = await getRequest({
        url: `/DeliveryRoutes/GetListDeliveryRoute`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const createDeliveryRouteCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/DeliveryRoutes`,
        body: body,
    });
}

export const updateDeliveryRouteCommand = async (id: number, body: any): Promise<void> => {
    await putRequest({
        url: `/DeliveryRoutes/${id}`,
        body: body,
    });
}

export async function getMeasurementUnitType(): Promise<DictionaryDto[]> {
    const data = await getDictionaryEntitiesFromDB<any>(DictionaryTypeEnum.MeasurementUnitTypes);
    
    return data;
}

export async function getMeasurementUnit(idConditional?: number): Promise<DictionaryDto[]> {
    
    const data = await getDictionaryEntitiesFromDB<any>(DictionaryTypeEnum.MeasurementUnits, { idConditional });
    
    return data;
}

export async function getCategories(): Promise<DictionaryDto[]> {
    
    const data = await getDictionaryEntitiesFromDB<any>(DictionaryTypeEnum.Categories);
    
    return data;
}

export async function getIngredients(): Promise<DictionaryDto[]> {
    
    const data = await getDictionaryEntitiesFromDB<any>(DictionaryTypeEnum.Ingredients);
    
    return data;
}

export async function getOrderPartners(): Promise<any[]> {
    
    const { data } = await getRequest({
        url: `/Partner/GetNewOrderPartners`,
    })

    return data;
}

export async function getOrderPartnerSalePoints(idConditional?: number): Promise<DictionaryDto[]> {
    
    const data = await getDictionaryEntitiesFromDB<any>(DictionaryTypeEnum.AdminNewOrderSalePoints, { idConditional });
    
    return data;
}

export async function getIngredientMeasurementUnit(idConditional?: number): Promise<DictionaryDto[]> {
    
    const data = await getDictionaryEntitiesFromDB<any>(DictionaryTypeEnum.IngredientMeasurementUnit, { idConditional });
    
    return data;
}

export const getItem = async (itemId: any) => {
    const { data } = await getRequest({
        url: `/Items/GetItem`,
        queryParams: {
            id: itemId
        }
    })

    return data;
}

export const updateItemCommand = async (id: any, body: any): Promise<void> => {
    await putRequest({
        url: `/Items/${id}`,
        body: body,
    });
}


export const createItemIngredientCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/ItemIngredients`,
        body: body,
    });
}

export const getAllItemingredients = async (filters: any) => {
    const { data } = await getRequest({
        url: `/ItemIngredients/GetListItemIngredients`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const getItemingredient = async (itemIngredientId: any) => {
    const { data } = await getRequest({
        url: `/ItemIngredients/GetItemIngredient`,
        queryParams: {
            id: itemIngredientId
        }
    })

    return data;
}

export const updateItemIngredientCommand = async (id: any, body: any): Promise<void> => {
    await putRequest({
        url: `/ItemIngredients/${id}`,
        body: body,
    });
}

export async function  saveImageItem(formData: any): Promise<any> {
    const token = window.localStorage.getItem(StorageEnum.Token);

    try {
        const { data } =  await axios.post(
            `${envVars.apiBaseUrl}/Items/UpdateItemImage`, 
            formData, 
            {
              headers: { 
                "Content-Type": ContentTypeEnum.FormData,
                "Authorization": `Bearer ${token}`
              }
            }
        );

        return data;
    } catch (error) {
        return []
    }
}

export async function getImageItem(itemId: any): Promise<any> {
    const { data } = await getRequest({
        url: "/Items/GetItemImage",
        queryParams: {
            itemId: itemId
        }
    })

    return data;
}

export const deleteImageItem = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Items/RemoveItemImage`,
        body: body,
    });
}

export const createPartnerCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Partner`,
        body: body,
    });
}

export const updatePartnerCommand = async (id: number, body: any): Promise<void> => {
    await putRequest({
        url: `/Partner/${id}`,
        body: body,
    });
}

export const getAllPartners = async (filters: any) => {
    const { data } = await getRequest({
        url: `/Partner/GetAllPartners`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const createPartnerSalePointCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/SalePoints`,
        body: body,
    });
}

export const updatePartnerSalePointCommand = async (id: number, body: any): Promise<void> => {
    await putRequest({
        url: `/SalePoints/${id}`,
        body: body,
    });
}

export async function getDeliveryRoutes(): Promise<DictionaryDto[]> {
    
    const data = await getDictionaryEntitiesFromDB<any>(DictionaryTypeEnum.DeliveryRoutes);
    
    return data;
}

export const getAllPartnerSalePoints = async (filters: any) => {
    const { data } = await getRequest({
        url: `/SalePoints/GetPartnerSalePoints`,
        queryParams: {
            ...filters
        }
    })

    return data;
}


export const getAllPartnerUsers = async (filters: any) => {
    const { data } = await getRequest({
        url: `/Partner/GetPartnerUsers`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const registerPartnerUserCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Auth/RegisterPartnerUser`,
        body: body,
    });
}

export const resetPasswordPartnerUserCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Auth/ResetPartnerUserPassword`,
        body: body,
    });
}

export const updatePartnerUserCommand = async (body: any): Promise<void> => {
    await putRequest({
        url: `/Partner/UpdatePartnerUser`,
        body: body,
    });
}

export const getPartnerUser = async (userId: any) => {
    const { data } = await getRequest({
        url: `/Partner/GetPartnerUser`,
        queryParams: {
            userId: userId
        }
    })

    return data;
}

export const createOrderCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Orders/CreateOrderAdmin`,
        body: body,
    });
}

export const createOrderAdminCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Orders/CreateOrderAdmin`,
        body: body,
    });
}

export const updateOrderAdminCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Orders/UpdateOrderAdmin`,
        body: body,
    });
}

export const getAllOrders = async (filters: any) => {
    const { data } = await getRequest({
        url: `/Orders/GetListOrders`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const getOrderDetails = async (orderId: any) => {
    const { data } = await getRequest({
        url: `/Orders/GetOrderDetails`,
        queryParams: {
            orderId: orderId
        }
    })

    return data;
}

export const updateOrderCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Orders/UpdateOrderAdmin`,
        body: body,
    });
}

export const deleteOrderCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Orders/DeleteOrderAdmin`,
        body: body,
    });
}

export const updateOrderStatusCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Orders/UpdateOrderStatus`,
        body: body,
    })
}

export const cancelOrderCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Orders/CancelOrder`,
        body: body,
    })
}

export const updateAdminUserCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Users/UpdateAdminUser`,
        body: body,
    });
}

export const confirmOrderBulk = async (): Promise<void> => {
    await getRequest({
        url: `/Orders/ConfirmOrderBulk`
    });
}

export async function getPartners(): Promise<DictionaryDto[]> {
    
    const data = await getDictionaryEntitiesFromDB<any>(DictionaryTypeEnum.Partners);
    
    return data;
}

export const getLicense = async () => {
    const { data } = await getRequest({
        url: `/Auth/GetLicense`
    })

    return data;
}

export const getCurrentDayOrdersReport = async (filters: any) => {
    const { data } = await getRequest({
        url: `/Orders/GetCurrentDayOrdersReport`,
        queryParams: {
            ...filters
        }  
    })

    return data;
}

export async function getPartnerSalePoints(idConditional: any): Promise<DictionaryDto[]> {
    
    const data = await getDictionaryEntitiesFromDB<any>(DictionaryTypeEnum.PartnerSalesPoints, { idConditional });
    
    return data;
}

export const registerAdminUserCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Auth/Register`,
        body: body,
    });
}

export const getAllAdminUsers = async (filters: any) => {
    const { data } = await getRequest({
        url: `/Users/GetAdminUsers`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const getAdminUser = async (userId: any) => {
    const { data } = await getRequest({
        url: `/Users/GetAdminUserDetails`,
        queryParams: {
            id: userId
        }
    })

    return data;
}

export const checkAuthorizationAdmin = async () => {
    await getRequest({
        url: `/Auth/CheckAuthorizationAdmin`
    })
}

export const getDailyProduction = async (filters: any) => {
    let { data } = await getRequest({
        url: `/Orders/GetDailyProduction`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const getItemObservations = async (itemId: any) => {
    let { data } = await getRequest({
        url: `/Orders/GetItemObservations`,
        queryParams: {
            itemId
        }
    })

    return data;
}

export const processOrders = async (filters: any) => {
    let { data } = await getRequest({
        url: `/Orders/ProcessOrders`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const addDoneQuantity = async (body: any) => {
    await putRequest({
        url: `/Orders/AddDoneQuantity`,
        body
    })
}

export const getDailyProductionDeliveryRoute = async (filers?: any) => {
    let { data } = await getRequest({
        url: `/Orders/GetDailyProductionDeliveryRoute`,
        queryParams: {
            ...filers
        }
    })

    return data;
}

export const getDailyObservationDeliveryRoute = async (filers?: any) => {
    let { data } = await getRequest({
        url: `/Orders/GetDailyObservationDeliveryRoute`,
        queryParams: {
            ...filers
        }
    })

    return data;
}


export const addDoneQuantityDeliveryRoutes = async (body: any) => {
    await putRequest({
        url: `/Orders/AddDoneQuantityDeliveryRoutes`,
        body
    })
}

export const submittedOrders = async (filers?: any) => {
    let { data } = await getRequest({
        url: `/Orders/SubmittedOrders`,
        queryParams: {
            ...filers
        }
    })

    return data;
}

export const getProcessedOrders = async (filters: any) => {
    let { data } = await getRequest({
        url: `/Orders/GetProcessedOrders`,
        queryParams: {
            ...filters
        }
    });

    return data;
}

export const getDailyObservationsDeliveryRoutes = async (filters: any) => {
    let { data } = await getRequest({
        url: `/Orders/GetDailyObservationsDeliveryRoutes`,
        queryParams: {
            ...filters
        }
    });

    return data;
}

export const addDailyObservationDeliveryRoute = async (body: any) => {
    await putRequest({
        url: `/Orders/AddDailyObservationDeliveryRoute`,
        body
    })
}

export async function getDictionaryItems(): Promise<DictionaryDto[]> {
    
    const data = await getDictionaryEntitiesFromDB<any>(DictionaryTypeEnum.Items);
    
    return data;
}

export const getItemExportOrder = async () => {
    let { data } = await getRequest({
        url: `/Items/GetItemExportOrder`
    });

    return data;
}

export const updateItemExportOrder = async (body: any) => {

    await postRequest({
        url: `/Items/UpdateItemExportOrder`,
        body
    })
}

export const getSalePointExportOrder = async (filters: any) => {
    let { data } = await getRequest({
        url: `/SalePoints/GetSalePointExportOrder`,
        queryParams: {
            ...filters
        }
    });

    return data;
}

export const updateSalePointExportOrder = async (body: any) => {

    await postRequest({
        url: `/SalePoints/UpdateSalePointExportOrder`,
        body
    })
}

export const getExcelReportData = async (filters: any) => {
    let { data } = await getRequest({
        url: `/Orders/GetOrdersForDelivery`,
        queryParams: {
            ...filters
        }
    });

    return data;
}

export const getFavoriteItems = async (filters: any) => {
    let { data } = await getRequest({
        url: `/SalePoints/GetFavoriteItems`,
        queryParams: {
            ...filters
        }
    });

    return data;
}

export const addSalePointFavoriteItems = async (body: any) => {

    await postRequest({
        url: `/SalePoints/AddSalePointFavoriteItems`,
        body
    })
}

export const deleteFavoriteItem = async (body: any) => {
    await postRequest({
        url: `/SalePoints/DeleteFavoriteItem`,
        body
    })
}

export const getBatches = async (filters: any) => {
    const { data } = await getRequest({
        url: `/Batch/GetBatches`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export async function getBatchTypes(): Promise<DictionaryDto[]> {
    const data = await getEnumEntitiesFromDB<any>(DictionaryTypeEnum.BatchTypes);
    
    return data;
}

export const createBatchCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Batch`,
        body: body,
    });
}

export const editBatchCommand = async (id: number, body: any): Promise<void> => {
    await putRequest({
        url: `/Batch/${id}`,
        body: body,
    });
}

export const deleteBatchCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Batch/DeleteBatch`,
        body: body,
    });
}


export const getProductCategoryTypes = async (filters: any) => {
    const { data } = await getRequest({
        url: `/ProductCategoryType/GetProductCategoryTypes`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const createProductCategoryTypeCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/ProductCategoryType`,
        body: body,
    });
}

export const editProductCategoryTypeCommand = async (id: number, body: any): Promise<void> => {
    await putRequest({
        url: `/ProductCategoryType/${id}`,
        body: body,
    });
}

export const getProductTypes = async (filters: any) => {
    const { data } = await getRequest({
        url: `/ProductTypes/GetProductTypes`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const createProductTypeCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/ProductTypes`,
        body: body,
    });
}

export const editProductTypeCommand = async (id: number, body: any): Promise<void> => {
    await putRequest({
        url: `/ProductTypes/${id}`,
        body: body,
    });
}

export async function getDictionaryProductCategoryTypes(): Promise<DictionaryDto[]> {
    const data = await getDictionaryEntitiesFromDB<any>(DictionaryTypeEnum.ProductCategoryTypes);
    
    return data;
}

export const getRawProducts = async (filters: any) => {
    const { data } = await getRequest({
        url: `/RawProducts/GetRawProducts`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export async function getDictionaryProductTypes(): Promise<DictionaryDto[]> {
    const data = await getDictionaryEntitiesFromDB<any>(DictionaryTypeEnum.ProductTypes);
    
    return data;
}

export async function getDictionaryRawProductStatus(): Promise<DictionaryDto[]> {
    const data = await getEnumEntitiesFromDB<any>(DictionaryTypeEnum.RawProductStatus);
    
    return data;
}

export async function getDictionaryRawProducts(): Promise<DictionaryDto[]> {
    const data = await getDictionaryEntitiesFromDB<any>(DictionaryTypeEnum.RawProducts);
    
    return data;
}

export async function getDictionaryBatches(): Promise<DictionaryDto[]> {
    const data = await getDictionaryEntitiesFromDB<any>(DictionaryTypeEnum.Batches);
    
    return data;
}

export const createRawProductCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/RawProducts`,
        body: body,
    });
}

export const editRawProductCommand = async (id: number, body: any): Promise<void> => {
    await putRequest({
        url: `/RawProducts/${id}`,
        body: body,
    });
}

export const deleteRawProductCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/RawProducts/DeleteRawProduct`,
        body: body,
    });
}

export const getInventories = async (filters: any) => {
    const { data } = await getRequest({
        url: `/Inventory/GetInventories`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const createInventoryCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Inventory`,
        body: body,
    });
}

export const editInventoryCommand = async (id: number, body: any): Promise<void> => {
    await putRequest({
        url: `/Inventory/${id}`,
        body: body,
    });
}

export const deleteInventoryCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Inventory/DeleteInventory`,
        body: body,
    });
}

export const getReceipts =  async (filters: any) => {
    const { data } = await getRequest({
        url: `/Receipts/GetReceipts`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const getReceipt =  async (filters: any) => {
    const { data } = await getRequest({
        url: `/Receipts/GetReceipt`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const getComputeReceiptData =  async (filters: any) => {
    const { data } = await getRequest({
        url: `/ItemReceipts/ComputeReceiptData`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const createReceiptCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Receipts`,
        body: body,
    });
}

export const editReceiptCommand = async (id: number, body: any): Promise<void> => {
    await putRequest({
        url: `/Receipts/${id}`,
        body: body,
    });
}

export const deleteReceiptCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/Receipts/DeleteReceipt`,
        body: body,
    });
}

export const getItemReceipts =  async (filters: any) => {
    const { data } = await getRequest({
        url: `/ItemReceipts/GetItemReceipts`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const getReceiptsList =  async (filters: any) => {
    const { data } = await getRequest({
        url: `/Receipts/GetReceiptsList`,
        queryParams: {
            ...filters
        }
    })

    return data;
}
export const getItemReceipt =  async (filters: any) => {
    const { data } = await getRequest({
        url: `/ItemReceipts/GetItemReceipt`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const createItemReceiptCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/ItemReceipts`,
        body: body,
    });
}

export const editItemReceiptCommand = async (id: number, body: any): Promise<void> => {
    await putRequest({
        url: `/ItemReceipts/${id}`,
        body: body,
    });
}

export const deleteItemReceiptCommand = async (body: any): Promise<void> => {
    await postRequest({
        url: `/ItemReceipts/DeleteItemReceipt`,
        body: body,
    });
}

export async function getDictionaryItemReceiptStatus(): Promise<DictionaryDto[]> {
    
    const data = await getEnumEntitiesFromDB<any>(DictionaryTypeEnum.ItemReceiptStatus);
    
    return data;
}

export async function getDictionaryReceiptsConditional(itemId: number): Promise<DictionaryDto[]> {
    
    const data = await getDictionaryEntitiesFromDB<any>(DictionaryTypeEnum.ReceiptsConditional, {
        idConditional: itemId
    });
    
    return data;
}

export async function getDictionarItemReceipts(): Promise<DictionaryDto[]> {
    
    const data = await getDictionaryEntitiesFromDB<any>(DictionaryTypeEnum.ItemReceipts);
    
    return data;
}

export const updateItemReceiptStatus = async (body: any): Promise<void> => {

    await putRequest({
        url: `/ItemReceipts/UpdateItemReceiptStatus`,
        body: body,
    });
}

export async function getDictionaryRawProductsConditional(id: number): Promise<DictionaryDto[]> {
    
    const data = await getDictionaryEntitiesFromDB<any>(DictionaryTypeEnum.RawProductsConditional, {
        idConditional: id
    });
    
    return data;
}

export const getRawProductsList =  async (filters: any) => {
    const { data } = await getRequest({
        url: `/RawProducts/GetRawProductsList`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export async function getDictionaryReceiptProductQuantityTypes(): Promise<DictionaryDto[]> {
    const data = await getEnumEntitiesFromDB<any>(DictionaryTypeEnum.ReceiptProductQuantityType);
    
    return data;
}

export const updateRawProductStatus = async (id: number, body: any): Promise<void> => {

    await putRequest({
        url: `/RawProducts/UpdateRawProductStatus/${id}`,
        body: body,
    });
}

export const updateSliceProcess = async (id: number, body: any): Promise<void> => {

    await putRequest({
        url: `/SliceProcess/UpdateSliceProcess/${id}`,
        body: body,
    });
}

export async function getDictionaryOrderStatuses(): Promise<DictionaryDto[]> {
    const data = await getEnumEntitiesFromDB<any>(DictionaryTypeEnum.OrderStatus);
    
    return data;
}

export const getMeasurementUnitList =  async (filters?: any) => {
    const { data } = await getRequest({
        url: `/MeasurementUnit/GetMeasurementUnitList`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const getDictionaryItemsList =  async (filters?: any) => {
    const { data } = await getRequest({
        url: `/Items/GetDictionaryItems`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

export const getLogs =  async (filters: any) => {
    const { data } = await getRequest({
        url: `/Logs/GetLogs`,
        queryParams: {
            ...filters
        }
    })

    return data;
}

/** make call to db to get dictionary entities specified in the params of the function */
export async function getDictionaryEntitiesFromDB<T>(id: DictionaryTypeEnum, otherParams: any = {}): Promise<T[]> {

    const { data } = await getRequest({
        url: `/Dictionary/Get`,
        queryParams: {
            id: id, 
            ...otherParams
        }
    })

    return data
}

/** make call to db to get enum entities specified in the params of the function */
export async function getEnumEntitiesFromDB<T>(id: DictionaryTypeEnum, otherParams: any = {}): Promise<T[]> {
    const { data } = await getRequest({
        url: `/Dictionary/GetEnum`,
        queryParams: {
            id: id, 
            ...otherParams
        }
    })

    return data
}

export const blobToBase64 = (blob: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };


