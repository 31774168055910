import { observer } from "mobx-react-lite";
import SelectProductTypesPanelStyled from "./select-product-types-panel.component.styled";
import { useEffect, useMemo, useState } from "react";
import { getDictionaryProductCategoryTypes, getProductTypes } from "../../../../utils/requests";
import { isEmpty } from "lodash";
import { displayCustomToast } from "../../../../utils/display-custom-toast";
import TableComponent, { CustomRendererPropsType, TableDataPropsType } from "../../../table/table.component";
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import ButtonComponent from "../../../button/button.component";
import { useStore } from "../../../../hooks/store.hook";
import { HeaderTypeEnum } from "../../../../enums/header-type.enum";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { ProductCategoryTypes } from "../../../../enums/product-category-types.enum";

export type SelectProductTypesPanelPropsType = {
    // productTypeId: number,
    // itemReceiptId?: number,
    selectedItems?: any[];
    setSelectedItems?: (value: any) => void;
}


const SelectProductTypesPanel = observer(({
    selectedItems,
    setSelectedItems
}: SelectProductTypesPanelPropsType) => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>();
    const [filtersTable, setFilters] = useState<any>({
          pageIndex: 0,
          pageSize: 5,
          selectFilterValue: null,
        //   secondSelectFilterValue: null,
        //   tabFilterValue: undefined,
          searchText: null
    });
    const [refreshData, setRefreshData] = useState<boolean>(true);
    const [productCategoryTypes, setProductCategoryTypes] = useState<any[]>([]);
    const uiStore = useStore('uiStore');

    const getBasicSelectOptions = async () => {
        try {

            let productCategoryTypes = await getDictionaryProductCategoryTypes();
            
            setProductCategoryTypes(() => productCategoryTypes);

        } catch (error: any) {

            displayCustomToast(error.content, true);
        }
    }

    useEffect(
        () => {
            async function getData() {
                try {
                    setIsLoading(() => true);

                    await getBasicSelectOptions();

                    let fetchJobs = await getProductTypes({
                        pageIndex: filtersTable.pageIndex + 1,
                        pageSize: filtersTable.pageSize,
                        searchText: filtersTable.searchText,
                        productCategoryTypeId: ProductCategoryTypes.Carne
                    });
                    setData(() => fetchJobs)
                    setIsLoading(() => false)
                } catch (error: any) {
                    displayCustomToast(error.content, true);
                    setIsLoading(() => false)
                }
            }
            
            if(!refreshData) return;

            getData();
            setRefreshData(() => false);
            
        },
        [filtersTable, refreshData]
    )

    const customRenderer: CustomRendererPropsType = useMemo(
        () => {
            return {
                isActive: (row: any) => {
                    return (
                        <>
                            {
                                row.isActive ?
                                    <CheckIcon color="success"  />
                                    :
                                    <CloseIcon color="error"  />
                            }
                        </>
                    )
                },
                hasBatch: (row: any) => {
                    return (
                        <>
                            {
                                row.hasBatch ?
                                    <CheckIcon color="success"  />
                                    :
                                    <CloseIcon color="error"  />
                            }
                        </>
                    )
                },
                productCategoryType: (row: any) => {
                    return (
                        <>
                            {row.productCategoryType.name}
                        </>
                    )
                },
                measurementUnitType: (row: any) => {
                    return (
                        <>
                            {row.measurementUnitType.name}
                        </>
                    )
                },
            }
        },
        []
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!data) return {
                data: [],
                headers: []
            }

            return {
                data: data.data,
                headers: [
                   {
                        id: 'name',
                        label: 'Nume',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'left',
                        sortable: false
                    },
                   {
                        id: 'productCategoryType',
                        label: 'Tip categorie produs',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'left',
                        sortable: false
                    }
                ],
                totalElements: data.totalNumber
            }
        },
        [data]
    )

    const onSelectProducts = () => {
        uiStore.dismissPanel({
            data: {
                dismissed: true
            }
        });
    }

    return (
        <SelectProductTypesPanelStyled>
            <TableComponent
                tableKey={TableTypeEnum.AllProductTypes} 
                viewType="panel"
                tableData={tableData}
                searchBarPlaceholder="Cauta dupa nume"
                isLoading={isLoading}
                tableState={filtersTable}
                setTableState={setFilters}
                setRefreshData={setRefreshData}
                noSelectAllCheckbox
                onSelectRows={(value) =>  setSelectedItems && setSelectedItems(value.selectedItems)}
                customRenderer={customRenderer}
            />

            <div className="button-container">
                <ButtonComponent 
                    onClick={onSelectProducts}
                >
                    Selecteaza
                </ButtonComponent>
            </div>
        </SelectProductTypesPanelStyled>
    )
});

export default SelectProductTypesPanel;