import { Tooltip } from "@mui/material";
import { shortenString } from "../../utils/utils";

type TooltipComponentPropsType = {
    title?: string,
    description?: string,
    panelSubTitle?: string,
    titleLimit?: number
}

const TooltipComponent = ({
    title,
    description,
    panelSubTitle,
    titleLimit
}: TooltipComponentPropsType) => {

    return (
        <>
            {
                title ?
                    <Tooltip title={title}>
                        <div className="product-sku">
                            {shortenString(title, titleLimit ?? 50)}
                        </div>
                    </Tooltip>
                :
                ''
            }
            {
                description ?

                    <Tooltip title={description}>
                        <div className="product-name">
                            {shortenString(description, titleLimit ?? 10)}
                        </div>
                    </Tooltip>

                    :
                    ''
            }
            {
                panelSubTitle ?
                    <Tooltip title={panelSubTitle}>
                        <div className="product-name">
                            {shortenString(panelSubTitle, titleLimit ?? 40)}
                        </div>
                    </Tooltip>

                    :
                    ''
            }
        </>
    )
}

export default TooltipComponent;