export enum DictionaryTypeEnum {
    MeasurementUnitTypes = 1,
    OrderStatus = 2,
    MeasurementUnits = 3,
    Categories = 4,
    Items = 5,
    Ingredients = 6,
    IngredientMeasurementUnit = 7,
    ItemIngredients = 8,
    SalePoints = 9,
    DeliveryRoutes = 10,
    Users = 11,
    UserSalePoints = 12,
    AdminNewOrderPartners = 13,
    AdminNewOrderSalePoints = 14,
    Partners = 15,
    PartnerSalesPoints = 16,
    BatchTypes = 17,
    ProductCategoryTypes = 18,
    Batches = 19,
    ProductTypes = 20,
    RawProductStatus = 21,
    RawProducts = 22,
    ReceiptProductQuantityType = 23,
    ItemReceiptStatus = 24,
    ReceiptsConditional = 25,
    ItemReceipts = 26,
    RawProductsConditional = 27
}