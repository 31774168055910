import { observer } from "mobx-react-lite";
import SliceProcessPageStyled from "./slice-process.page.styled";
import PageTemplateComponent from "../../../../components/page-template/page-template.component";
import TableComponent, { ActionItemPropsType, CustomRendererPropsType, TableDataPropsType } from "../../../../components/table/table.component";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../hooks/store.hook";
import { PanelType } from "../../../../enums/panel-type.enum";
import SliceProcessPanel from "../../../../components/slice-process-panel/slice-process-panel.component";
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import { getRawProducts, updateRawProductStatus } from "../../../../utils/requests";
import { displayCustomToast } from "../../../../utils/display-custom-toast";
import { HeaderTypeEnum } from "../../../../enums/header-type.enum";
import { EditOutlined, SyncAltOutlined } from "@mui/icons-material";
import { RawProductStatusDisplay, RawProductStatusEnum } from "../../../../enums/raw-product-status.enum";
import { Chip } from "@mui/material";
import { DialogResponseTypeEnum } from "../../../../enums/dialog-response-type.enum";
import TooltipComponent from "../../../../components/tooltip/tooltip.component";
import { isEmpty } from "lodash";

const SliceProcessPage = observer(() => {

    const uiStore = useStore('uiStore');
    const [actionItemsClick, setActionItemsClick] = useState<any>();
    const [refreshData, setRefreshData] = useState<boolean>(true);
    const [data, setData] = useState<any>();
    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [filtersTable, setFilters] = useState<any>({
          pageIndex: 0,
          pageSize: 5,
        //   selectFilterValue: null,
        //   secondSelectFilterValue: null,
          tabFilterValue: [RawProductStatusEnum.IsSlicing, RawProductStatusEnum.Sliced],
          searchText: null
    });

    const createData = useCallback(
        () => {
            uiStore.openPanel({
                key: PanelType.CreateInventory,
                component: <SliceProcessPanel />,
                panelWidth: '700px',
                title: `Creare`,
                onDismiss: (data: any) => {
                    if(!data) return;
                    
                    if(data?.refreshData)
                        setRefreshData(() => true);
                }
            });
        },
        []
    )

    useEffect(
        () => {
            async function getData() {
                try {
                    setDataLoading(() => true)
                    let fetchJobs = await getRawProducts({
                        pageIndex: filtersTable.pageIndex + 1,
                        pageSize: filtersTable.pageSize,
                        searchText: filtersTable.searchText,
                        statusIds: filtersTable.tabFilterValue
                    });
                    setData(() => fetchJobs)
                    setDataLoading(() => false)
                } catch (error: any) {
                    displayCustomToast(error.content, true);
                    setDataLoading(() => false)
                }
            }
            
            if(!refreshData) return;

            getData();
            setRefreshData(() => false);
            
        },
        [filtersTable, refreshData]
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!data) return {
                data: [],
                headers: []
            }

            return {
                data: data.data,
                headers: [
                   {
                        id: 'productType',
                        label: 'Nume',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'left',
                        sortable: false
                    },
                   {
                        id: 'description',
                        label: 'Descriere',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false,
                        width: "20rem"
                    },
                   {
                        id: 'batch',
                        label: 'Lot',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'status',
                        label: 'Status',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    }
                ],
                totalElements: data.totalNumber
            }
        },
        [data]
    )

    const actionItems: ActionItemPropsType[] = useMemo(
        () => {
            return [
                {
                    text: "Editeaza",
                    icon: <EditOutlined />,
                    color: "black",
                    fOnClick: (row: any) => {
                        uiStore.openPanel({
                            key: PanelType.EditBatch,
                            component: <SliceProcessPanel 
                                rawProductInSlicingId={row.id}
                                rawProductInSlicingDetails={row}
                            />,
                            panelWidth: '700px',
                            title: `Editeaza: ${row.productType.name}`,
                            onDismiss: (data: any) => {
                                if(!data) return;
                                
                                if(data?.refreshData)
                                    setRefreshData(() => true);
                            }
                        });
                    }
                },
                {
                    text: 'Finalizeaza proces',
                    icon: <SyncAltOutlined />,
                    color: 'green',
                    fOnClick: async (row: any) => {
                        const response = await uiStore.openDialog({
                            title: "Atentie...",
                            message: "Esti sigur ca vrei sa continui?",
                        })    

                        if(response.value === DialogResponseTypeEnum.Confirm) {
                            try {
                                setDataLoading(() => true);
                                let body = {
                                    rawProductId: row.id,
                                    rawProductStatusId: RawProductStatusEnum.Sliced
                                }
                                await updateRawProductStatus(row.id, body);
                                displayCustomToast("Proces finalizat cu succes");
                                setDataLoading(() => false);
                                setRefreshData(() => true);
                            }
                            catch (error: any) {
                                displayCustomToast(error.content, true);
                                setDataLoading(() => false)
                            }
                        }    
                    },
                    canDisplay: (row: any) => row?.status?.id === RawProductStatusEnum.Sliced ? false : true 
                },
            ]
        },
        [actionItemsClick]
    )

    const customRenderer: CustomRendererPropsType = {
        productType: (row: any) => {
            return (
                <>
                    {row.productType.name}
                </>
            )
        },
        batch: (row: any) => {
            return (
                <>
                    {row.batch.name}
                </>
            )
        },
        status: (row: any) => {
            return (
                <>
                    {
                        row.status.id === RawProductStatusEnum.IsSlicing ?
                            <Chip 
                                label={row.status.name} 
                                color="primary" 
                                variant="outlined" 
                            />
                            :
                            <Chip 
                                label={row.status.name} 
                                color="success" 
                                variant="outlined" 
                            />
                    }
                </>
            )
        },
        description: (row: any) => {
            return (
                <>
                {
                    isEmpty(row.description) ?
                        "-"
                        :
                        <TooltipComponent title={row.description ?? ''} titleLimit={90} />
                }
                </>
            )
        },
    }

    var tabsOptions = useMemo(
        () => {
            return [
                {
                    label: "Toate",
                    value: [RawProductStatusEnum.IsSlicing, RawProductStatusEnum.Sliced],
                },
                {
                    label: RawProductStatusDisplay[RawProductStatusEnum.IsSlicing],
                    value: [RawProductStatusEnum.IsSlicing],
                },
                {
                    label: RawProductStatusDisplay[RawProductStatusEnum.Sliced],
                    value: [RawProductStatusEnum.Sliced],
                },
            ]
        },
        []
    )

    return (
        <SliceProcessPageStyled>
            <PageTemplateComponent 
                title="Proces de transare"
                buttons={[
                    {
                        fOnClick: () => {
                            createData()
                        },
                        text: "Creeaza"
                    }
                ]}
            >
                <TableComponent
                    tableKey={TableTypeEnum.InSlicingRawProducts} 
                    viewType="normal"
                    tableData={tableData}
                    searchBarPlaceholder="Cauta dupa nume"
                    withoutSelect
                    isLoading={dataLoading}
                    tableState={filtersTable}
                    setTableState={setFilters}
                    actionItems={actionItems}
                    actionItemsClick={setActionItemsClick}
                    setRefreshData={setRefreshData}
                    customRenderer={customRenderer}
                    tabs={tabsOptions}
                /> 
            </PageTemplateComponent>
        </SliceProcessPageStyled>
    )
});

export default  SliceProcessPage;