import { observer } from "mobx-react-lite";
import ReceiptsPageStyled from "./receipts.page.styled";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../hooks/store.hook";
import { displayCustomToast } from "../../../../utils/display-custom-toast";
import { deleteReceiptCommand, getReceipts } from "../../../../utils/requests";
import TableComponent, { ActionItemPropsType, CustomRendererPropsType, TableDataPropsType } from "../../../../components/table/table.component";
import { DialogResponseTypeEnum } from "../../../../enums/dialog-response-type.enum";
import { PanelType } from "../../../../enums/panel-type.enum";
import { HeaderTypeEnum } from "../../../../enums/header-type.enum";
import { DeleteOutlined, EditOutlined } from "@mui/icons-material";
import PageTemplateComponent from "../../../../components/page-template/page-template.component";
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import ReceiptPanel from "../../../../components/receipt-panel/receipt-panel.component";

const ReceiptsPage = observer(() => {

    const [refreshData, setRefreshData] = useState<boolean>(true);
    const [data, setData] = useState<any>();
    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [filtersTable, setFilters] = useState<any>({
          pageIndex: 0,
          pageSize: 5,
        //   selectFilterValue: null,
        //   secondSelectFilterValue: null,
        //   tabFilterValue: undefined,
          searchText: null
    });
    const uiStore = useStore('uiStore');
    const [actionItemsClick, setActionItemsClick] = useState<any>();

    const createData = useCallback(
        () => {
            uiStore.openPanel({
                key: PanelType.CreateReceipt,
                component: <ReceiptPanel />,
                panelWidth: '850px',
                title: `Creare reteta`,
                onDismiss: (data: any) => {
                    if(!data) return;
                    
                    if(data?.refreshData)
                        setRefreshData(() => true);
                }
            });
        },
        []
    )

    useEffect(
        () => {
            async function getData() {
                try {
                    setDataLoading(() => true)
                    let fetchJobs = await getReceipts({
                        pageIndex:filtersTable.pageIndex + 1,
                        pageSize:filtersTable.pageSize,
                        searchText:filtersTable.searchText
                    });
                    setData(() => fetchJobs)
                    setDataLoading(() => false)
                } catch (error: any) {
                    displayCustomToast(error.content, true);
                    setDataLoading(() => false)
                }
            }
            
            if(!refreshData) return;

            getData();
            setRefreshData(() => false);
            
        },
        [filtersTable, refreshData]
    )

    const customRenderer: CustomRendererPropsType = {
        item: (row: any) => {
            return (
                <>
                    {row.item.name}
                </>
            )
        }
    }

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!data) return {
                data: [],
                headers: []
            }

            return {
                data: data.data,
                headers: [
                   {
                        id: 'name',
                        label: 'Nume',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'left',
                        sortable: false,
                        width: "10rem"
                    },
                   {
                        id: 'item',
                        label: 'Produs',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'left',
                        sortable: false,
                        width: "10rem"
                    }
                ],
                totalElements: data.totalNumber
            }
        },
        [data]
    )

    const actionItems: ActionItemPropsType[] = useMemo(
        () => {
            return [
                {
                    text: "Editeaza reteta",
                    icon: <EditOutlined />,
                    color: "black",
                    fOnClick: (row: any) => {
                        uiStore.openPanel({
                            key: PanelType.EditReceipt,
                            component: <ReceiptPanel 
                                receiptId={row.id}
                                receiptDetails={row}
                            />,
                            panelWidth: '850px',
                            title: `Editeaza reteta: ${row.item.name}`,
                            onDismiss: (data: any) => {
                                if(!data) return;
                                
                                if(data?.refreshData)
                                    setRefreshData(() => true);
                            }
                        });
                    }
                },
                {
                    text: "Sterge reteta",
                    icon: <DeleteOutlined />,
                    color: "red",
                    async fOnClick(row: any){
                        if(row) {   
                            const response = await uiStore.openDialog({
                                title: "Stergi lotul...",
                                message: "Esti sigur ca vrei sa continui?",
                            })    

                            if(response.value === DialogResponseTypeEnum.Confirm) {
                                try {
                                    setDataLoading(() => true);
                                    await deleteReceiptCommand({
                                        id: row.id
                                    });
                                    displayCustomToast("Reteta a fost stearsa");
                                    setDataLoading(() => false);
                                    setRefreshData(() => true);
                                }
                                catch (error: any) {
                                    displayCustomToast(error.content, true);
                                    setDataLoading(() => false)
                                }
                            }    
                        } 
                    }
                }
            ]
        },
        [actionItemsClick]
    )

    return (
        <ReceiptsPageStyled>
            <PageTemplateComponent 
                title="Retete"
                buttons={[
                    {
                        fOnClick: () => {
                            createData()
                        },
                        text: "Creeaza"
                    }
                ]}
            >
                <TableComponent
                    tableKey={TableTypeEnum.AllReceipts} 
                    viewType="normal"
                    tableData={tableData}
                    searchBarPlaceholder="Cauta dupa nume"
                    withoutSelect
                    isLoading={dataLoading}
                    tableState={filtersTable}
                    setTableState={setFilters}
                    actionItems={actionItems}
                    actionItemsClick={setActionItemsClick}
                    setRefreshData={setRefreshData}
                    customRenderer={customRenderer}
                />  
            </PageTemplateComponent>
        </ReceiptsPageStyled>
    )
});

export default ReceiptsPage;