import {DashboardPageStyled} from "./dashboard.page.styled";
import PageTemplateComponent from "../../../../components/page-template/page-template.component";
import { observer } from "mobx-react-lite";
import { getCurrentDayOrdersReport, getDeliveryRoutes } from "../../../../utils/requests";
import { useEffect, useMemo, useState } from "react";
import { displayCustomToast } from "../../../../utils/display-custom-toast";
import { HeaderTypeEnum } from "../../../../enums/header-type.enum";
import TableComponent, { ActionItemPropsType, CustomRendererPropsType, TableDataPropsType } from "../../../../components/table/table.component";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import { isEmpty } from "lodash";
import { formatDate } from "../../../../utils/utils";
import { Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import TextFieldComponent from "../../../../components/text-field/text-field.component";
import { useStore } from "../../../../hooks/store.hook";
import { PanelType } from "../../../../enums/panel-type.enum";
import CreateOrderPanel from "../../../../components/create-order-panel/create-order-panel.component";
import AddIcon from '@mui/icons-material/Add';

const DashboardPage = observer(() => {

    const [filtersTable, setFilters] = useState<any>({
        pageIndex: 0,
        pageSize: 5,
      //   selectFilterValue: null,
      //   secondSelectFilterValue: null,
      //   tabFilterValue: undefined,
        searchText: null
    });
    const [refreshData, setRefreshData] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>();
    const [deliveryRoutes, setDeliveryRoutes] = useState<any[]>([]);
    const [targetDate, setTargetDate] = useState<any>(new Date());
    const [actionItemsClick, setActionItemsClick] = useState<any>();
    const uiStore = useStore('uiStore');

    const [testText, setTestText] = useState<any>(0)

    useEffect(
        () => {
            async function getData() {
                try {
                    setLoading(() => true)
                    let fetchJobs = await getCurrentDayOrdersReport({
                        pageIndex:filtersTable.pageIndex + 1,
                        pageSize:filtersTable.pageSize,
                        searchText:filtersTable.searchText,
                        deliveryRouteId: filtersTable.selectFilterValue?.value,
                        targetDate: isEmpty(targetDate) ? formatDate(new Date()) : formatDate(targetDate)
                    });
                    setData(() => fetchJobs)
                    setLoading(() => false)
                } catch (error: any) {
                    displayCustomToast(error.content, true);
                    setLoading(() => false)
                }
            }
            
            if(!refreshData) return;

            getData();
            setRefreshData(() => false);
            
        },
        [filtersTable, refreshData]
    )

    useEffect(
        () => {
            getDeliveryRoutes()
            .then(data => {
                setDeliveryRoutes(() => data);
            })
            .catch(error => {
                displayCustomToast(error, true);
            })
        },
        []
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!data) return {
                data: [],
                headers: []
            }

            return {
                data: data.data,
                headers: [
                   {
                       id: 'partnerName',
                       label: 'Nume partener',
                       headerType: HeaderTypeEnum.Numeric,
                       alignment: 'left',
                       sortable: false
                   },
                   {
                        id: 'partnerPhoneNumber',
                        label: 'Numar telefon partner',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'salePointName',
                        label: 'Punct de livrare',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                        id: 'salePointPhoneNumber',
                        label: 'Numar de telefon punct de livrare',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                        id: 'hasOrdered',
                        label: 'A comandat',
                        headerType: HeaderTypeEnum.Boolean,
                        alignment: 'center',
                        sortable: false
                    }
                ],
                totalElements: data.totalNumber
            }
        },
        [data]
    )

    const customRenderer: CustomRendererPropsType = useMemo(
        () => {
            return {
                hasOrdered: (row: any) => {
                    return (
                        <>
                            {
                                row.hasOrdered ?
                                    <CheckIcon color="success"  />
                                    :
                                    <CloseIcon color="error"  />
                            }
                        </>
                    )
                }
            }
        },
        []
    )

    // const actionItems: ActionItemPropsType[] = useMemo(
    //     () => {
    //         return [
    //             {
    //                 text: "Creează comandă",
    //                 icon: <AddIcon />,
    //                 color: "black",
    //                 fOnClick: (row: any) => {
    //                     uiStore.openPanel({
    //                         key: PanelType.CreateOrder,
    //                         component: <CreateOrderPanel 
    //                             defaultPartner={{
    //                                 label: row.partnerName,
    //                                 value: row.partnerId
    //                             }}
    //                             defaultSalePoint={{
    //                                 label: row.salePointName,
    //                                 value: row.salePointId
    //                             }}
    //                         />,
    //                         panelWidth: '700px',
    //                         title: `Creează comandă`,
    //                         onDismiss: (data: any) => {
    //                             if (!data) return;

    //                             if (data?.refreshData)
    //                                 setRefreshData(() => true);
    //                         }
    //                     });
    //                 }
    //             },
    //         ];
    //     },
    //     [actionItemsClick]
    // )

    /** define the return statement bellow */
    return (
        <DashboardPageStyled>
            <PageTemplateComponent title="Dashboard">
                {/* <h3>Raport comenzi</h3>
                <Box
                    width="100%"
                    padding="0.5rem"
                >
                    <DatePicker
                        value={targetDate}
                        renderInput={(params) => <TextFieldComponent 
                            {...params} 
                            fullWidth={true} 
                            variant="outlined" 
                            label="Selectează zi"
                        />}
                        onChange={newValue =>{ 
                            setTargetDate(() => newValue)
                            setRefreshData(() => true);
                        }}
                        mask="__.__.____"
                        
                    />
                </Box>
                <TableComponent
                    tableKey={TableTypeEnum.CurrentDayOrdersReport} 
                    viewType="normal"
                    tableData={tableData}
                    searchBarPlaceholder="Cauta dupa nume partener"
                    withoutSelect
                    isLoading={loading}
                    tableState={filtersTable}
                    setTableState={setFilters}
                    setRefreshData={setRefreshData}
                    customRenderer={customRenderer}
                    dropdownPlaceholder="Rute de livrare"
                    dropdownOptions={deliveryRoutes?.map(p => ({
                        label: p.name,
                        value: p.id
                    }))}
                    actionItems={actionItems}
                    actionItemsClick={actionItemsClick}
                />  */}

                {/* <TextFieldComponent
                    label={`Test field`}
                    variant="outlined"
                    fullWidth={true}
                    value={testText}
                    type="number"
                    onTextChange={setTestText}
                /> */}
            </PageTemplateComponent>    
        </DashboardPageStyled>
    )

})

export default DashboardPage;