import { observer } from "mobx-react-lite";
import SelectOrderItemsComponentStyled from "./select-order-items.component.styled";
import { useEffect, useMemo, useState } from "react";
import TableComponent, { CustomRendererPropsType, TableDataPropsType } from "../../../table/table.component";
import { HeaderTypeEnum } from "../../../../enums/header-type.enum";
import { getAllItems } from "../../../../utils/requests";
import { displayCustomToast } from "../../../../utils/display-custom-toast";
import { useStore } from "../../../../hooks/store.hook";
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import ButtonComponent from "../../../button/button.component";
import TooltipComponent from "../../../tooltip/tooltip.component";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

export type SelectOrderItemsComponentPropsType = {
    selectedItems?: any[];
    setSelectedItems?: (value: any) => void;
}

const SelectOrderItemsComponent = observer(({
    selectedItems,
    setSelectedItems
}: SelectOrderItemsComponentPropsType) => {

    const uiStore = useStore('uiStore');
    const [refreshData, setRefreshData] = useState<boolean>(true);
    const [data, setData] = useState<any>();
    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [filtersTable, setFilters] = useState<any>({
        pageIndex: 0,
        pageSize: 5,
      //   selectFilterValue: null,
      //   secondSelectFilterValue: null,
      //   tabFilterValue: undefined,
        searchText: null
    });

    useEffect(
        () => {
            async function getData() {
                try {
                    setDataLoading(() => true)
                    let fetchData = await getAllItems({
                        pageIndex:filtersTable.pageIndex + 1,
                        pageSize:filtersTable.pageSize,
                        searchText:filtersTable.searchText
                    });
                    setData(() => fetchData)
                    setDataLoading(() => false)
                } catch (error: any) {
                    displayCustomToast(error.content, true);
                    setDataLoading(() => false)
                }
            }
            
            if(!refreshData) return;

            getData();
            setRefreshData(() => false);
            
        },
        [filtersTable, refreshData]
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!data) return {
                data: [],
                headers: []
            }

            return {
                data: data.data,
                headers: [
                   {
                        id: 'name',
                        label: 'Nume',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'left',
                        sortable: false
                    },
                   {
                        id: 'description',
                        label: 'Descriere',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'isActive',
                        label: 'Activ',
                        headerType: HeaderTypeEnum.Boolean,
                        alignment: 'center',
                        sortable: false
                    }
                ],
                totalElements: data.totalNumber
            }
        },
        [data]
    )

    const customRenderer: CustomRendererPropsType = useMemo(
        () => {
            return {
                description: (row: any) => {
                    return (
                        <TooltipComponent title={row.description ?? ''} />
                    )
                },
                isActive: (row: any) => {
                    return (
                        <>
                            {
                                row.isActive ?
                                    <CheckIcon color="success"  />
                                    :
                                    <CloseIcon color="error"  />
                            }
                        </>
                    )
                }
            }
        },
        []
    )

    const onSelectProducts = () => {
        uiStore.dismissPanel({
            data: {
                dismissed: true
            }
        });
    }

    return (
        <SelectOrderItemsComponentStyled>
            <TableComponent
                tableKey={TableTypeEnum.AllItems} 
                viewType="panel"
                tableData={tableData}
                searchBarPlaceholder="Cauta dupa nume"
                isLoading={dataLoading}
                tableState={filtersTable}
                setTableState={setFilters}
                setRefreshData={setRefreshData}
                noSelectAllCheckbox
                preselectedItems={selectedItems}
                onSelectRows={(value) =>  setSelectedItems && setSelectedItems(value.selectedItems)}
                customRenderer={customRenderer}
            />

            <div className="button-container">
                <ButtonComponent 
                    onClick={onSelectProducts}
                >
                    Selecteaza
                </ButtonComponent>
            </div>
        </SelectOrderItemsComponentStyled>
    )
});

export default SelectOrderItemsComponent;