export enum PanelType {
    CreateCategory,
    EditCategory,
    CreateIngredient,
    EditIngredient,
    CreateItem,
    EditItem,
    CreateDeliveryRoute,
    EditDeliveryRoute,
    ItemIgredients,
    CreateItemIngredients,
    EditItemIngredients,
    CreatePartner,
    EditPartner,
    CreateSalePoint,
    EditSalePoint,
    SalePoints,
    PartnerUsers,
    CreatePartnerUsers,
    EditPartnerUser,
    ResetPasswordPartnerUsers,
    CreateOrder,
    AddProductOrder,
    UpdateImageItem,
    CreateInsiderUser,
    UpdateInsideUser,
    ResetPasswordAdminUsers,
    SeeItemObservations,
    FavoriteItems,
    CreateBatch,
    EditBatch,
    CreateProductCategroyType,
    CreateProducType,
    CreateRawProduct,
    CreateInventory,
    CreateReceipt,
    EditReceipt,
    CreateItemReceipt,
    EditItemReceipt,
    AddProductTypes
}