export enum RawProductStatusEnum {
    New = 1,
    IsSlicing = 2,
    Sliced = 3,
    InProgress = 4,
    PartialProcessed = 5,
    Processed = 6
}

export let RawProductStatusDisplay: {[key in RawProductStatusEnum] : string} = {
    [RawProductStatusEnum.New]: "Noua",
    [RawProductStatusEnum.IsSlicing]: "In curs de transare",
    [RawProductStatusEnum.Sliced]: "Transata",
    [RawProductStatusEnum.InProgress]: "In curs de procesare",
    [RawProductStatusEnum.PartialProcessed]: "Procesata partial",
    [RawProductStatusEnum.Processed]: "Procesata",
};