import { Navigate, RouteObject, useRoutes } from "react-router-dom"
import { RoleEnum } from "../../enums/role.enum";
import { getUser } from "../../utils/get-user.util";
import DashboardPage from "./pages/dashboard/dashboard.page";
import ProfilePage from "./pages/profile/profile.page";
import JobsPage from "./pages/categories/categories.page";
import IngredientsPage from "./pages/ingredients/ingredients.page";
import ItemsPage from "./pages/items/items.page";
import DeliveryRoutesPage from "./pages/delivery-routes/delivery-routes.page";
import PartnersPage from "./pages/partners/partners.page";
import OrdersPage from "./pages/orders/orders.page";
import InsideUsersPage from "./pages/inside-users/inside-users.page";
import ProductionZonePage from "./pages/production-zone/production-zone.page";
import DeliveryZonePage from "./pages/delivery-zone/delivery-zone.page";
import DailyObservationsDeliveryRoutePage from "./pages/daily-observations-delivery-route/daily-observations-delivery-route.page";
import BatchesPage from "./pages/batches/batches.page";
import ProductCategoryTypePage from "./pages/product-category-type/product-category-type.page";
import ProductTypePage from "./pages/product-type/product-type.page";
import RawProductsPage from "./pages/raw-products/raw-products.page";
import InventoriesPage from "./pages/inventories/inventories.page";
import ItemReceiptsPage from "./pages/item-receipts/item-receipts.page";
import ReceiptsPage from "./pages/receipts/receipts.page";
import SliceProcessPage from "./pages/slice-process/slice-process.page";
import LogsPage from "./pages/logs/logs.page";

type RawRouteType = {
    to: string;
    component: JSX.Element,
    roles?: RoleEnum[];
}

const MainRouter = () => {

    const routes: RawRouteType[] = [
        {
            to: 'dashboard',
            component: <DashboardPage />
        },
        {
            to: 'profile',
            component: <ProfilePage />,
        },
        {
            to: 'categorii',
            component: <JobsPage/>
        },
        {
            to: 'ingrediente',
            component: <IngredientsPage />
        },
        {
            to: 'produse',
            component: <ItemsPage />
        },
        {
            to: 'rute-de-livrare',
            component: <DeliveryRoutesPage />
        },
        {
            to: 'loturi',
            component: <BatchesPage />
        },
        {
            to: 'product-types',
            component: <ProductTypePage />
        },
        {
            to: 'product-category-types',
            component: <ProductCategoryTypePage />
        },
        {
            to: 'inventory',
            component: <InventoriesPage />
        },
        {
            to: 'item-receipts',
            component: <ItemReceiptsPage />
        },
        {
            to: 'receipts',
            component: <ReceiptsPage />
        },
        {
            to: 'slice-process',
            component: <SliceProcessPage />
        },
        {
            to: 'raw-products',
            component: <RawProductsPage />
        },
        {
            to: 'partneri',
            component: <PartnersPage />
        },
        {
            to: 'logs',
            component: <LogsPage />
        },
        {
            to: 'comenzi',
            component: <OrdersPage />,
            roles: [RoleEnum.Production, RoleEnum.Admin]
        },
        {
            to: 'utilizatori-interni',
            component: <InsideUsersPage />
        },
        {
            to: 'zona-productie',
            component: <ProductionZonePage />,
            roles: [RoleEnum.Production, RoleEnum.Admin]
        },
        {
            to: 'zona-livrare',
            component: <DeliveryZonePage />,
            roles: [RoleEnum.Order, RoleEnum.Admin]
        },
        {
            to: 'observatii-zilnice-rute',
            component: <DailyObservationsDeliveryRoutePage />,
            roles: [RoleEnum.Admin]
        },
        {
            to: 'profile/:userId',
            component: <ProfilePage />,
        }
    ]

    const user = getUser();
    let parsedRoutes: RouteObject[] = [];

    if (user) {
        parsedRoutes = routes
            .filter(route => {
                if (!route.roles) return true;
                return route.roles.some((requiredRole) => user.roles?.includes(requiredRole));
            })
            .map(route => ({
                element: route.component,
                path: route.to
            }));

        if (parsedRoutes.length > 0) {
            parsedRoutes = [
                ...parsedRoutes,
                {
                    path: '*',
                    element: <Navigate to={parsedRoutes[0].path ?? ''} />
                }
            ];
        }
            
    }

    let element = useRoutes(parsedRoutes);

    return element;

}

export default MainRouter;