import { observer } from "mobx-react-lite";
import ItemReceiptPanelStyled from "./item-receipt-panel.component.styled";
import { StateFieldType } from "../../types/form.types";
import { useEffect, useMemo, useState } from "react";
import { useStore } from "../../hooks/store.hook";
import { cloneDeep, isEmpty } from "lodash";
import { createItemReceiptCommand, editItemReceiptCommand, getDictionaryItems, getItemReceipt, getReceiptsList, getComputeReceiptData } from "../../utils/requests";
import { displayCustomToast } from "../../utils/display-custom-toast";
import { FormHelperText, Grid, LinearProgress } from "@mui/material";
import TextFieldComponent from "../text-field/text-field.component";
import { TableTypeEnum } from "../../enums/table-type.enum";
import TableComponent, { ActionItemPropsType, CustomRendererPropsType, TableActionsPropsType, TableDataPropsType } from "../table/table.component";
import { themeVariables } from "../../theme/variables";
import ButtonComponent from "../button/button.component";
import BasicSelect from "../dropdown-table-component/dropdown-table-component.component";
import { PanelType } from "../../enums/panel-type.enum";
import SelectItemReceiptRawProductsComponent from "./components/select-item-receipt-raw-products/select-item-receipt-raw-products.component";
import CloseIcon from '@mui/icons-material/Close';
import { HeaderTypeEnum } from "../../enums/header-type.enum";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { fixedNumberDecimals } from "../../utils/utils";
import { ItemReceiptStatusEnum } from "../../enums/item-receipt-status.enum";
import TooltipComponent from "../tooltip/tooltip.component";

export type ItemReceiptPanelPropsTypes = {
    itemReceiptId?: number,
    itemReceiptDetails?: any
}

export type StateType = {
    fields: {
        estimatedQuantity: StateFieldType<number | null>;
        totalQuantity: StateFieldType<any>;
        doneQuantity: StateFieldType<any>;
        item: StateFieldType<any>,
        receipt: StateFieldType<any>,
        status: StateFieldType<any>,
        itemReceiptRawProducts: StateFieldType<any[]>
    };
    shouldDisplayError: boolean;
}

const ItemReceiptPanel = observer(({
    itemReceiptDetails,
    itemReceiptId
}: ItemReceiptPanelPropsTypes) => {

    const [formGroup, setFormGroup] = useState<StateType>({
        fields: {
            totalQuantity: {
                value: 0,
                isValid: false,
                noValidation: false
            },
            estimatedQuantity: {
                value: 0,
                isValid: true,
                noValidation: true
            },
            doneQuantity: {
                value: 0,
                isValid: true,
                noValidation: true
            },
            item: {
                value: null,
                isValid: false,
                noValidation: false
            },
            receipt: {
                value: null,
                isValid: false,
                noValidation: false
            },
            status: {
                value: null,
                isValid: true,
                noValidation: true
            },
            itemReceiptRawProducts: {
                value: [],
                isValid: false,
                noValidation: false
            }
        },
        shouldDisplayError: false
    });

    const uiStore = useStore('uiStore');
    const [filtersTable, setFilters] = useState<any>({
        pageIndex: 0,
        pageSize: 5,
      //   selectFilterValue: null,
      //   secondSelectFilterValue: null,
      //   tabFilterValue: undefined,
        searchText: null
    });
    const [actionItemsClick, setActionItemsClick] = useState<any>();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isComputing, setIComputing] = useState<boolean>(false);

    const [items, setItems] = useState<any[]>([]);
    const [receipts, setReceipts] = useState<any[]>([]);

    const [receiptProducts, setReceiptProducts] = useState<any>();

    const updateState =  <T extends keyof any>(field: T, newValue: any) => {
        setFormGroup((state: any) => ({
            ...state,
            fields: {
                ...state.fields,
                [field]: {
                    ...state.fields[field],
                    isValid: isEmpty(newValue?.toString()) ? false : true,
                    value: newValue
                }
            }
        }))
    }

    const getBasicSelectOptions = async () => {
        try {
            setIsLoading(() => true);

            let items = await getDictionaryItems();

            setItems(() => items);

            setIsLoading(() => false);

        } catch (error: any) {

            displayCustomToast(error.content, true);
            setIsLoading(() => false);
        }
    }

    useEffect(
        () => {
            if(itemReceiptId !== undefined) return;

            getBasicSelectOptions();
        },
        []
    )

    const getReceipts = async (itemId: number) => {
        
        let receipts = await getReceiptsList({
            itemId
        });
        
        setReceipts(() => receipts);
    }

    useEffect(
        () => {
            if(itemReceiptId !== undefined) return;

            if(isEmpty(formGroup.fields.item.value)){
                updateState('receipt', null);
                setReceipts(() => []);

                return;
            };

            updateState('receipt', null);

            getReceipts(formGroup.fields.item.value.value);
        },
        [formGroup.fields.item.value]
    )

    const editReceiptData = async () => {
        await getBasicSelectOptions();

        let itemReceipt = await getItemReceipt({
            id: itemReceiptId
        });

        updateState('item', {
            label: itemReceipt.item.name,
            value: itemReceipt.item.id
        });

        updateState('receipt', {
            label: itemReceipt.receipt.name,
            value: itemReceipt.receipt.id,
            estimatedQuantityPercentage: itemReceipt.receipt.estimatedQuantityPercentage
        });

        updateState('totalQuantity', itemReceipt.totalQuantity);
        updateState('doneQuantity', itemReceipt.doneQuantity);

        updateState('status', {
            label: itemReceipt.status.name,
            value: itemReceipt.status.id
        });
        
        updateState('itemReceiptRawProducts', itemReceipt.itemReceiptRawProducts.map((r: any) => ({
            id: r.rawProduct.id,
            productType: {
                id: r.productType.id,
                name: r.productType.name
            },
            measurementUnit: {
                name: r.measurementUnit.name,
                id: r.measurementUnit.id,
            },
            batch: {
                name: r.batch.name,
                id: r.batch.id,
            },
            quantity: r.quantity,
            totalQuantity: r.totalQuantity,
            availableQuantity: r.availableQuantity,
            usedQuantity: r.usedQuantity
        })));

        
        await computeDataTable({
            label: itemReceipt.receipt.name,
            value: itemReceipt.receipt.id,
            estimatedQuantityPercentage: itemReceipt.receipt.estimatedQuantityPercentage
        }, 
        itemReceipt.totalQuantity.toString()
    );
        
    }

    useEffect(
        () => {
            if(itemReceiptId === undefined) return;

            editReceiptData()
        },
        []
    )

    const createAction = async () => {
        setIsSaving(() => true);
        if(!formGroup) return;

        const isNotValid = Object.values(formGroup.fields).filter((f: any) => f.noValidation === false).some((field: any) => !field.isValid);
        if (isNotValid) {
            setIsSaving(() => false);
            setFormGroup((prevState: any) => ({
                ...prevState,
                shouldDisplayError: true
            }))
            displayCustomToast("Datele nu sunt valide!", true);
            return;
        };

        let copyItemReceiptRawProducts = cloneDeep(formGroup.fields.itemReceiptRawProducts.value);

        copyItemReceiptRawProducts = copyItemReceiptRawProducts.map((v: any) => {
            if(v.quantity < 0)
                v.error = true
            else 
                v.error = false

            return {
                ...v
            }
        })

        setFormGroup((prevState: any) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                itemReceiptRawProducts: {
                    ...prevState.fields.itemReceiptRawProducts,
                    value: prevState.fields.itemReceiptRawProducts.value.map((v: any) => {
                        if(v.quantity < 0)
                            return {
                                ...v,
                                error: true
                            }
                        else 
                            return {
                                ...v,
                                error: false
                            }
                    })
                }
            }
        }))

        const isNotValidReceiptProducts = copyItemReceiptRawProducts.some((field: any) => field.error);
        
        if (isNotValidReceiptProducts) {
            setIsSaving(() => false);
            displayCustomToast("Datele nu sunt valide!", true);
            return;
        };

        // let copyReceiptProducts = cloneDeep(receipt.receiptProducts);

        // copyReceiptProducts = copyReceiptProducts.map((r: any) => {
        //     let sum = formGroup.fields.itemReceiptRawProducts.value
        //         .filter(f => f.productType.id === r.productType.id)
        //         .map(m => parseFloat(isEmpty(m.quantity.toString()) ? 0 : m.quantity))
        //         .reduce((prev: number, current: number) => prev + current, 0);

        //     if(sum !== r.value) {
                
        //         setReceipt((prev: any) => ({
        //             ...prev,
        //             receiptProducts: prev.receiptProducts.map((m: any) => ({
        //                 ...m,
        //                 id: m.receiptProductId,
        //                 isError: true
        //             }))
        //         }));

        //         return {
        //             ...r,
        //             isError: true
        //         }
        //     }
        //     else {

        //         setReceipt((prev: any) => ({
        //             ...prev,
        //             receiptProducts: prev.receiptProducts.map((m: any) => ({
        //                 ...m,
        //                 id: m.receiptProductId,
        //                 isError: false
        //             }))
        //         }));

        //         return {
        //             ...r,
        //             isError: false
        //         }
        //     }
        // });

        // const isNotValidReceiptProductsSums = copyReceiptProducts.some((field: any) => field.isError);
    

        // if (isNotValidReceiptProductsSums) {
        //     setIsSaving(() => false);
        //     displayCustomToast("Cantitatile nu sunt valide!", true);
        //     return;
        // };

        let body = {
            itemId: formGroup.fields.item.value?.value,
            receiptId: formGroup.fields.receipt.value?.value,
            totalQuantity: formGroup.fields.totalQuantity.value,
            estimatedQuantity: formGroup.fields.estimatedQuantity.value,
            itemReceiptRawProducts: formGroup.fields.itemReceiptRawProducts.value.map((v: any) => ({
                rawProductId: v.id,
                measurementUnitId: receiptProducts.find((f: any) => f.productType.id == v.productType.id)?.defaultMeasurementUnit.id,
                quantity: v.quantity
            }))
        }

        try {
            if(itemReceiptId != undefined){
                let bodyUpdate = {
                    id: itemReceiptId,
                    ...body
                }
                await editItemReceiptCommand(itemReceiptId, bodyUpdate)
            }
            else
                await createItemReceiptCommand(body);

            setIsSaving(() => false);
            displayCustomToast("Datele au fost salvate cu succes!");
            uiStore.dismissPanel({
                data: {
                    refreshData: true
                }
            });
        } catch (error: any) {
            displayCustomToast(error.content, true);
            setIsSaving(() => false);
        }
    }

    const computeDataTable = async (receipt: any, totalQuantity: any) => {
        const isNotValid = isEmpty(receipt) || isEmpty(totalQuantity)

        if (isNotValid) {
            setIComputing(() => false);
            setFormGroup((prevState: any) => ({
                ...prevState,
                shouldDisplayError: true
            }))
            displayCustomToast("Campurile cu Reteta si Cantitate totala trebuie completate pentru a calcula datele din tabel", true);
            return;
        };

        try {
            setIComputing(() => true);

            let receiptProducts = await getComputeReceiptData({
                receiptId: receipt.value, 
                totalQuantity: totalQuantity
            });

            setReceiptProducts(() => ([
                ...receiptProducts.map((m: any) => ({
                    ...m,
                    addedQuantity: 0,
                    id: m.receiptProductId,
                    isError: false
                }))
            ]));

            setIComputing(() => false);
        } catch (error) {
            setIComputing(() => false);
        }
    }

    useEffect(
        () => {
            if(isEmpty(formGroup.fields.receipt.value)) {
                updateState('estimatedQuantity', 0)
                setReceiptProducts(() => null);
                return;
            }

            updateState('estimatedQuantity', (parseFloat(formGroup.fields.receipt.value.estimatedQuantityPercentage) * parseFloat(isEmpty((formGroup.fields.totalQuantity.value).toString()) ? 0 : formGroup.fields.totalQuantity.value) / 100 ));
            setReceiptProducts(() => null)
        },
        [formGroup.fields.receipt.value, formGroup.fields.totalQuantity.value]
    )

    const renderExpandable = (row: any, tableActions: TableActionsPropsType) => {

        let tableData: any;

        if(isEmpty(formGroup.fields.itemReceiptRawProducts.value.filter(f => f.productType.id == row.productType.id))) 
            tableData = {
                data: [],
                headers: []
            }
            else
            tableData = {
                data: formGroup.fields.itemReceiptRawProducts.value.filter(f => f.productType.id == row.productType.id),
                headers: [
                    {
                        id: 'name',
                        label: 'Nume',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'left',
                        sortable: false
                    },
                    {
                        id: 'batch',
                        label: 'Lot',
                        headerType: HeaderTypeEnum.Numeric,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                        id: 'availableQuantity',
                        label: 'Cantitate disponibila',
                        headerType: HeaderTypeEnum.Numeric,
                        alignment: 'center',
                        sortable: false
                    }, 
                    {
                        id: 'quantity',
                        label: 'Cantitate',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'left',
                        sortable: false
                    },                         
                    {
                        id: 'measurementUnit',
                        label: 'Unitate de masura',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },               
                ],

            }

        const customRenderer: CustomRendererPropsType = {
                    measurementUnit: () => {
                        return (
                            <>
                                {row.defaultMeasurementUnit?.name}
                            </>
                        )
                    },
                    quantity: (row: any) => {
                        return (
                            <>
                                <TextFieldComponent
                                    label="Cantitate"
                                    variant="outlined"
                                    value={row.quantity}
                                    error={row.error && row.quantity < 0}
                                    onTextChange={value => {
                                        updateState('itemReceiptRawProducts', formGroup.fields.itemReceiptRawProducts.value.map((m: any) => ({
                                            ...m,
                                            quantity: m.id === row.id ? 
                                                m.availableQuantity - parseFloat(value) < 0 ?
                                                    m.availableQuantity
                                                    :
                                                    value
                                                : 
                                                m.quantity
                                        })));
                                    }}
                                    type="number"
                                    required
                                    style={{
                                        marginRight: 0
                                    }}
                                    width={120}
                                    disabled={formGroup.fields.status?.value?.value === ItemReceiptStatusEnum.Done}
                                />
                            </>
                        )
                    },
                    name: (row: any) => {
                        return (
                            <>
                                {
                                    row.productType.name
                                }
                            </>
                        )
                    },
                    batch: (row: any) => {
                        return (
                            <>
                                {
                                    row.batch?.name
                                }
                            </>
                        )
                    },
        }

        const actionItems: any[] = [
            {
                text: "Elimina produsul",
                icon: <CloseIcon color="error"  />,
                color: "red",
                fOnClick: (row: any) => {
                    setFormGroup((state: any) => ({
                        ...state,
                        fields: {
                            ...state.fields,
                            itemReceiptRawProducts: {
                                ...state.fields.itemReceiptRawProducts,
                                value: state.fields.itemReceiptRawProducts.value.filter((f: any) => f.id != row.id)
                            }
                        }
                    }))
                }
            }
        ]

        return (
            <TableComponent
                tableKey={TableTypeEnum.SelectedItems} 
                viewType="read-only"
                tableData={tableData}
                searchBarPlaceholder="Cauta dupa nume"
                withoutSelect
                tableState={filtersTable}
                setTableState={setFilters}
                withoutSearchBar
                withoutDenseSwitch
                denseByDefault
                customRenderer={customRenderer}
                actionItems={formGroup.fields.status?.value?.value === ItemReceiptStatusEnum.Done ? undefined : actionItems}
                actionItemsClick={setActionItemsClick}
                noRequest
            />
        )
    }

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!receiptProducts) return {
                data: [],
                headers: []
            }

            return {
                data: receiptProducts,
                headers: [
                    {
                         id: 'name',
                         label: 'Nume',
                         headerType: HeaderTypeEnum.String,
                         alignment: 'left',
                         sortable: false
                     },
                    {
                         id: 'productCategoryType',
                         label: 'Categorie produs',
                         headerType: HeaderTypeEnum.String,
                         alignment: 'left',
                         sortable: false
                     },
                     
                    {
                        id: 'requiredQuantity',
                        label: 'Cantitate necesara',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'left',
                        sortable: false
                    },
                    {
                        id: 'addedQuantity',
                        label: 'Cantitate adaugata',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'left',
                        sortable: false
                    },
                    {
                        id: 'defaultMeasurementUnit',
                        label: 'Unitate de masura',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                 ],
                 totalElements: receiptProducts.length
            }
        },
        [receiptProducts]
    )

    const customRenderer: CustomRendererPropsType = useMemo(
        () => {
            
            return {
                name: (row: any) => {
                    return (
                        <>
                            {row.productType.name}
                        </>
                    )
                },
                productCategoryType: (row: any) => {
                    return (
                        <>
                            {row.productCategoryType.name}
                        </>
                    )
                },
                defaultMeasurementUnit: (row: any) => {
                    return (
                        <>
                            {row.defaultMeasurementUnit?.name}
                        </>
                    )
                },
                addedQuantity: (row: any) => {

                    let sum = formGroup.fields.itemReceiptRawProducts.value
                        .filter(f => f.productType.id === row.productType.id)
                        .map(m => parseFloat(isEmpty(m.quantity.toString()) ? 0 : m.quantity))
                        .reduce((prev: number, current: number) => prev + current, 0);
                    
                    return (
                        <div
                            style={{
                                color: sum != row.requiredQuantity ? themeVariables.colors.error : themeVariables.colors.success
                            }}
                        >
                            {sum}
                        </div>
                    )
                },
                requiredQuantity: (row: any) => {
                    return (
                        <div>
                            {row.requiredQuantity.toFixed(fixedNumberDecimals)}
                        </div>
                    )
                }
            }
        },
        [formGroup.fields.itemReceiptRawProducts]
    )

    const actionItems: ActionItemPropsType[] = [
        {
            text: "Adauga Stoc",
            icon: <AddBoxOutlinedIcon/>,
            color: "black",
            fOnClick: (row: any) => {
                let copyItemReceiptRawProducts = cloneDeep(formGroup.fields.itemReceiptRawProducts.value);

                uiStore.openPanel({
                    key: PanelType.AddProductOrder,
                    title: "Selecteaza produsele",
                    panelWidth: '800px',
                    component: <SelectItemReceiptRawProductsComponent
                        productTypeId={row.productType.id}
                        itemReceiptId={itemReceiptId}
                        selectedItems={copyItemReceiptRawProducts.filter(f => f.productType.id == row.productType.id)}
                        setSelectedItems={(value) => updateState('itemReceiptRawProducts', [
                            ...copyItemReceiptRawProducts.filter(f => f.productType.id !== row.productType.id),
                            ...value.map((m: any) => ({
                                ...m,
                                measurementUnit: formGroup.fields.itemReceiptRawProducts.value?.find(f => f.id === m.id)?.measurementUnit ?? null,
                                quantity: formGroup.fields.itemReceiptRawProducts.value?.find(f => f.id === m.id)?.quantity ?? 0,
                                error: false
                            }))
                        ])}
                    />,
                    onDismiss: (data) => {
                        setFilters((prevFilters: any) => ({
                            ...prevFilters,
                            pageIndex: 0
                        }))
                        if (!data) return;
                    }
                })
            }
        },
    ]

    return (
        <ItemReceiptPanelStyled>
            {
                isLoading ? (
                    <LinearProgress sx={{ borderRadius: "10px" }} />
                )
                :
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <BasicSelect
                            placeholder="Produs *"
                            options={items?.map((m: any) => ({
                                label: m?.name,
                                value: m?.id
                            }))}
                            selectFilterValue={formGroup.fields.item?.value}
                            onValueChange={e => updateState('item', e)}
                            loading={isLoading}
                            error={formGroup?.shouldDisplayError && !formGroup.fields.item?.isValid}
                            customStyle={{
                                marginRight: "0"
                            }}
                            disabled={itemReceiptId !== undefined}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <BasicSelect
                            placeholder="Reteta *"
                            options={receipts?.map((m: any) => ({
                                label: m?.name,
                                value: m?.id,
                                estimatedQuantityPercentage: m.estimatedQuantityPercentage
                            }))}
                            selectFilterValue={formGroup.fields.receipt?.value}
                            onValueChange={e => updateState('receipt', e)}
                            loading={isLoading}
                            error={formGroup?.shouldDisplayError && !formGroup.fields.receipt?.isValid}
                            customStyle={{
                                marginRight: "0"
                            }}
                            disabled={itemReceiptId !== undefined}
                        />
                    </Grid>

                    {
                        itemReceiptId ?
                            <Grid item xs={12}>
                                <BasicSelect
                                    placeholder="Status *"
                                    options={[
                                        {
                                            label: formGroup.fields.status?.value?.label,
                                            value: formGroup.fields.status?.value?.value
                                        }
                                    ]}
                                    selectFilterValue={formGroup.fields.status?.value}
                                    onValueChange={e => updateState('status', e)}
                                    loading={isLoading}
                                    error={formGroup?.shouldDisplayError && !formGroup.fields.status?.isValid}
                                    customStyle={{
                                        marginRight: "0"
                                    }}
                                    disabled={itemReceiptId !== undefined}
                                />
                            </Grid>
                            :
                            <></>
                    }

                    <Grid item xs={12}>
                        <TextFieldComponent
                            label="Cantitate totala (kg)"
                            variant="outlined"
                            fullWidth={true}
                            value={formGroup.fields.totalQuantity?.value}
                            error={formGroup?.shouldDisplayError && !formGroup.fields.totalQuantity?.isValid}
                            onTextChange={(value: any) => updateState('totalQuantity', value)}
                            type="number"
                            required
                            disabled={formGroup.fields.status?.value?.value === ItemReceiptStatusEnum.Done}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextFieldComponent
                            label="Cantitate obtinuta estimativ (kg)"
                            variant="outlined"
                            fullWidth={true}
                            value={formGroup.fields.estimatedQuantity?.value}
                            error={formGroup?.shouldDisplayError && !formGroup.fields.estimatedQuantity?.isValid}
                            onTextChange={(value: any) => updateState('estimatedQuantity', value)}
                            type="number"
                            required
                            disabled
                        />
                    </Grid>

                    {
                        itemReceiptId ?
                            <Grid item xs={12}>
                                <TextFieldComponent
                                    label="Cantitate finalizata (kg)"
                                    variant="outlined"
                                    fullWidth={true}
                                    value={formGroup.fields.doneQuantity?.value}
                                    error={formGroup?.shouldDisplayError && !formGroup.fields.doneQuantity?.isValid}
                                    onTextChange={(value: any) => updateState('doneQuantity', value)}
                                    type="number"
                                    required
                                    disabled
                                />
                            </Grid>
                            :
                            <></>
                    }

                    <Grid item xs={12}>
                        <ButtonComponent 
                            onClick={() => computeDataTable(formGroup.fields.receipt.value, formGroup.fields.totalQuantity.value.toString())} 
                            isLoading={isComputing}
                            disabled={formGroup.fields.status?.value?.value === ItemReceiptStatusEnum.Done}
                        >
                            Calculeaza
                        </ButtonComponent>
                    </Grid>

                    <Grid item xs={12}>
                        {
                            !isEmpty(receiptProducts) ?
                                <TableComponent
                                    tableKey={TableTypeEnum.SelectedItems} 
                                    viewType="panel"
                                    tableData={tableData}
                                    searchBarPlaceholder="Cauta dupa nume"
                                    withoutSelect
                                    tableState={filtersTable}
                                    setTableState={setFilters}
                                    withoutSearchBar
                                    withoutDenseSwitch
                                    denseByDefault
                                    customRenderer={customRenderer}
                                    actionItems={formGroup.fields.status?.value?.value === ItemReceiptStatusEnum.Done ? undefined : actionItems}
                                    expandableRenderer={renderExpandable}
                                    actionItemsClick={setActionItemsClick}
                                    noRequest
                                />
                                :
                                <FormHelperText 
                                    sx={{ 
                                        fontSize: `${themeVariables.fontSizes.normal}`,
                                        marginLeft: 0,
                                        color: `${isEmpty(receiptProducts) && formGroup.shouldDisplayError ? '#cb5765' : 'rgba(0, 0, 0, 0.6)'}`
                                    }}
                                >
                                    Nu ati calculat valorile din tabel
                                </FormHelperText>
                        }
                    </Grid>

                    <Grid item xs={12}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginBottom: "1rem"
                            }}
                        >
                            <ButtonComponent 
                                onClick={createAction} 
                                isLoading={isSaving}
                                sx={{
                                    width: "5rem"
                                }}
                                disabled={formGroup.fields.status?.value?.value === ItemReceiptStatusEnum.Done}
                            >
                                Salveaza
                            </ButtonComponent>
                        </div>
                    </Grid>
                </Grid>

            }
        </ItemReceiptPanelStyled>
    )
})

export default ItemReceiptPanel;