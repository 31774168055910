import { Autocomplete, AutocompleteRenderOptionState } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { getRequest } from "../../utils/http-requests";
import TextFieldComponent from "../text-field/text-field.component";
import AsyncSelectComponentStyled from "./async-select.component.styled";
import { displayCustomToast } from "../../utils/display-custom-toast";
import { roundNumber } from "../../utils/utils";

export type AsyncSelectComponentPropsType = {
    options?: any[],
    placeholder?: string;
    url?: string | undefined;
    value?: AsyncSelectOptionType | null | undefined;
    label?: string;
    error?: boolean;
    renderOption?: (props: React.HTMLAttributes<HTMLLIElement>, option: AsyncSelectOptionType, state: AutocompleteRenderOptionState) => React.ReactNode,
    getOptionLabel?: (option: any) => any,
    onOptionChange?: (newValue: any) => void,
    isOptionEqualToValue?: ((option: AsyncSelectOptionType, value: AsyncSelectOptionType) => boolean) | undefined,
    getOptions?: (options: any) => void,
    isAsync?: boolean,
    notFoundText?: string,
    propertyName?: string,
    disabled?: boolean,
    filter?: any
}

export type AsyncSelectOptionType = {
    id?: string;
    [prop: string]: any;
}

const AsyncSelectComponent = ({
    placeholder,
    url,
    value,
    label,
    error,
    options,
    renderOption,
    getOptionLabel,
    onOptionChange = () => { },
    isOptionEqualToValue,
    isAsync,
    notFoundText,
    propertyName = 'data',
    disabled,
    filter
}: AsyncSelectComponentPropsType) => {

    const [optionsFetched, setOptionsFetched] = useState<AsyncSelectOptionType[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [fetchAgain, setFetchAgain] = useState<boolean>(true);
    const [filters, setFilters] = useState<any>({
        pageIndex: 0,
        pageSize: 10,
        search: null
    });

    const [dataTotalNumber, setDataTotalNumber] = useState<number | null>(null);

    useEffect(
        () => {
            async function getOption(){
                if (!url) return;
                setIsLoading(true)
                try{
                    if (isAsync) {
                        const response = await getRequest({
                            url: url,
                            queryParams: {
                                pageIndex: filters.pageIndex + 1,
                                pageSize: filters.pageSize,
                                searchText: filters.search,
                                ...filter
                            }
                        })
    
                        if (filters.pageIndex === 0) {
                            setOptionsFetched(response.data[propertyName] || response.data);
                            setDataTotalNumber(() => response.data["totalNumber"])
                        } else {
                            const newData = response.data[propertyName] || response.data;
                            setOptionsFetched(prevState => [...prevState, ...newData]);  
                        }

                    } else {
                        const response = await getRequest({url: url})
                        setOptionsFetched(response.data);
                    }

                    
                    setFetchAgain(() => false)
                } catch(e: any) {
                    displayCustomToast(e.content, true)
                }
                setIsLoading(false)
            }

            if(!fetchAgain) return;
            getOption();
        },
        [url, fetchAgain, filters.pageIndex, filter]
    )

    const debounceFactory = useCallback(
        () => {
            let timeoutId: any = 0;
            return (delayText: number, onChangeText: (newValue: any) => void, text : string) => {
                if (timeoutId > 0) {
                    clearTimeout(timeoutId);
                    timeoutId = 0;
                }
        
                timeoutId = setTimeout(() => {
                    onChangeText((state: any) => ({
                        ...state,
                        search: text,
                        pageIndex: 0
                    }));
                    setFetchAgain(() => true)
                }, delayText);
            }
        },
        []
    )

    const debounce = useCallback(
        debounceFactory(),
        []
    )

    const changeFilterSearchInput = 
        (value: string) => {
            setIsLoading(true)
            setFilters((prevState: any) => ({ ...prevState, search: value }));
            debounce(500, setFilters, value)   
        }
    
    useEffect(
        () => {
            if(!options) return;

            const myInterval = setInterval(myTimer, 50);
            let c = 0 
            function myTimer() {
                if(c === 1){
                    clearInterval(myInterval);
                    if(!options) return;
                    setOptionsFetched(options)
                    setIsLoading(false)
                }
                c = c + 1
            }
        },
        [options]
    )

    const handleScroll = (event: React.SyntheticEvent) => {
        const listboxNode = event.currentTarget;

        if( dataTotalNumber && (dataTotalNumber === optionsFetched.length)) return;
        
        if ((roundNumber(listboxNode.scrollTop) ?? 0) + listboxNode.clientHeight >= listboxNode.scrollHeight - 1) {
          setFilters((prevState: any) => ({ 
              ...prevState, 
              pageIndex: prevState.pageIndex + 1
          }));
          setFetchAgain(() => true)
        } 
    };

    const getFirstOptions = () => {
        setFilters({ pageIndex: 0, pageSize: 10, search: '' });
        setFetchAgain(() => true)
    };

    /** define the return logic bellow */
    return (
        <>
            {
                <AsyncSelectComponentStyled>
                    {
                        isAsync ?
                            <Autocomplete
                                options={url ? optionsFetched.map(m => ({
                                    label: m?.name,
                                    value: m?.id,
                                    ...m
                                })) : options ?? []}
                                renderOption={renderOption}
                                loading={isLoading}
                                getOptionLabel={getOptionLabel}
                                value={value ? value : null}
                                onChange={(_, newOption) => onOptionChange(newOption)}
                                id="combo-box-demo"
                                disablePortal
                                renderInput={(params) => <TextFieldComponent 
                                    {...params} 
                                    error={error} 
                                    helperText={error ? (notFoundText ?? 'Optiunea nu a fost gasita') : ''}
                                    placeholder={placeholder} 
                                    label={label}
                                    onTextChange={(value) => changeFilterSearchInput(value)}
                                />}
                                isOptionEqualToValue={isOptionEqualToValue}
                                ListboxProps={{ 
                                    style: { maxHeight: 250 },
                                    onScroll: handleScroll, 
                                    role: 'list-box' 
                                }} // used for pagination when scrolling
                                freeSolo // handle the "The value provided to Autocomplete is invalid" console warnings
                                onOpen={getFirstOptions} // get first items when select is open
                                onInputChange={(event, value, reason) => reason === 'clear' && getFirstOptions()} // get first items when clear button is clicked
                                disabled={disabled}
                            />
                            :
                            <Autocomplete
                                options={url ? optionsFetched : options ? options : []}
                                renderOption={renderOption}
                                getOptionLabel={getOptionLabel}
                                value={ value?.id ? {id: value?.id, ...value} : value }
                                onChange={(_, newOption) => onOptionChange(newOption)}
                                id="combo-box-demo"
                                disablePortal
                                defaultChecked={true}
                                renderInput={(params) => <TextFieldComponent 
                                    {...params} 
                                    error={error} 
                                    helperText={error ? `The option was not found` : ''}
                                    placeholder={placeholder} 
                                    label={label} 
                                />}
                                isOptionEqualToValue={isOptionEqualToValue}
                                disabled={disabled}
                            />

                    }
                </AsyncSelectComponentStyled>
            }
        </>
    )

}

export default AsyncSelectComponent;