import { useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../hooks/store.hook";
import SelectOrderProductsStyled from "./select-order-products.component.styled";
import { displayCustomToast } from "../../../../utils/display-custom-toast";
import { getInventories } from "../../../../utils/requests";
import TableComponent, { CustomRendererPropsType, TableDataPropsType } from "../../../table/table.component";
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import { HeaderTypeEnum } from "../../../../enums/header-type.enum";
import ButtonComponent from "../../../button/button.component";
import TooltipComponent from "../../../tooltip/tooltip.component";
import { isEmpty } from "lodash";

export type SelectOrderProductsComponentPropsType = {
    selectedItems?: any[];
    setSelectedItems?: (value: any) => void;
    itemId: number,
    orderItemId?: number,
    orderStatusId?: number
}

const SelectOrderProductsComponent = ({
    selectedItems,
    setSelectedItems,
    itemId,
    orderItemId,
    orderStatusId
}: SelectOrderProductsComponentPropsType) => {

    const uiStore = useStore('uiStore');

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>();
    const [refreshData, setRefreshData] = useState<boolean>(true);

    const [filtersTable, setFilters] = useState<any>({
        pageIndex: 0,
        pageSize: 5,
      //   selectFilterValue: null,
      //   secondSelectFilterValue: null,
        tabFilterValue: undefined,
        searchText: null
    });

    useEffect(
        () => {
            async function getData() {
                try {
                    setIsLoading(() => true)

                    let fetchData = await getInventories({
                        pageIndex: filtersTable.pageIndex + 1,
                        pageSize: filtersTable.pageSize,
                        searchText: filtersTable.searchText,
                        itemId: itemId,
                        orderItemId: orderItemId,
                        orderStatusId: orderStatusId
                    });
                    setData(() => fetchData)
                    setIsLoading(() => false)
                } catch (error: any) {
                    displayCustomToast(error.content, true);
                    setIsLoading(() => false)
                }
            }
            
            if(!refreshData) return;

            getData();
            setRefreshData(() => false);
            
        },
        [filtersTable, refreshData]
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!data) return {
                data: [],
                headers: []
            }

            return {
                data: data.data,
                headers: [
                   {
                        id: 'item',
                        label: 'Nume',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'left',
                        sortable: false
                    },
                    {
                        id: 'availableQuantity',
                        label: 'Cantitate disponibila',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'measurementUnit',
                        label: 'Unitate de masura',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                ],
                totalElements: data.totalNumber
            }
        },
        [data]
    )

    const customRenderer: CustomRendererPropsType = useMemo(
        () => {
            return {
                description: (row: any) => {
                    return (
                       <>
                       {
                            isEmpty(row.description) ?
                                "-"
                                :
                                <TooltipComponent title={row.description ?? ''} titleLimit={20} />
                        }
                       </>
                    )
                },
                item: (row: any) => {
                    return (
                        <>
                            {row.item.name}
                        </>
                    )
                },
                measurementUnit: (row: any) => {
                    return (
                        <>
                            {row.measurementUnit.name}
                        </>
                    )
                },
            }
        },
        []
    )

    const onSelectProducts = () => {
        uiStore.dismissPanel({
            data: {
                dismissed: true
            }
        });
    }

    return (
        <SelectOrderProductsStyled>
            <TableComponent
                tableKey={TableTypeEnum.AllItems} 
                viewType="panel"
                tableData={tableData}
                searchBarPlaceholder="Cauta dupa nume"
                isLoading={isLoading}
                tableState={filtersTable}
                setTableState={setFilters}
                setRefreshData={setRefreshData}
                noSelectAllCheckbox
                preselectedItems={selectedItems}
                onSelectRows={(value) =>  setSelectedItems && setSelectedItems(value.selectedItems)}
                customRenderer={customRenderer}
            />

            <div className="button-container">
                <ButtonComponent 
                    onClick={onSelectProducts}
                >
                    Selecteaza
                </ButtonComponent>
            </div>
        </SelectOrderProductsStyled>
    )

}

export default SelectOrderProductsComponent;