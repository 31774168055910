import { observer } from "mobx-react-lite";
import ProductCategoryTypePanelStyled from "./product-category-type-panel.component.styled";
import { StateFieldType } from "../../types/form.types";
import { useStore } from "../../hooks/store.hook";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { displayCustomToast } from "../../utils/display-custom-toast";
import { createProductCategoryTypeCommand, editProductCategoryTypeCommand, getProductCategoryTypes } from "../../utils/requests";
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Grid } from "@mui/material";
import TextFieldComponent from "../text-field/text-field.component";
import { themeVariables } from "../../theme/variables";
import ButtonComponent from "../button/button.component";

export type ProductCategoryTypePanelPropsTypes = {
    productCategoryTypeId?: number,
    productCategoryTypeDetails?: any
}

export type StateType = {
    fields: {
        name: StateFieldType<string>;
        isActive: StateFieldType<any>;
    };
    shouldDisplayError: boolean;
}

const ProductCategoryTypePanel = observer(({
    productCategoryTypeDetails,
    productCategoryTypeId
}: ProductCategoryTypePanelPropsTypes) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [formGroup, setFormGroup] = useState<StateType>({
        fields: {
            name: {
                value: "",
                isValid: false,
                noValidation: false
            },
            isActive: {
                value: true,
                isValid: true,
                noValidation: true
            },
        },
        shouldDisplayError: false
    })
    const uiStore = useStore('uiStore');

    const updateState =  <T extends keyof any>(field: T, newValue: any) => {
        setFormGroup((state: any) => ({
            ...state,
            fields: {
                ...state.fields,
                [field]: {
                    ...state.fields[field],
                    isValid: isEmpty(newValue?.toString()) ? false : true,
                    value: newValue
                }
            }
        }))
    }

    // Editare
    useEffect(
        () => {
            if(productCategoryTypeId === undefined) return;
            
            updateState('name', productCategoryTypeDetails.name);
            updateState('isActive', productCategoryTypeDetails.isActive);
            
        },
        [productCategoryTypeDetails, productCategoryTypeId]
    )

    const createAction = async () => {
        setIsSaving(() => true);
        if(!formGroup) return;

        const isNotValid = Object.values(formGroup.fields).filter((f: any) => f.noValidation === false).some((field: any) => !field.isValid);
        if (isNotValid) {
            setIsSaving(() => false);
            setFormGroup((prevState: any) => ({
                ...prevState,
                shouldDisplayError: true
            }))
            displayCustomToast("Datele nu sunt valide!", true);
            return;
        };

        let body = {
            name: formGroup.fields.name.value,
        }

        try {
            if(productCategoryTypeId != undefined){
                let bodyUpdate = {
                    id: productCategoryTypeId,
                    name: formGroup.fields.name.value,
                    isActive: formGroup.fields.isActive.value
                }
                await editProductCategoryTypeCommand(productCategoryTypeId, bodyUpdate)
            }
            else
                await createProductCategoryTypeCommand(body);

            setIsSaving(() => false);
            displayCustomToast("Datele au fost salvate cu succes!");
            uiStore.dismissPanel({
                data: {
                    refreshData: true
                }
            });
        } catch (error: any) {
            displayCustomToast(error.content, true);
            setIsSaving(() => false);
        }
    } 

    return (
        <ProductCategoryTypePanelStyled>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextFieldComponent
                        label="Nume"
                        variant="outlined"
                        fullWidth={true}
                        value={formGroup.fields.name?.value}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.name?.isValid}
                        onTextChange={(value: any) => updateState('name', value)}
                        style={{}}
                        required
                    />
                </Grid>

                {
                    !(productCategoryTypeId === undefined) ?
                        <Grid item xs={12}>
                            <FormControl
                                error={!formGroup.fields.isActive?.value}
                            >
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={formGroup.fields.isActive?.value}
                                        onChange={e => updateState('isActive', e.target.checked)}
                                    />}
                                    label="Activ"
                                />
                                {
                                    !formGroup.fields.isActive?.value ?
                                        <FormHelperText 
                                            sx={{ 
                                                fontSize: `${themeVariables.fontSizes.normal}`,
                                                marginLeft: 0
                                            }}>
                                                Tipul de categorie de produs o sa devina inactiv
                                            </FormHelperText>
                                        :
                                        <></>
                                }
                            </FormControl>
                        </Grid>
                        :
                        <></>
                    }
                <Grid item xs={12}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end"
                        }}
                    >
                        <ButtonComponent 
                            onClick={createAction} 
                            isLoading={isSaving}
                            sx={{
                                width: "5rem"
                            }}
                        >
                            Salveaza
                        </ButtonComponent>
                    </div>
                </Grid>

            </Grid>

        </ProductCategoryTypePanelStyled>
    )
});

export default ProductCategoryTypePanel;