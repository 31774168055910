import { observer } from "mobx-react-lite";
import LogsPageStyled from "./logs.page.styled";
import { useStore } from "../../../../hooks/store.hook";
import { useEffect, useMemo, useState } from "react";
import { getLogs } from "../../../../utils/requests";
import { displayCustomToast } from "../../../../utils/display-custom-toast";
import PageTemplateComponent from "../../../../components/page-template/page-template.component";
import TableComponent, { CustomRendererPropsType, TableDataPropsType } from "../../../../components/table/table.component";
import { HeaderTypeEnum } from "../../../../enums/header-type.enum";
import { isEmpty } from "lodash";
import TooltipComponent from "../../../../components/tooltip/tooltip.component";
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const LogsPage = observer(() => {

    const uiStore = useStore('uiStore');
    const [actionItemsClick, setActionItemsClick] = useState<any>();
    const [refreshData, setRefreshData] = useState<boolean>(true);
    const [data, setData] = useState<any>();
    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [filtersTable, setFilters] = useState<any>({
          pageIndex: 0,
          pageSize: 5,
        //   selectFilterValue: null,
        //   secondSelectFilterValue: null,
        //   tabFilterValue: undefined,
          searchText: null
    });

    useEffect(
        () => {
            async function getData() {
                try {
                    setDataLoading(() => true)
                    let data = await getLogs({
                        pageIndex: filtersTable.pageIndex + 1,
                        pageSize: filtersTable.pageSize,
                        searchText: filtersTable.searchText,
                    });
                    setData(() => data)
                    setDataLoading(() => false)
                } catch (error: any) {
                    displayCustomToast(error.content, true);
                    setDataLoading(() => false)
                }
            }
            
            if(!refreshData) return;

            getData();
            setRefreshData(() => false);
            
        },
        [filtersTable, refreshData]
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!data) return {
                data: [],
                headers: []
            }

            return {
                data: data.data,
                headers: [
                   {
                        id: 'description',
                        label: 'Descriere',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'left',
                        sortable: false,
                        width: "30rem"
                    },
                   {
                        id: 'order',
                        label: 'Comanda',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false,
                    },
                   {
                        id: 'partner',
                        label: 'Partener',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false,
                    },
                   {
                        id: 'rawProduct',
                        label: 'Proces de transare',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false,
                    },
                   {
                        id: 'itemReceipt',
                        label: 'Proces de productie',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false,
                    },
                   {
                        id: 'user',
                        label: 'Admin',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false,
                    },
                ],
                totalElements: data.totalNumber
            }
        },
        [data]
    )

    const customRenderer: CustomRendererPropsType = {
        description: (row: any) => {
            return (
                <>
                {
                    isEmpty(row.description) ?
                        "-"
                        :
                        <TooltipComponent title={row.description ?? ''} titleLimit={200} />
                }
                </>
            )
        },
        order: (row: any) => {
            return (
                <>
                {
                    row.order.id !== null ?
                        <CheckIcon color="success"  />
                        :
                        <CloseIcon color="error"  />
                }
                </>
            )
        },
        partner: (row: any) => {
            return (
                <>
                {
                    row.partner.id !== null ?
                        <CheckIcon color="success"  />
                        :
                        <CloseIcon color="error"  />
                }
                </>
            )
        },
        rawProduct: (row: any) => {
            return (
                <>
                {
                    row.rawProduct.id !== null ?
                        <CheckIcon color="success"  />
                        :
                        <CloseIcon color="error"  />
                }
                </>
            )
        },
        itemReceipt: (row: any) => {
            return (
                <>
                {
                    row.itemReceipt.id !== null ?
                        <CheckIcon color="success"  />
                        :
                        <CloseIcon color="error"  />
                }
                </>
            )
        },
        user: (row: any) => {
            return (
                <>
                    {row.user.name}
                </>
            )
        },
    }

    return (
        <LogsPageStyled>
            <PageTemplateComponent
                title="Loguri"
            >
                <TableComponent
                    tableKey={TableTypeEnum.Logs} 
                    viewType="normal"
                    tableData={tableData}
                    searchBarPlaceholder="Cauta dupa descriere"
                    withoutSelect
                    isLoading={dataLoading}
                    tableState={filtersTable}
                    setTableState={setFilters}
                    actionItemsClick={setActionItemsClick}
                    setRefreshData={setRefreshData}
                    customRenderer={customRenderer}
                /> 
            </PageTemplateComponent>
        </LogsPageStyled>
    )
});

export default LogsPage;