import { observer } from "mobx-react-lite";
import RawProductsPanelStyled from "./raw-products-panel.component.styled";
import { StateFieldType } from "../../types/form.types";
import { useStore } from "../../hooks/store.hook";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Box, Checkbox, FormControl, FormControlLabel, Grid } from "@mui/material";
import BasicSelect from "../dropdown-table-component/dropdown-table-component.component";
import { createRawProductCommand, editRawProductCommand, getBatchTypes, getDictionaryBatches, getDictionaryProductTypes, getDictionaryRawProductStatus, getDictionaryRawProducts } from "../../utils/requests";
import { displayCustomToast } from "../../utils/display-custom-toast";
import TextFieldComponent from "../text-field/text-field.component";
import ButtonComponent from "../button/button.component";
import AsyncSelectComponent from "../async-select/async-select.component";

export type RawProductsPanelPropsTypes = {
    rawProductId?: number,
    rawProductDetails?: any
}

export type StateType = {
    fields: {
        description: StateFieldType<string>;
        batch: StateFieldType<any>;
        canSlice: StateFieldType<boolean>;
        initialQuantity: StateFieldType<number>;
        totalQuantity: StateFieldType<number>;
        usedQuantity: StateFieldType<number>;
        productType: StateFieldType<any>;
        status: StateFieldType<any>;
        parentRawProduct: StateFieldType<any>;
    };
    shouldDisplayError: boolean;
}

const RawProductsPanel = observer(({
    rawProductDetails,
    rawProductId
}: RawProductsPanelPropsTypes) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [formGroup, setFormGroup] = useState<StateType>({
        fields: {
            description: {
                value: "",
                isValid: true,
                noValidation: true
            },
            batch: {
                value: null,
                isValid: false,
                noValidation: false
            },
            canSlice: {
                value: false,
                isValid: true,
                noValidation: true 
            },
            initialQuantity: {
                value: 0,
                isValid: true,
                noValidation: true
            },
            totalQuantity: {
                value: 0,
                isValid: false,
                noValidation: false
            },
            usedQuantity: {
                value: 0,
                isValid: true,
                noValidation: true
            },
            productType: {
                value: null,
                isValid: false,
                noValidation: false
            },
            status: {
                value: null,
                isValid: true,
                noValidation: true
            },
            parentRawProduct: {
                value: null,
                isValid: true,
                noValidation: true
            },

        },
        shouldDisplayError: false
    })
    const uiStore = useStore('uiStore');

    const updateState =  <T extends keyof any>(field: T, newValue: any) => {
        setFormGroup((state: any) => ({
            ...state,
            fields: {
                ...state.fields,
                [field]: {
                    ...state.fields[field],
                    isValid: isEmpty(newValue?.toString()) ? false : true,
                    value: newValue
                }
            }
        }))
    }

    const [rawProducts, setRawProducts] = useState<any>();
    const [rawProductStatus, setRawProductStatus] = useState<any>();

    const getBasicSelectOptions = async () => {
        try {
            setIsLoading(() => true);

            let rawProducts = await getDictionaryRawProducts();
            let rawProductStatus = await getDictionaryRawProductStatus();

            setRawProducts(() => rawProducts);
            setRawProductStatus(() => rawProductStatus);

            setIsLoading(() => false);

        } catch (error: any) {

            displayCustomToast(error.content, true);
            setIsLoading(() => false);
        }
    }

    useEffect(
        () => {
            getBasicSelectOptions();
        },
        []
    )

    // Editare
    useEffect(
        () => {
            if(rawProductId === undefined) return;
            
            updateState('description', rawProductDetails.description);
            updateState('batch', {
                label: rawProductDetails.batch.name,
                value: rawProductDetails.batch.id
            });
            updateState('productType', {
                label: rawProductDetails.productType.name,
                value: rawProductDetails.productType.id,
                ...rawProductDetails.productType
            });
            updateState('status', {
                label: rawProductDetails.status.name,
                value: rawProductDetails.status.id
            });
            updateState('parentRawProduct', rawProductDetails.parentRawProduct);
            updateState('canSlice', rawProductDetails.canSlice);
            updateState('initialQuantity', rawProductDetails.initialQuantity);
            updateState('totalQuantity', rawProductDetails.totalQuantity);
            updateState('usedQuantity', rawProductDetails.usedQuantity);
            
        },
        [rawProductDetails, rawProductId]
    )

    const createAction = async () => {
        setIsSaving(() => true);
        if(!formGroup) return;

        const isNotValid = Object.values(formGroup.fields).filter((f: any) => f.noValidation === false).some((field: any) => !field.isValid);
        if (isNotValid) {
            setIsSaving(() => false);
            setFormGroup((prevState: any) => ({
                ...prevState,
                shouldDisplayError: true
            }))
            displayCustomToast("Datele nu sunt valide!", true);
            return;
        };

        if(formGroup.fields.totalQuantity.value < formGroup.fields.usedQuantity.value) {

            displayCustomToast("Cantitatea totala este mai mica decat cea utilizata", true);
            return;
        }

        let body = {
            description: formGroup.fields.description.value,
            batchId: formGroup.fields.batch.value.value,
            productTypeId: formGroup.fields.productType.value.value,
            totalQuantity: formGroup.fields.totalQuantity.value,
            canSlice: formGroup.fields.canSlice.value,
        }

        try {
            if(rawProductId != undefined){
                let bodyUpdate = {
                    id: rawProductId,
                    ...body
                }

                await editRawProductCommand(rawProductId, bodyUpdate)
            }
            else
                await createRawProductCommand(body);

            setIsSaving(() => false);
            displayCustomToast("Datele au fost salvate cu succes!");
            uiStore.dismissPanel({
                data: {
                    refreshData: true
                }
            });
        } catch (error: any) {
            displayCustomToast(error.content, true);
            setIsSaving(() => false);
        }
    }

    return (
        <RawProductsPanelStyled>
            <Grid container spacing={2}>
                {
                    rawProductId ?
                        <Grid item xs={12}>
                            <BasicSelect
                                placeholder="Status *"
                                options={rawProductStatus?.map((m: any) => ({
                                    label: m?.name,
                                    value: m?.id
                                }))}
                                selectFilterValue={formGroup.fields.status?.value}
                                onValueChange={e => updateState('status', e)}
                                loading={isLoading}
                                error={formGroup?.shouldDisplayError && !formGroup.fields.status?.isValid}
                                customStyle={{
                                    marginRight: "0"
                                }}
                                disabled
                            />
                        </Grid>
                        :
                        <></>
                }

                {
                    !isEmpty(rawProductDetails?.parentRawProduct) ?
                        <Grid item xs={12}>
                            <BasicSelect
                                placeholder="Produsul din care provine"
                                options={[{
                                    ...formGroup.fields.parentRawProduct?.value,
                                    value: formGroup.fields.parentRawProduct?.value?.id
                                }]}
                                selectFilterValue={formGroup.fields.parentRawProduct?.value}
                                onValueChange={e => updateState('parentRawProduct', e)}
                                loading={isLoading}
                                error={formGroup?.shouldDisplayError && !formGroup.fields.parentRawProduct?.isValid}
                                customStyle={{
                                    marginRight: "0"
                                }}
                                disabled
                                renderOption={(props, option) => (
                                    <Box component="li" {...props} key={option?.value}>
                                      <div>
                                        <div>{[option.productType.name]}/{[option.batch.name]}</div>
                                      </div>
                                    </Box>
                                )}
                                getOptionLabel={(option) => `${option.productType.name} / ${option.batch.name}`}
                            />
                        </Grid>
                        :
                        <></>
                }
  
                {
                    rawProductId ?
                        <Grid item xs={12}>
                            <TextFieldComponent
                                label={`Cantiate initiala ${isEmpty(formGroup.fields.productType?.value) ? '' : `(${formGroup.fields.productType?.value?.defaultMeasurementUnit?.name})`}`}
                                variant="outlined"
                                fullWidth={true}
                                value={formGroup.fields.initialQuantity?.value}
                                error={formGroup?.shouldDisplayError && !formGroup.fields.initialQuantity?.isValid}
                                onTextChange={(value: any) => updateState('initialQuantity', value)}
                                type="number"
                                required
                                disabled
                            />
                        </Grid>
                        :
                        <></>
                }

                {
                    rawProductId ?
                        <Grid item xs={12}>
                            <TextFieldComponent
                                label={`Cantiate folosita ${isEmpty(formGroup.fields.productType?.value) ? '' : `(${formGroup.fields.productType?.value?.defaultMeasurementUnit?.name})`}`}
                                variant="outlined"
                                fullWidth={true}
                                value={formGroup.fields.usedQuantity?.value}
                                error={formGroup?.shouldDisplayError && !formGroup.fields.usedQuantity?.isValid}
                                onTextChange={(value: any) => updateState('usedQuantity', value)}
                                type="number"
                                required
                                disabled
                            />
                        </Grid>
                        :
                        <></>
                } 

                <Grid item xs={12}>
                    <AsyncSelectComponent
                        label="Tip produs *"
                        url={'/ProductTypes/GetProductTypes'}
                        isAsync
                        propertyName='data'
                        value={formGroup.fields.productType?.value}
                        onOptionChange={e => updateState('productType', e)}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                        renderOption={(props, option) => (
                            <Box component="li" {...props} key={option?.value}>
                              <div>
                                <div>{[option.label]}</div>
                              </div>
                            </Box>
                        )}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.productType?.isValid}
                        disabled={!(formGroup.fields.status?.value?.value === 1 && formGroup.fields.usedQuantity?.value === 0) && !!rawProductId}
                    />
                </Grid>

                <Grid item xs={12}>
                    <label htmlFor="description">Descriere:</label>
                    <textarea
                        id="description"
                        className="text-area"
                        rows={3}
                        placeholder="Descriere"
                        value={formGroup.fields.description?.value ?? ""}
                        onChange={e => updateState('description', e.target.value)}
                    />
                </Grid>
                
                <Grid item xs={12}>
                    <AsyncSelectComponent
                        label="Lot *"
                        url={'/Batch/GetBatches'}
                        isAsync
                        propertyName='data'
                        value={formGroup.fields.batch?.value}
                        onOptionChange={e => updateState('batch', e)}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                        renderOption={(props, option) => (
                            <Box component="li" {...props} key={option?.value}>
                              <div>
                                <div>{[option.label]}</div>
                              </div>
                            </Box>
                        )}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.batch?.isValid}
                        disabled={!(formGroup.fields.status?.value?.value === 1 && formGroup.fields.usedQuantity?.value === 0) && !!rawProductId}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextFieldComponent
                        label={`Cantiate totala ${isEmpty(formGroup.fields.productType?.value) ? '' : `(${formGroup.fields.productType?.value?.defaultMeasurementUnit?.name})`}`}
                        variant="outlined"
                        fullWidth={true}
                        value={formGroup.fields.totalQuantity?.value}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.totalQuantity?.isValid}
                        onTextChange={(value: any) => updateState('totalQuantity', value)}
                        type="number"
                        required
                    />
                </Grid>  

                <Grid item xs={12}>
                    <FormControl
                        error={!formGroup.fields.canSlice?.value}
                    >
                        <FormControlLabel
                            control={<Checkbox
                                checked={formGroup.fields.canSlice?.value}
                                onChange={e => updateState('canSlice', e.target.checked)}
                            />}
                            label="Se poate transa"
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end"
                        }}
                    >
                        <ButtonComponent 
                            onClick={createAction} 
                            isLoading={isSaving}
                            sx={{
                                width: "5rem"
                            }}
                        >
                            Salveaza
                        </ButtonComponent>
                    </div>
                </Grid>
            </Grid>
        </RawProductsPanelStyled>
    )
});

export default RawProductsPanel;