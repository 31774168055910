import { observer } from "mobx-react-lite";
import RawProductsPageStyled from "./raw-products.page.styled";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../hooks/store.hook";
import { displayCustomToast } from "../../../../utils/display-custom-toast";
import { deleteRawProductCommand, getRawProducts } from "../../../../utils/requests";
import TableComponent, { ActionItemPropsType, CustomRendererPropsType, TableDataPropsType } from "../../../../components/table/table.component";
import { HeaderTypeEnum } from "../../../../enums/header-type.enum";
import PageTemplateComponent from "../../../../components/page-template/page-template.component";
import { TableTypeEnum } from "../../../../enums/table-type.enum";
import { PanelType } from "../../../../enums/panel-type.enum";
import RawProductsPanel from "../../../../components/raw-products-panel/raw-products-panel.component";
import { DialogResponseTypeEnum } from "../../../../enums/dialog-response-type.enum";
import { DeleteOutlined, EditOutlined } from "@mui/icons-material";
import TooltipComponent from "../../../../components/tooltip/tooltip.component";
import { isEmpty } from "lodash";
import { RawProductStatusDisplay, RawProductStatusEnum } from "../../../../enums/raw-product-status.enum";
import { Chip } from "@mui/material";

export const statusColor: any = {
    [RawProductStatusEnum.New]: "#A4ABB6",
    [RawProductStatusEnum.IsSlicing]: "#ffab24",
    [RawProductStatusEnum.Sliced]: "#00780e",
    [RawProductStatusEnum.InProgress]: "#882dff",
    [RawProductStatusEnum.PartialProcessed]: "#2DCCFF",
    [RawProductStatusEnum.Processed]: "#00E200",
}

const RawProductsPage = observer(() => {

    const [refreshData, setRefreshData] = useState<boolean>(true);
    const [data, setData] = useState<any>();
    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [filtersTable, setFilters] = useState<any>({
          pageIndex: 0,
          pageSize: 5,
        //   selectFilterValue: null,
        //   secondSelectFilterValue: null,
          tabFilterValue: undefined,
          searchText: null
    });
    const uiStore = useStore('uiStore');
    const [actionItemsClick, setActionItemsClick] = useState<any>();

    const createData = useCallback(
        () => {
            uiStore.openPanel({
                key: PanelType.CreateRawProduct,
                component: <RawProductsPanel />,
                panelWidth: '700px',
                title: `Creare`,
                onDismiss: (data: any) => {
                    if(!data) return;
                    
                    if(data?.refreshData)
                        setRefreshData(() => true);
                }
            });
        },
        []
    )

    useEffect(
        () => {
            async function getData() {
                try {
                    setDataLoading(() => true)
                    let fetchJobs = await getRawProducts({
                        pageIndex:filtersTable.pageIndex + 1,
                        pageSize:filtersTable.pageSize,
                        searchText:filtersTable.searchText,
                        statusIds: filtersTable.tabFilterValue
                    });
                    setData(() => fetchJobs)
                    setDataLoading(() => false)
                } catch (error: any) {
                    displayCustomToast(error.content, true);
                    setDataLoading(() => false)
                }
            }
            
            if(!refreshData) return;

            getData();
            setRefreshData(() => false);
            
        },
        [filtersTable, refreshData]
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!data) return {
                data: [],
                headers: []
            }

            return {
                data: data.data,
                headers: [
                   {
                        id: 'productType',
                        label: 'Nume',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'left',
                        sortable: false,
                        width: "10rem"
                    },
                   {
                        id: 'description',
                        label: 'Descriere',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false,                        
                        width: "50rem"
                    },
                   {
                        id: 'totalQuantity',
                        label: 'Cantitate totala',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false,                        
                        width: "10rem"
                    },
                   {
                        id: 'usedQuantity',
                        label: 'Cantitate folosita',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false,                        
                        width: "10rem"
                    },
                   {
                        id: 'measurementType',
                        label: 'U.M.',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false,                        
                        width: "20rem"
                    },
                   {
                        id: 'batch',
                        label: 'Lot',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false,
                        width: "10rem"
                    },
                   {
                        id: 'status',
                        label: 'Status',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false,
                        width: "10rem"
                    }
                ],
                totalElements: data.totalNumber
            }
        },
        [data]
    )

    const actionItems: ActionItemPropsType[] = useMemo(
        () => {
            return [
                {
                    text: "Editeaza",
                    icon: <EditOutlined />,
                    color: "black",
                    fOnClick: (row: any) => {
                        uiStore.openPanel({
                            key: PanelType.EditBatch,
                            component: <RawProductsPanel 
                                rawProductId={row.id}
                                rawProductDetails={row}
                            />,
                            panelWidth: '700px',
                            title: `Editeaza: ${row.productType.name}`,
                            onDismiss: (data: any) => {
                                if(!data) return;
                                
                                if(data?.refreshData)
                                    setRefreshData(() => true);
                            }
                        });
                    }
                },
                {
                    text: "Sterge",
                    icon: <DeleteOutlined />,
                    color: "red",
                    async fOnClick(row: any){
                        if(row) {   
                            const response = await uiStore.openDialog({
                                title: "Stergi...",
                                message: "Esti sigur ca vrei sa continui?",
                            })    

                            if(response.value === DialogResponseTypeEnum.Confirm) {
                                try {
                                    setDataLoading(() => true);
                                    await deleteRawProductCommand({
                                        id: row.id
                                    });
                                    displayCustomToast("Lotul a fost sters");
                                    setDataLoading(() => false);
                                    setRefreshData(() => true);
                                }
                                catch (error: any) {
                                    displayCustomToast(error.content, true);
                                    setDataLoading(() => false)
                                }
                            }    
                        } 
                    }
                }
            ]
        },
        [actionItemsClick]
    )

    const customRenderer: CustomRendererPropsType = {
        productType: (row: any) => {
            return (
                <>
                    {row.productType.name}
                </>
            )
        },
        batch: (row: any) => {
            return (
                <>
                    {row.batch.name}
                </>
            )
        },
        status: (row: any) => {
            return (
                <>
                    <Chip 
                        label={row.status.name} 
                        sx={{
                            color: statusColor[row.status.id],
                            border: `1px solid ${statusColor[row.status.id]}`
                        }}
                        variant="outlined" 
                    />
                </>
            )
        },
        description: (row: any) => {
            return (
                <>
                {
                    isEmpty(row.description) ?
                        "-"
                        :
                        <TooltipComponent title={row.description ?? ''} titleLimit={90} />
                }
                </>
            )
        },
        measurementType: (row: any) => {
            return (
                <>
                    {
                        row.productType.defaultMeasurementUnit.name
                    }
                </>
            )
        }
    }

    var tabsOptions = useMemo(
        () => {
            return [
                {
                    label: "Toate",
                    value: null,
                },
                {
                    label: RawProductStatusDisplay[RawProductStatusEnum.New],
                    value: [RawProductStatusEnum.New],
                },
                {
                    label: RawProductStatusDisplay[RawProductStatusEnum.InProgress],
                    value: [RawProductStatusEnum.InProgress],
                },
                {
                    label: RawProductStatusDisplay[RawProductStatusEnum.PartialProcessed],
                    value: [RawProductStatusEnum.PartialProcessed],
                },
                {
                    label: RawProductStatusDisplay[RawProductStatusEnum.Processed],
                    value: [RawProductStatusEnum.Processed],
                },
                {
                    label: RawProductStatusDisplay[RawProductStatusEnum.IsSlicing],
                    value: [RawProductStatusEnum.IsSlicing],
                },
                {
                    label: RawProductStatusDisplay[RawProductStatusEnum.Sliced],
                    value: [RawProductStatusEnum.Sliced],
                },
            ]
        },
        []
    )

    return (
        <RawProductsPageStyled>
            <PageTemplateComponent 
                title="Materii prime"
                buttons={[
                    {
                        fOnClick: () => {
                            createData()
                        },
                        text: "Creeaza"
                    }
                ]}
            >
                <TableComponent
                    tableKey={TableTypeEnum.AllRawProducts} 
                    viewType="normal"
                    tableData={tableData}
                    searchBarPlaceholder="Cauta dupa nume"
                    withoutSelect
                    isLoading={dataLoading}
                    tableState={filtersTable}
                    setTableState={setFilters}
                    actionItems={actionItems}
                    actionItemsClick={setActionItemsClick}
                    setRefreshData={setRefreshData}
                    customRenderer={customRenderer}
                    tabs={tabsOptions}
                />
            </PageTemplateComponent>
        </RawProductsPageStyled>
    )

});

export default RawProductsPage;