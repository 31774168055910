import styled from "styled-components";

const SelectProductTypesPanelStyled = styled.div`
    .button-container {
        margin-top: 1rem;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1.5rem;
    }
`;

export default SelectProductTypesPanelStyled;