export enum TableTypeEnum {
    AllCategories = "all-categories",
    AllIngredients = "all-ingredients",
    AllItems = "all-items",
    AllDeliveryRoutes = "all-delivery-routes",
    AllItemIngredients = "all-item-ingredients",
    AllPartners = "all-parnters",
    AllPartnerSalePoints = "all-partner-sale-points",
    AllPartnerUsers = "all-partner-users",
    SelectedItems = "selected-items",
    AllOrders = "all-orders",
    AllInsideUsers = "all-inside-users",
    CurrentDayOrdersReport = "current-day-order-raport",
    ProcessedOrders = "processed-orders",
    DailyObservations = "daily-observations",
    ReportExcel = "report-excel",
    FavoritePartnerItems = "favorite-partner-items",

    AllBatches = "all-batches",
    AllProductCategoryTypes = "all-product-category-types",
    AllProductTypes = "all-product-types",
    AllRawProducts = "all-raw-products",
    AllInventories = "all-inventory",
    AllReceipts = "all-receipts",
    AllItemReceipts = "all-item-receipts",
    InSlicingRawProducts = "in-slicing-raw-products",
    Logs = "logs",
}