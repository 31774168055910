import { observer } from "mobx-react-lite";
import SliceProcessPanelStyled from "./slice-process-panel.omponent.styled";
import { useCallback, useEffect, useMemo, useState } from "react";
import { StateFieldType } from "../../types/form.types";
import { Box, Chip, Grid } from "@mui/material";
import AsyncSelectComponent from "../async-select/async-select.component";
import { isEmpty } from "lodash";
import { updateRawProductStatus, getRawProductsList, updateSliceProcess } from "../../utils/requests";
import BasicSelect from "../dropdown-table-component/dropdown-table-component.component";
import TableComponent, { CustomRendererPropsType, TableDataPropsType } from "../table/table.component";
import { TableTypeEnum } from "../../enums/table-type.enum";
import { useStore } from "../../hooks/store.hook";
import { PanelType } from "../../enums/panel-type.enum";
import ButtonComponent from "../button/button.component";
import SelectProductTypesPanel from "./components/select-product-types-panel/select-product-types-panel.component";
import { HeaderTypeEnum } from "../../enums/header-type.enum";
import TextFieldComponent from "../text-field/text-field.component";
import { ProductCategoryTypes } from "../../enums/product-category-types.enum";
import { displayCustomToast } from "../../utils/display-custom-toast";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { RawProductStatusDisplay, RawProductStatusEnum } from "../../enums/raw-product-status.enum";
import { formatDateHourRo, formatDateRO } from "../../utils/utils";

type SliceProcessPanelPropsTypes = {
    rawProductInSlicingId?: number,
    rawProductInSlicingDetails?: any,
}

export type StateType = {
    fields: {
        productType: StateFieldType<any>;
        rawProduct: StateFieldType<any>;
        selectedProductTypes: StateFieldType<any[]>;
    };
    shouldDisplayError: boolean;
}

const SliceProcessPanel = observer(({
    rawProductInSlicingId,
    rawProductInSlicingDetails
}: SliceProcessPanelPropsTypes) => {

    // const [tableData, setTableData] = useState<any>();
    const [actionItemsClick, setActionItemsClick] = useState<any>();
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [formGroup, setFormGroup] = useState<StateType>({
        fields: {
            productType: {
                value: null,
                isValid: true,
                noValidation: true
            },
            rawProduct: {
                value: null,
                isValid: false,
                noValidation: false
            },
            selectedProductTypes: {
                value: [],
                isValid: isEmpty(rawProductInSlicingDetails),
                noValidation: isEmpty(rawProductInSlicingDetails)
            }
        },
        shouldDisplayError: false
    })
    const [filtersTable, setFilters] = useState<any>({
        pageIndex: 0,
        pageSize: 5,
      //   selectFilterValue: null,
      //   secondSelectFilterValue: null,
      //   tabFilterValue: undefined,
        searchText: null
    });
    const uiStore = useStore('uiStore');

    const [rawProducts, setRawProducts] = useState<any[]>([]);

    const isSliced = rawProductInSlicingDetails?.status?.id === RawProductStatusEnum.Sliced;
    const [refreshSelectedProductTypesTable, setRefreshSelectedProductTypesTable] = useState<boolean>(true);

    const updateState =  <T extends keyof any>(field: T, newValue: any) => {
        setFormGroup((state: any) => ({
            ...state,
            fields: {
                ...state.fields,
                [field]: {
                    ...state.fields[field],
                    isValid: isEmpty(newValue?.toString()) ? false : true,
                    value: newValue
                }
            }
        }))
    }

    const getRawProducts = async (productTypeId: number) => {

        const rawProducts = await getRawProductsList({
            productTypeId
        });

        setRawProducts(() => rawProducts);

        if(!isEmpty(rawProductInSlicingDetails)) {
            updateState('rawProduct', {
                label: rawProductInSlicingDetails.name,
                value: rawProductInSlicingDetails.id,
                ...rawProductInSlicingDetails
            })
        }
    }

    const getChildrenRawProducts = async (parentRawProductId: any) => {
        const rawProducts = await getRawProductsList({
            parentRawProductId
        });

        updateState('selectedProductTypes', rawProducts.map((m: any) => ({
            ...m,
            quantity: m.totalQuantity,
            defaultMeasurementUnit: {
                ...m.productType.defaultMeasurementUnit
            }
        })))
    }

    useEffect(
        () => {
            if(isEmpty(rawProductInSlicingDetails)) return;

            getChildrenRawProducts(rawProductInSlicingId)
        },
        [rawProductInSlicingDetails]
    )

    const customRenderer: CustomRendererPropsType = useMemo(
        () => {
            
            return {
                quantity: (row: any) => {
                    return (
                        <TextFieldComponent
                            label="Cantitate"
                            variant="outlined"
                            value={row.quantity}
                            error={row.error && row.quantity < 0}
                            onTextChange={value => {
                                updateState('selectedProductTypes', formGroup.fields.selectedProductTypes.value.map((m: any) => ({
                                    ...m,
                                    quantity: m.id === row.id ? 
                                        value
                                        : 
                                        m.quantity
                                })));
                            }}
                            type="number"
                            required
                            style={{
                                marginRight: 0
                            }}
                            width={120}
                            disabled={isSliced}
                        />
                    )
                },
                measurementUnit: (row: any) => {
                    return (
                        <>
                            {row.defaultMeasurementUnit?.name}
                        </>
                    )
                },                
                isNew: (row: any) => {
                    return (
                        <Chip 
                            label={row.isNew ? "Da" : "Nu"} 
                            color={row.isNew ? "success" : "error"}
                            variant="outlined" 
                        />
                    )
                },
                updatedOn: (row: any) => {
                    return (
                        <>               
                        {
                            !isEmpty(row.updatedOn) ?
                                <>
                                    {formatDateHourRo(row.updatedOn)} 
                                </>
                                :
                                <>
                                    -
                                </>
                        }         
                        </>
                    )
                }
            }
        },
        [formGroup.fields.selectedProductTypes.value]
    )

    useEffect(
        () => {
            if(!isEmpty(rawProductInSlicingDetails)) {
                updateState('productType', {
                    label: rawProductInSlicingDetails.productType.name,
                    value: rawProductInSlicingDetails.productType.id,
                    ...rawProductInSlicingDetails.productType
                })
            }
        },
        [rawProductInSlicingDetails]
    )

    useEffect(
        () => {
            if(isEmpty(formGroup.fields.productType.value)) return

            getRawProducts(formGroup.fields.productType.value?.value);
        },
        [formGroup.fields.productType.value]
    )

    const addProductTypes = useCallback(
        () => {
            uiStore.openPanel({
                key: PanelType.AddProductTypes,
                component: <SelectProductTypesPanel
                    selectedItems={formGroup.fields.selectedProductTypes.value}
                    setSelectedItems={(value) => {
                        updateState('selectedProductTypes', [
                            ...value.map((v: any) => ({
                                ...v,
                                quantity: formGroup.fields.selectedProductTypes.value.find(f => f.id === v.id)?.quantity ?? 0,
                                isError: false,
                                isNew: true,
                                productTypeId: v.id
                            })),                        
                            ...formGroup.fields.selectedProductTypes.value,
                        ].map((v: any, index: number) => ({
                            ...v,
                            id: v.isNew ? `newRawProduct${v.id}${index}` : v.id
                        })))

                        setRefreshSelectedProductTypesTable(() => true);
                }}
                />,
                panelWidth: '800px',
                title: `Creare`,
                onDismiss: (data: any) => {
                    setFilters((prevFilters: any) => ({
                        ...prevFilters,
                        pageIndex: 0
                    }))
                    if (!data) return;
                }
            });
        },
        [formGroup.fields.selectedProductTypes.value]
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!formGroup.fields.selectedProductTypes.value) return {
                data: [],
                headers: []
            }

            return {
                data: formGroup.fields.selectedProductTypes.value,
                headers: [
                    {
                         id: 'name',
                         label: 'Nume',
                         headerType: HeaderTypeEnum.String,
                         alignment: 'left',
                         sortable: false
                     },
                    {
                         id: 'measurementUnit',
                         label: 'U.M.',
                         headerType: HeaderTypeEnum.String,
                         alignment: 'center',
                         sortable: false
                     },
                    {
                         id: 'quantity',
                         label: 'Cantitate',
                         headerType: HeaderTypeEnum.String,
                         alignment: 'left',
                         sortable: false
                     },
                    {
                         id: 'isNew',
                         label: 'Noua',
                         headerType: HeaderTypeEnum.Boolean,
                         alignment: 'center',
                         sortable: false
                     },
                    {
                         id: 'updatedOn',
                         label: 'Actualizat la data de',
                         headerType: HeaderTypeEnum.Date,
                         alignment: 'center',
                         sortable: false
                     },
                ],
                totalElements: formGroup.fields.selectedProductTypes.value.length
            }
        },
        [formGroup.fields.selectedProductTypes.value]
    )

    const createAction = async () => {
        setIsSaving(() => true);
        if(!formGroup) return;

        const isNotValid = Object.values(formGroup.fields).filter((f: any) => f.noValidation === false).some((field: any) => !field.isValid);
        if (isNotValid) {
            setIsSaving(() => false);
            setFormGroup((prevState: any) => ({
                ...prevState,
                shouldDisplayError: true
            }))
            displayCustomToast("Datele nu sunt valide!", true);
            return;
        };

        let body = {
            rawProductId: formGroup.fields.rawProduct.value.id
        }

        try {
            if(rawProductInSlicingId != undefined){
                let bodyUpdate = {
                    productTypes: formGroup.fields.selectedProductTypes.value.map((m: any) => ({
                        productTypeId: m.productTypeId,
                        quantity: isEmpty(m.quantity.toString()) ? 0 : m.quantity,
                        isNew: m.isNew,
                        rawProductId: m.isNew ? null : m.id
                    })),
                    ...body
                }

                await updateSliceProcess(rawProductInSlicingId, bodyUpdate)
            }
            else {
                let boydUpdate = {
                    ...body,
                    rawProductStatusId: RawProductStatusEnum.IsSlicing
                }
                await updateRawProductStatus(formGroup.fields.rawProduct.value.id, boydUpdate);
            }

            setIsSaving(() => false);
            displayCustomToast("Datele au fost salvate cu succes!");
            uiStore.dismissPanel({
                data: {
                    refreshData: true
                }
            });
        } catch (error: any) {
            displayCustomToast(error.content, true);
            setIsSaving(() => false);
        }
    }

    return (
        <SliceProcessPanelStyled>
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <AsyncSelectComponent
                        label="Tip produs"
                        url={'/ProductTypes/GetProductTypes'}
                        filter={{
                            productCategoryTypeId: ProductCategoryTypes.Carne,
                        }}
                        isAsync
                        propertyName='data'
                        value={formGroup.fields.productType?.value}
                        onOptionChange={e => updateState('productType', e)}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                        renderOption={(props, option) => (
                            <Box component="li" {...props} key={option?.value}>
                                <div>
                                    <div>{[option.label]}</div>
                                </div>
                            </Box>
                        )}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.productType?.isValid}
                        disabled={!isEmpty(rawProductInSlicingDetails)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <BasicSelect
                        placeholder="Materie prima *"
                        options={rawProducts.map(m => ({
                            label: m?.name,
                            value: m?.id,
                            ...m
                        }))}
                        selectFilterValue={formGroup.fields.rawProduct?.value}
                        onValueChange={e => updateState('rawProduct', e)}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.rawProduct?.isValid}
                        customStyle={{
                            marginRight: "0"
                        }}
                        disabled={!isEmpty(rawProductInSlicingDetails)}
                    />
                </Grid>  

                {
                    !isEmpty(formGroup.fields.rawProduct.value) ?
                        <Grid item xs={12}>
                            <TextFieldComponent
                                label={`Cantiate totala`}
                                variant="outlined"
                                fullWidth={true}
                                value={formGroup.fields.rawProduct.value.totalQuantity}
                                type="number"
                                disabled
                            />
                        </Grid>
                        :
                        <></>  
                }

                {
                    !isEmpty(formGroup.fields.rawProduct.value) ?
                        <Grid item xs={12}>
                            <TextFieldComponent
                                label={`Unitate de masura`}
                                variant="outlined"
                                fullWidth={true}
                                value={formGroup.fields.rawProduct.value.productType.defaultMeasurementUnit.name}
                                disabled
                            />
                        </Grid>
                        :
                        <></>  
                }

                {
                    !isEmpty(rawProductInSlicingDetails) ? 
                        <Grid item xs={12}>
                            <div
                                style={{
                                    display: "flex"
                                }}
                            >
                                <ButtonComponent 
                                    onClick={addProductTypes}
                                    disabled={isSliced}
                                >
                                    Adauga produse
                                </ButtonComponent>
                            </div>
                        </Grid>
                        :
                        <></>
                }

                {
                    !isEmpty(rawProductInSlicingDetails) ? 
                        <Grid item xs={12}>
                            <TableComponent
                                tableKey={TableTypeEnum.AllRawProducts} 
                                viewType="panel"
                                tableData={tableData}
                                withoutSelect
                                withoutSearchBar
                                withoutDenseSwitch
                                denseByDefault
                                customRenderer={customRenderer}
                                actionItemsClick={setActionItemsClick}
                                noRequest
                                tableState={filtersTable}
                                setTableState={setFilters}
                            />
                        </Grid>
                        :
                        <></>
                }


                <Grid item xs={12}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end"
                        }}
                    >
                        <ButtonComponent 
                            onClick={createAction}
                            isLoading={isSaving}
                            disabled={isSliced}
                        >
                            Salveaza
                        </ButtonComponent>
                    </div>
                </Grid>

            </Grid>
        </SliceProcessPanelStyled>
    )
});

export default SliceProcessPanel;