import { observer } from "mobx-react-lite";
import ReceiptPanelStyled from "./receipt-panel.component.styled";
import { StateFieldType } from "../../types/form.types";
import { useEffect, useMemo, useState } from "react";
import { useStore } from "../../hooks/store.hook";
import { createReceiptCommand, editReceiptCommand, getDictionaryItems, getDictionaryReceiptProductQuantityTypes, getMeasurementUnit, getMeasurementUnitList, getReceipt } from "../../utils/requests";
import { displayCustomToast } from "../../utils/display-custom-toast";
import { FormHelperText, Grid, LinearProgress } from "@mui/material";
import BasicSelect from "../dropdown-table-component/dropdown-table-component.component";
import { cloneDeep, isEmpty } from "lodash";
import TextFieldComponent from "../text-field/text-field.component";
import ButtonComponent from "../button/button.component";
import { PanelType } from "../../enums/panel-type.enum";
import SelectReceiptsComponent from "./components/select-receipt-items/select-receipt-items.component";
import TableComponent, { CustomRendererPropsType, TableDataPropsType } from "../table/table.component";
import { TableTypeEnum } from "../../enums/table-type.enum";
import { themeVariables } from "../../theme/variables";
import { HeaderTypeEnum } from "../../enums/header-type.enum";
import { Add } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';

export type ReceiptPanelPropsTypes = {
    receiptId?: number,
    receiptDetails?: any
}

export type StateType = {
    fields: {
        name: StateFieldType<string>;
        estimatedQuantityPercentage: StateFieldType<number | null>;
        item: StateFieldType<any>,
        receiptProducts: StateFieldType<any[]>
    };
    shouldDisplayError: boolean;
}

const ReceiptPanel = observer(({
    receiptDetails,
    receiptId
}: ReceiptPanelPropsTypes) => {

    const [formGroup, setFormGroup] = useState<StateType>({
        fields: {
            name: {
                value: "",
                isValid: false,
                noValidation: false
            },
            estimatedQuantityPercentage: {
                value: 0,
                isValid: false,
                noValidation: false
            },
            item: {
                value: null,
                isValid: false,
                noValidation: false
            },
            receiptProducts: {
                value: [],
                isValid: false,
                noValidation: false
            }
        },
        shouldDisplayError: false
    });

    const uiStore = useStore('uiStore');
    const [filtersTable, setFilters] = useState<any>({
        pageIndex: 0,
        pageSize: 5,
      //   selectFilterValue: null,
      //   secondSelectFilterValue: null,
      //   tabFilterValue: undefined,
        searchText: null
    });
    const [actionItemsClick, setActionItemsClick] = useState<any>();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const [items, setItems] = useState<any[]>([]);
    const [measurementUnits, setMeasurementUnits] = useState<any[]>([]);
    const [receiptProductQuantityTypes, setReceiptProductQuantityTypes] = useState<any[]>([]);

    const updateState =  <T extends keyof any>(field: T, newValue: any) => {
        setFormGroup((state: any) => ({
            ...state,
            fields: {
                ...state.fields,
                [field]: {
                    ...state.fields[field],
                    isValid: isEmpty(newValue?.toString()) ? false : true,
                    value: newValue
                }
            }
        }))
    }

    useEffect(
        () => {
            if(receiptId === undefined) return;

            editReceiptData()
        },
        []
    )

    const getBasicSelectOptions = async () => {
        try {
            setIsLoading(() => true);

            let items = await getDictionaryItems();
            let measurementUnits = await getMeasurementUnitList();
            let receiptProductQuantityTypes = await getDictionaryReceiptProductQuantityTypes();

            setItems(() => items);
            setMeasurementUnits(() => measurementUnits);
            setReceiptProductQuantityTypes(() => receiptProductQuantityTypes);

            setIsLoading(() => false);

        } catch (error: any) {

            displayCustomToast(error.content, true);
            setIsLoading(() => false);
        }
    }

    const editReceiptData = async () => {
        await getBasicSelectOptions();

        let receipt = await getReceipt({
            id: receiptId
        });

        updateState('item', {
            label: receipt.item.name,
            value: receipt.item.id
        });
        updateState('estimatedQuantityPercentage', receipt.estimatedQuantityPercentage);
        updateState('name', receipt.name);
        
        updateState('receiptProducts', receipt.receiptProducts.map((r: any) => ({
            id: r.productType.id,
            name: r.productType.name,
            receiptProductQuantityType: {
                value: r.receiptProductQuantityType.id,
                label: r.receiptProductQuantityType.name
            },
            value: r.value,
            defaultMeasurementUnit: {
                ...r.defaultMeasurementUnit
            },
            productCategoryType: {
                ...r.productCategoryType
            }
        })));
        
    }

    useEffect(
        () => {
            if(receiptId !== undefined) return;

            getBasicSelectOptions();
        },
        []
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!formGroup.fields.receiptProducts.value) return {
                data: [],
                headers: []
            }

            return {
                data: formGroup.fields.receiptProducts.value,
                headers: [
                    {
                         id: 'name',
                         label: 'Nume',
                         headerType: HeaderTypeEnum.String,
                         alignment: 'left',
                         sortable: false
                     },
                    {
                         id: 'productCategoryType',
                         label: 'Categorie produs',
                         headerType: HeaderTypeEnum.String,
                         alignment: 'left',
                         sortable: false
                     },
                     
                    {
                        id: 'value',
                        label: 'Cantitate',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'left',
                        sortable: false
                    },
                    {
                         id: 'receiptProductQuantityType',
                         label: 'Tip reteta cantaite produs ',
                         headerType: HeaderTypeEnum.String,
                         alignment: 'left',
                         sortable: false
                     }
                 ],
                 totalElements: formGroup.fields.receiptProducts.value.length
            }
        },
        [formGroup.fields.receiptProducts.value]
    )

    const customRenderer: CustomRendererPropsType = useMemo(
        () => {
            
            return {
                receiptProductQuantityType: (row: any) => {
                    return (
                        <>
                            <BasicSelect
                                placeholder="Alege *"
                                options={receiptProductQuantityTypes?.map((m: any) => ({
                                    label: m?.name,
                                    value: m?.id
                                }))}
                                selectFilterValue={row.receiptProductQuantityType}
                                onValueChange={value => updateState('receiptProducts', formGroup.fields.receiptProducts.value.map((m: any) => ({
                                    ...m,
                                    receiptProductQuantityType: m.id === row.id ? value: m.receiptProductQuantityType
                                })))}
                                error={row.error && row.receiptProductQuantityType == null}
                                customStyle={{
                                    marginRight: "0"
                                }}
                                width={250}
                            />
                        </>
                    )
                },
                value: (row: any) => {
                    return (
                        <>
                            <TextFieldComponent
                                label="Cantitate"
                                variant="outlined"
                                value={row.value}
                                error={row.error && row.value < 0}
                                onTextChange={value => updateState('receiptProducts', formGroup.fields.receiptProducts.value.map((m: any) => ({
                                    ...m,
                                    value: m.id === row.id ? value: m.value
                                })))}
                                type="number"
                                required
                                style={{
                                    marginRight: 0
                                }}
                                width={120}
                            />
                        </>
                    )
                },
                productCategoryType: (row: any) => {
                    return (
                        <>
                            {row.productCategoryType.name}
                        </>
                    )
                }
            }
        },
        [measurementUnits, receiptProductQuantityTypes, formGroup.fields.receiptProducts]
    )

    const actionItems: any[] = useMemo(
        () => {
            return [
                {
                    text: "Elimina produsul",
                    icon: <CloseIcon color="error"  />,
                    color: "red",
                    fOnClick: (row: any) => {
                        setFormGroup((state: any) => ({
                            ...state,
                            fields: {
                                ...state.fields,
                                receiptProducts: {
                                    ...state.fields.receiptProducts,
                                    value: state.fields.receiptProducts.value.filter((f: any) => f.id != row.id)
                                }
                            }
                        }))
                    }
                }
            ]
        },
        [actionItemsClick, formGroup]
    );

    const onChooseProducts = () => {
        uiStore.openPanel({
            key: PanelType.AddProductOrder,
            title: "Selecteaza produsele",
            panelWidth: '800px',
            component: <SelectReceiptsComponent
                selectedItems={formGroup.fields.receiptProducts.value}
                setSelectedItems={(value) => updateState('receiptProducts', value.map((m: any) => ({
                    ...m,
                    value: formGroup.fields.receiptProducts.value?.find(f => f.id === m.id)?.value ?? 0,
                    receiptProductQuantityType: formGroup.fields.receiptProducts.value?.find(f => f.id === m.id)?.receiptProductQuantityType ?? null,
                    error: false
                })))}
            />,
            onDismiss: (data) => {
                setFilters((prevFilters: any) => ({
                    ...prevFilters,
                    pageIndex: 0
                }))
                if (!data) return;
            }
        })
    }

    const createAction = async () => {
        setIsSaving(() => true);
        if(!formGroup) return;

        const isNotValid = Object.values(formGroup.fields).filter((f: any) => f.noValidation === false).some((field: any) => !field.isValid);
        if (isNotValid) {
            setIsSaving(() => false);
            setFormGroup((prevState: any) => ({
                ...prevState,
                shouldDisplayError: true
            }))
            displayCustomToast("Datele nu sunt valide!", true);
            return;
        };

        let copyReceiptProducts = cloneDeep(formGroup.fields.receiptProducts.value);

        copyReceiptProducts = copyReceiptProducts.map((v: any) => {
            if(v.quantity < 0 || v.receiptProductQuantityType == null)
                return {
                    ...v,
                    error: true
                }
            else 
                return {
                    ...v,
                    error: false
                }
        })

        setFormGroup((prevState: any) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                receiptProducts: {
                    ...prevState.fields.receiptProducts,
                    value: prevState.fields.receiptProducts.value.map((v: any) => {
                        if(v.value < 0 || v.receiptProductQuantityType == null)
                            return {
                                ...v,
                                error: true
                            }
                        else 
                            return {
                                ...v,
                                error: false
                            }
                    })
                }
            }
        }))

        const isNotValidReceiptProducts = copyReceiptProducts.some((field: any) => field.error);
        
        if (isNotValidReceiptProducts) {
            setIsSaving(() => false);
            displayCustomToast("Datele nu sunt valide!", true);
            return;
        };

        const percentQuantitiesSum = copyReceiptProducts
            .filter(f => f.receiptProductQuantityType.value === 1)
            .map(m => parseFloat(m.value))
            .reduce((prev: any, current: any) => prev + current, 0);
        
        if(percentQuantitiesSum != 100) {
            setIsSaving(() => false);
            displayCustomToast("Suma cantitailor care sunt de tip procent trebuie sa fie egala cu 100", true);
            return;
        }

        let body = {
            itemId: formGroup.fields.item.value?.value,
            name: formGroup.fields.name.value,
            estimatedQuantityPercentage: formGroup.fields.estimatedQuantityPercentage.value,
            receiptProducts: formGroup.fields.receiptProducts.value.map((v: any) => ({
                productTypeId: v.id,
                receiptProductQuantityTypeId: v.receiptProductQuantityType.value,
                defaultMeasurementUnitId: v.defaultMeasurementUnit.id, 
                value: v.value
            }))
        }

        try {
            if(receiptId != undefined){
                let bodyUpdate = {
                    id: receiptId,
                    ...body
                }
                await editReceiptCommand(receiptId, bodyUpdate)
            }
            else
                await createReceiptCommand(body);

            setIsSaving(() => false);
            displayCustomToast("Datele au fost salvate cu succes!");
            uiStore.dismissPanel({
                data: {
                    refreshData: true
                }
            });
        } catch (error: any) {
            displayCustomToast(error.content, true);
            setIsSaving(() => false);
        }
    }

    return (
        <ReceiptPanelStyled>
            {
                isLoading ? (
                    <LinearProgress sx={{ borderRadius: "10px" }} />
                )
                :
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextFieldComponent
                            label="Nume"
                            variant="outlined"
                            fullWidth={true}
                            value={formGroup.fields.name?.value}
                            error={formGroup?.shouldDisplayError && !formGroup.fields.name?.isValid}
                            onTextChange={(value: any) => updateState('name', value)}
                            required
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <BasicSelect
                            placeholder="Produs *"
                            options={items?.map((m: any) => ({
                                label: m?.name,
                                value: m?.id
                            }))}
                            selectFilterValue={formGroup.fields.item?.value}
                            onValueChange={e => updateState('item', e)}
                            loading={isLoading}
                            error={formGroup?.shouldDisplayError && !formGroup.fields.item?.isValid}
                            customStyle={{
                                marginRight: "0"
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextFieldComponent
                            label="Procent de cantitate rezultată estimativ %"
                            variant="outlined"
                            fullWidth={true}
                            value={formGroup.fields.estimatedQuantityPercentage?.value}
                            error={formGroup?.shouldDisplayError && !formGroup.fields.estimatedQuantityPercentage?.isValid}
                            onTextChange={(value: any) => updateState('estimatedQuantityPercentage', value)}
                            type="number"
                            required
                        />
                    </Grid>

                    <Grid item xs={12}>
                        {
                            !isEmpty(formGroup.fields.receiptProducts.value) ?
                                <TableComponent
                                    tableKey={TableTypeEnum.SelectedItems} 
                                    viewType="panel"
                                    tableData={tableData}
                                    searchBarPlaceholder="Cauta dupa nume"
                                    withoutSelect
                                    tableState={filtersTable}
                                    setTableState={setFilters}
                                    withoutSearchBar
                                    withoutDenseSwitch
                                    denseByDefault
                                    customRenderer={customRenderer}
                                    actionItems={actionItems}
                                    actionItemsClick={setActionItemsClick}
                                    noRequest
                                />
                                :
                                <FormHelperText 
                                    sx={{ 
                                        fontSize: `${themeVariables.fontSizes.normal}`,
                                        marginLeft: 0,
                                        color: `${isEmpty(formGroup.fields.receiptProducts.value) && formGroup.shouldDisplayError ? '#cb5765' : 'rgba(0, 0, 0, 0.6)'}`
                                    }}
                                >
                                    Nu aveti produse selectate
                                </FormHelperText>
                        }
                    </Grid>

                    <Grid item xs={12}>
                        <ButtonComponent
                            variant="text"
                            onClick={onChooseProducts}
                            size="medium"
                            startIcon={<Add />}
                        >
                        {
                                isEmpty(formGroup.fields.receiptProducts?.value) ?
                                    "Selecteaza produse *"
                                    : 
                                    "Editeaza produsele *"
                        }
                        </ButtonComponent>
                    </Grid>

                    <Grid item xs={12}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginBottom: "1rem"
                            }}
                        >
                            <ButtonComponent 
                                onClick={createAction} 
                                isLoading={isSaving}
                                sx={{
                                    width: "5rem"
                                }}
                            >
                                Salveaza
                            </ButtonComponent>
                        </div>
                    </Grid>
                </Grid>

            }
        </ReceiptPanelStyled>
    )
});

export default ReceiptPanel;