import { observer } from "mobx-react-lite";
import DeliveryQuantityTabStyled from "./delivery-quantity-tab.component.styled";
import { DailyProductionDeliveryRouteDto, DeliveryQuantityTabPropsType } from "../../delivery-zone.page.types";
import { Box, Button, Container, Divider, Grid, LinearProgress, Link, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { addDailyObservationDeliveryRoute, getDailyObservationDeliveryRoute, getDailyProductionDeliveryRoute, getDeliveryRoutes, getDictionaryItems, submittedOrders } from "../../../../../../utils/requests";
import { displayCustomToast } from "../../../../../../utils/display-custom-toast";
import { themeVariables } from "../../../../../../theme/variables";
import ButtonComponent from "../../../../../../components/button/button.component";
import { useStore } from "../../../../../../hooks/store.hook";
import { PanelType } from "../../../../../../enums/panel-type.enum";
import GetItemObservationsModal from "../../../../../../components/get-item-observations-modal/get-item-observations-modal.component";
import AddDoneQuantityModal from "../../../../../../components/add-done-quantity-modal/add-done-quantity-modal.component";
import BasicSelect from "../../../../../../components/dropdown-table-component/dropdown-table-component.component";
import { isEmpty } from "lodash";
import AddDescriptionModal from "../../../../../../components/create-order-panel/components/add-description-modal/add-description-modal.dialog";
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';
import { DatePicker } from "@mui/x-date-pickers";
import TextFieldComponent from "../../../../../../components/text-field/text-field.component";
import { formatDate } from "../../../../../../utils/utils";

const DeliveryQuantityTab = observer(({
    selectedTabIndex
}: DeliveryQuantityTabPropsType) => {

    const [dailyProductionDeliveryRouteItems, setDailyProductionDeliveryRouteItems] = useState<DailyProductionDeliveryRouteDto[]>([]);
    const [processedOrdersNo, setProcessedOrdersNo] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const uiStore = useStore('uiStore');
    const [deliveryRoutes, setDeliveryRoutes] = useState<any[]>([]);
    const [selectedDeliveryRoute, setSelectedDeliveryRoute] = useState<any>(null);
    const [dailyObservation, setDailyObservation] = useState<any>(null);
    const [items, setItems] = useState<any[]>([]);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [targetDate, setTargetDate] = useState<any>(new Date());

    useEffect(
        () => {
            if(!uiStore.refreshDeliveryQuantityTab) return;
            setIsLoading(() => true);

            getDailyObservationDeliveryRoute({
                deliveryRouteId: selectedDeliveryRoute?.value
            })
            .then(data => {
                setDailyObservation(() => data);
            })
            .catch((error) => {
                displayCustomToast(error, true);
                setIsLoading(() => false)
            })

            getDailyProductionDeliveryRoute({
                deliveryRouteId: selectedDeliveryRoute?.value,
                targetDate: formatDate(targetDate)
            })
            .then(data => {
                setDailyProductionDeliveryRouteItems(() => data.dailyProductionDeliveryRouteItems);
                setProcessedOrdersNo(() => data.processedOrdersNo);
                setIsLoading(() => false)
                uiStore.setRefreshDeliveryQuantityTab(false);
            })
            .catch((error) => {
                displayCustomToast(error, true);
            })
        },
        [uiStore.refreshDeliveryQuantityTab]
    );

    useEffect(
        () => {
            if(selectedTabIndex !== 0) return;

            setIsLoading(() => true);

            getDailyObservationDeliveryRoute({
                deliveryRouteId: selectedDeliveryRoute?.value,
                targetDate: formatDate(targetDate)
            })
            .then(data => {
                setDailyObservation(() => data);
            })
            .catch((error) => {
                displayCustomToast(error, true);
                setIsLoading(() => false)
            })

            getDailyProductionDeliveryRoute({
                deliveryRouteId: selectedDeliveryRoute?.value,
                itemId: selectedItem?.value,
                targetDate: formatDate(targetDate)
            })
            .then(data => {
                setDailyProductionDeliveryRouteItems(() => data.dailyProductionDeliveryRouteItems);
                setProcessedOrdersNo(() => data.processedOrdersNo);
                setIsLoading(() => false)
            })
            .catch((error) => {
                displayCustomToast(error, true);
                setIsLoading(() => false)
            })
        },
        [selectedDeliveryRoute, selectedTabIndex, selectedItem, targetDate]
    );

    const finishOrdersAction = async () => {

        setIsLoading(() => true);
        try {
            await submittedOrders({
                targetDate: formatDate(targetDate)
            });
            setIsLoading(() => false);
            displayCustomToast("Comenzile au fost finalizate cu succes");
            uiStore.setRefreshDeliveryQuantityTab(true);
            
        } catch (error: any) {
            setIsLoading(() => false);
            displayCustomToast(error, true);
        }
    }

    const addDoneQuantity = (item: DailyProductionDeliveryRouteDto, minusQuantity: boolean = false) => {
        uiStore.openModal({
            title: `Adauga cantitatea terminata pe produsul: ${item.itemName}`,
            component: (
                <AddDoneQuantityModal
                    dailyProductionId={item.id}
                    maxQuantity={minusQuantity ? item.doneQuantity : item.remainedQuantity}
                    minusQuantity={minusQuantity}
                    isDailyProductionDelveryRoute
                />
            )
        })
    }

    const seeObservations = (item: DailyProductionDeliveryRouteDto) => {
        uiStore.openPanel({
            title: `Observatii pentru produsul: ${item.itemName}`,
            component: (
                <GetItemObservationsModal
                    itemId={item.itemId} 
                />
            ),
            key: PanelType.SeeItemObservations,
            panelWidth: "700px"
        })
    }

    useEffect(
        () => {
            getDeliveryRoutes()
            .then(data => {
                setDeliveryRoutes(() => data);
            })
            .catch(error => {
                displayCustomToast(error.content, true);
            })
        },
        []
    )

    
    useEffect(
        () => {
            getDictionaryItems()
            .then(data => {
                setItems(data);
            })
            .catch(error => {
                displayCustomToast(error.content, true);
            })
            
        },
        []
    )

    const addObservationDeliveryRoute = () => {
        uiStore.openModal({
            title: `Editează observațiile pentru ruta: ${selectedDeliveryRoute?.label}`,
            component: (
                <AddDescriptionModal
                    description={dailyObservation?.observations}
                    setDescription={(value) => setDailyObservation((prev: any) => ({
                        ...prev,
                        observations: value
                    }))}
                    action={async (value: string) => {
                        
                        if(isEmpty(value?.trim())) 
                            displayCustomToast("Nu se poate salva ");

                        try {
                            setIsLoading(() => true);
                            await addDailyObservationDeliveryRoute({
                                dailyObservationDeliveryRouteId: dailyObservation?.id,
                                deliveryRouteId: selectedDeliveryRoute?.value,
                                observations: value?.trim()
                            });
                            displayCustomToast("Detaliile au fost salvate!");
                            setIsLoading(() => false);
                            uiStore.setRefreshDeliveryQuantityTab(true);
                            uiStore.dismissModal();
                            
                        } catch (error: any) {
                            setIsLoading(() => false);
                            displayCustomToast(error, true);
                        }
                    }}
                /> 
            ),
        })
    }

    return (
        <DeliveryQuantityTabStyled>
            {
                isLoading ?
                    <LinearProgress style={{ borderRadius: "10px" }} />
                    :
                    <Box>

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginBottom: "1rem",
                                marginRight: "0.5rem"
                            }}
                        >
                            <Tooltip
                                title={`${!(selectedDeliveryRoute !== null) ? 
                                        'Pentru a adăuga observații trebuie să alegeți o rută de livrare' 
                                        : 
                                        isEmpty(dailyObservation) ?
                                            'Adăugați observații'
                                            :
                                            'Editați observațiile'
                                    }`}
                                arrow
                            >
                                <Box>
                                    <ButtonComponent 
                                        onClick={addObservationDeliveryRoute}
                                        disabled={!(selectedDeliveryRoute !== null)}
                                    > 
                                        {
                                            isEmpty(dailyObservation) ?
                                                'Adăugați observații'
                                                :
                                                'Editați observațiile'
                                        }
                                    </ButtonComponent>
                                </Box>
                            </Tooltip>
                        </Box>
                        
                        <Box
                            width="100%"
                            marginBottom="1rem"
                        >
                            <DatePicker
                                value={targetDate}
                                renderInput={(params) => <TextFieldComponent 
                                    {...params} 
                                    fullWidth={true} 
                                    variant="outlined" 
                                    label="Selectează zi"
                                />}
                                onChange={newValue =>{ 
                                    setTargetDate(() => newValue)
                                    uiStore.setRefreshProductionZone(true);
                                }}
                                mask="__.__.____"
                                
                            />
                        </Box>

                        <Box
                            marginBottom="1rem"
                        >
                            <BasicSelect
                                placeholder='Produse'
                                options={items?.map(p => ({
                                    label: p.name,
                                    value: p.id
                                }))}
                                selectFilterValue={selectedItem ?? null}
                                onValueChange={setSelectedItem}
                                customStyle={{
                                    marginRight: "0"
                                }}
                            />
                        </Box>

                        <Box
                            marginBottom="2rem"
                        >
                            <BasicSelect
                                placeholder='Rută de livrare'
                                options={deliveryRoutes?.map(p => ({
                                    label: p.name,
                                    value: p.id
                                }))}
                                selectFilterValue={selectedDeliveryRoute ?? null}
                                onValueChange={setSelectedDeliveryRoute}
                                customStyle={{
                                    marginRight: "0"
                                }}
                            />
                        </Box>

                        <Typography
                            variant="h5"
                            fontWeight={themeVariables.fontWeights.bold}
                            marginBottom="1rem"
                        >
                            Cantități în curs de finalizare:
                        </Typography>
                        <Grid 
                            container 
                            spacing={{ xs: 2, md: 3 }} 
                            columns={{ xs: 12, sm: 12, md: 12 }}
                            marginBottom="1rem"
                        >
                            {
                                dailyProductionDeliveryRouteItems?.filter(f => f.remainedQuantity > 0)
                                .length === 0 ?
                                    <Box
                                        sx={{
                                            width: "100%",
                                            padding: "2rem"
                                        }}
                                    >
                                        {
                                                processedOrdersNo === 0 ?
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        marginTop: "1rem"
                                                    }}
                                                >
                                                    <Typography
                                                        variant="h5"
                                                        fontWeight={themeVariables.fontWeights.bold}
                                                    >
                                                        Nu există comenzi în curs de finalizare
                                                    </Typography>
                                                </Box>
                                                : 
                                                selectedDeliveryRoute === null && selectedItem === null ?
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            marginTop: "1rem"
                                                        }}
                                                    >
                                                        <Box>
                                                            <Typography
                                                                variant="h5"
                                                                fontWeight={themeVariables.fontWeights.bold}
                                                            >
                                                                Nu mai sunt alte cantități în lucru. Pentru a continua trebuie să finalizați comenzile:
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    marginTop: "2rem"
                                                                }}
                                                            >
                                                                <ButtonComponent 
                                                                    onClick={finishOrdersAction}
                                                                > 
                                                                    Finalizează comenzile
                                                                </ButtonComponent>
                                                            </Box>
                                                                
                                                        </Box>
                                                            
                                                    </Box>
                                                    :
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            marginTop: "1rem"
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="h5"
                                                            fontWeight={themeVariables.fontWeights.bold}
                                                        >
                                                            Nu există cantități în curs de finalizare pe această rută
                                                        </Typography>
                                                    </Box>
                                        }
                                    </Box>
                                    :
                                    dailyProductionDeliveryRouteItems
                                    .filter(f => f.remainedQuantity > 0)
                                    .map((item, index) => (
                                        <Grid 
                                            item 
                                            xs={6} 
                                            sm={3} 
                                            md={2} 
                                            key={index}
                                        >
                                            <Container
                                                className="item-card item-card-undone"
                                            >
                                                <Box>
                                                    <Link
                                                        color="#fff"
                                                        sx={{
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={() => {    
                                                            setSelectedDeliveryRoute(() => ({
                                                                label: item.deliveryRouteName,
                                                                value: item.deliveryRouteId
                                                            }))
                                                        }}
                                                    >
                                                        <Typography
                                                            fontSize={themeVariables.fontSizes.xSmall}
                                                            fontWeight={themeVariables.fontWeights.semibold}
                                                        >
                                                            Ruta: {item.deliveryRouteName}
                                                        </Typography>
                                                    </Link>
                                                </Box>

                                                <Container
                                                    className="container-styled"
                                                    sx={{
                                                        marginBottom: "1rem",                                                        
                                                        marginTop: "0.5rem",
                                                        display: "flex",
                                                        flexDirection: "column"
                                                    }}
                                                >
                                                    <Typography
                                                        fontSize={themeVariables.fontSizes.smaller}
                                                        fontWeight={themeVariables.fontWeights.bold}
                                                    >
                                                        {item.itemName}
                                                    </Typography>
                                                    <Container
                                                        sx={{
                                                            padding: "0 !important",
                                                            width: "fit-content",
                                                            margin: 0,
                                                        }}          
                                                    >
                                                        <Typography
                                                            fontSize={themeVariables.fontSizes.smaller}
                                                        >
                                                            Cantitate: {item.remainedQuantity}
                                                        </Typography>
                                                    </Container>
                                                </Container>

                                                <Box
                                                    display="flex"
                                                    flexDirection="column"
                                                >
                                                    <Button
                                                        startIcon={<InfoIcon fontSize="small" />}
                                                        onClick={() => {
                                                            if(!item.hasObservations) return;
                                                            seeObservations(item);
                                                        }}
                                                        sx={{
                                                            opacity: `${item.hasObservations ?  1 : 0}`,
                                                            cursor: `${item.hasObservations ? "pointer" : "default !important"}`,
                                                            marginBottom: "0.5rem",
                                                            fontSize: `${themeVariables.fontSizes.xxSmall}`
                                                        }}
                                                        variant="contained"
                                                        size="small"
                                                    >
                                                        Observații
                                                    </Button>
                                                    <Button
                                                        startIcon={<AddIcon fontSize="small" />}
                                                        onClick={() => addDoneQuantity(item)}
                                                        variant="contained"
                                                        size="small"
                                                        sx={{
                                                            fontSize: `${themeVariables.fontSizes.xxSmall}`
                                                        }}
                                                    >
                                                        Cantitate
                                                    </Button>
                                                </Box>
                                            </Container>
                                        </Grid>
                                    ))
                            }
                        </Grid>
                        <Divider
                            component="div" 
                            role="presentation"
                            className="divider-style"
                        />
                        <Typography
                            variant="h5"
                            fontWeight={themeVariables.fontWeights.bold}
                            marginBottom="1rem"
                        >
                            Cantități finalizate:
                        </Typography>
                        <Grid 
                            container 
                            spacing={{ xs: 2, md: 3 }} 
                            columns={{ xs: 12, sm: 12, md: 12 }}
                            marginTop="1rem"
                        >
                            {
                                dailyProductionDeliveryRouteItems
                                .filter(f => f.doneQuantity !=0)
                                .length === 0 ?
                                    <Container
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            marginTop: "1rem"
                                        }}
                                    >
                                        <Typography
                                            variant="h5"
                                            fontWeight={themeVariables.fontWeights.bold}
                                        >
                                            Nu există cantități finalizate
                                        </Typography>
                                    </Container>
                                    :
                                    dailyProductionDeliveryRouteItems
                                    .filter(f => f.doneQuantity !=0)
                                    .map((item, index) => (
                                        <Grid 
                                            item 
                                            xs={6} 
                                            sm={3} 
                                            md={2}  
                                            key={index}
                                        >
                                            <Container
                                                className="item-card item-card-done"
                                                sx={{
                                                    marginBottom: "1rem"
                                                }}
                                            >

                                                <Box>
                                                    <Link
                                                        color="#fff"
                                                        sx={{
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={() => {    
                                                            setSelectedDeliveryRoute(() => ({
                                                                label: item.deliveryRouteName,
                                                                value: item.deliveryRouteId
                                                            }))
                                                        }}
                                                    >
                                                        <Typography
                                                            fontWeight={themeVariables.fontWeights.semibold}
                                                            fontSize={themeVariables.fontSizes.xSmall}
                                                        >
                                                            Ruta: {item.deliveryRouteName}
                                                        </Typography>
                                                    </Link>
                                                </Box>

                                                <Container
                                                    className="container-styled"
                                                    sx={{
                                                        marginBottom: "1rem",
                                                        marginTop: "0.5rem",
                                                        display: "flex",
                                                        flexDirection: "column"
                                                    }}
                                                >
                                                    <Typography
                                                        fontSize={themeVariables.fontSizes.smaller}
                                                        fontWeight={themeVariables.fontWeights.bold}
                                                    >
                                                        {item.itemName}
                                                    </Typography>
                                                    <Container
                                                        sx={{
                                                            padding: "0 !important",
                                                            width: "fit-content",
                                                            margin: 0
                                                        }}          
                                                    >
                                                        <Container
                                                            sx={{
                                                                padding: "0 !important"
                                                            }}
                                                        >
                                                            <Typography
                                                                fontSize={themeVariables.fontSizes.smaller}
                                                            >
                                                                Cantitate: {item.doneQuantity}
                                                            </Typography>
                                                        </Container>
                                                    </Container>
                                                </Container>
                                                
                                                <Box
                                                   display="flex"
                                                   flexDirection="column"
                                                >
                                                    <Button
                                                        startIcon={<InfoIcon fontSize="small" />}
                                                        onClick={(event) => {
                                                            if(!item.hasObservations) return;
                                                            seeObservations(item);
                                                        }}
                                                        sx={{
                                                            opacity: `${item.hasObservations ?  1 : 0}`,
                                                            cursor: `${item.hasObservations ? "pointer" : "default !important"}`,
                                                            marginBottom: "0.5rem",
                                                            fontSize: `${themeVariables.fontSizes.xxSmall}`
                                                        }}
                                                        variant="contained"
                                                        size="small"
                                                    >
                                                        Observații
                                                    </Button>
                                                    <Button
                                                        startIcon={<AddIcon fontSize="small" />}
                                                        onClick={() => {
                                                            if(processedOrdersNo === 0) return;
                                                            addDoneQuantity(item, true)
                                                        }}
                                                        variant="contained"
                                                        size="small"
                                                        sx={{
                                                            fontSize: `${themeVariables.fontSizes.xxSmall}`,
                                                            opacity: `${processedOrdersNo ?? 0 > 0 ?  1 : 0}`,
                                                            cursor: `${processedOrdersNo ?? 0 > 0 ? "pointer" : "default !important"}`
                                                        }}
                                                    >
                                                        Cantitate
                                                    </Button>
                                                </Box>
                                            </Container>

                                        </Grid>
                                ))
                            }
                        </Grid>
                    </Box>
            }
        </DeliveryQuantityTabStyled>
    )
});

export default DeliveryQuantityTab;