import { observer } from "mobx-react-lite";
import BatchPanelComponent from "./batch-panel.component.styled";
import { StateFieldType } from "../../types/form.types";
import { useEffect, useState } from "react";
import { useStore } from "../../hooks/store.hook";
import { displayCustomToast } from "../../utils/display-custom-toast";
import { createBatchCommand, editBatchCommand, getBatchTypes } from "../../utils/requests";
import { Grid } from "@mui/material";
import TextFieldComponent from "../text-field/text-field.component";
import { isEmpty } from "lodash";
import BasicSelect from "../dropdown-table-component/dropdown-table-component.component";
import ButtonComponent from "../button/button.component";
import { DialogResponseTypeEnum } from "../../enums/dialog-response-type.enum";

export type BatchPanelPropsTypes = {
    batchId?: number,
    batchDetails?: any
}

export type StateType = {
    fields: {
        name: StateFieldType<string>;
        description: StateFieldType<any>;
        type: StateFieldType<any>;

    };
    shouldDisplayError: boolean;
}

const BatchPanel = observer(({
    batchDetails,
    batchId
}: BatchPanelPropsTypes) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [formGroup, setFormGroup] = useState<StateType>({
        fields: {
            name: {
                value: "",
                isValid: false,
                noValidation: false
            },
            description: {
                value: "",
                isValid: true,
                noValidation: true
            },
            type: {
                value: null,
                isValid: false,
                noValidation: false
            },
        },
        shouldDisplayError: false
    })
    const uiStore = useStore('uiStore');

    const [batchTypes, setBatchTypes] = useState<any>();

    const updateState =  <T extends keyof any>(field: T, newValue: any) => {
        setFormGroup((state: any) => ({
            ...state,
            fields: {
                ...state.fields,
                [field]: {
                    ...state.fields[field],
                    isValid: isEmpty(newValue) ? false : true,
                    value: newValue
                }
            }
        }))
    }

    // Editare
    useEffect(
        () => {
            if(batchId === undefined) return;
            
            updateState('name', batchDetails.name);
            updateState('description', batchDetails.description);
            updateState('type', {
                label: batchDetails.type.name,
                value: batchDetails.type.id
            });
            
        },
        [batchDetails, batchId]
    )

    useEffect(
        () => {
            setIsLoading(() => true);

            getBatchTypes()
            .then(data => {
                setBatchTypes(() => data)
                setIsLoading(() => false);
            })
            .catch(error => {
                displayCustomToast(error.content, true);
                setIsLoading(() => false);
            })
        },
        []
    )

    const createBatchAction = async () => {
        setIsSaving(() => true);
        if(!formGroup) return;

        const isNotValid = Object.values(formGroup.fields).filter((f: any) => f.noValidation === false).some((field: any) => !field.isValid);
        if (isNotValid) {
            setIsSaving(() => false);
            setFormGroup((prevState: any) => ({
                ...prevState,
                shouldDisplayError: true
            }))
            displayCustomToast("Datele nu sunt valide!", true);
            return;
        };

        let body = {
            name: formGroup.fields.name.value,
            description: formGroup.fields.description.value,
            typeId: formGroup.fields.type.value.value,
        }

        try {
            if(batchId != undefined){
                let bodyUpdate = {
                    id: batchId,
                    name: formGroup.fields.name.value,
                    typeId: formGroup.fields.type.value.value,
                    description: formGroup.fields.description.value,
                }

                if(batchDetails.isAssigned) {
                    const response = await uiStore.openDialog({
                        title: "Lotul este deja atribuit...",
                        message: "Esti sigur ca vrei sa continui?",
                    })    

                    if(response.value === DialogResponseTypeEnum.Confirm) {   
                        await editBatchCommand(batchId, bodyUpdate)
                        displayCustomToast("Datele au fost salvate cu succes!");
                        
                        uiStore.dismissPanel({
                            data: {
                                refreshData: true
                            }
                        });
                    }   
                } else {
                    await editBatchCommand(batchId, bodyUpdate)
                    displayCustomToast("Datele au fost salvate cu succes!");
                    
                    uiStore.dismissPanel({
                        data: {
                            refreshData: true
                        }
                    });
                }
            }
            else {
                await createBatchCommand(body);                
                displayCustomToast("Datele au fost salvate cu succes!");
                
                uiStore.dismissPanel({
                    data: {
                        refreshData: true
                    }
                });
            }

            setIsSaving(() => false);
        } catch (error: any) {
            displayCustomToast(error.content, true);
            setIsSaving(() => false);
        }
    } 

    return (
        <BatchPanelComponent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextFieldComponent
                        label="Nume"
                        variant="outlined"
                        fullWidth={true}
                        value={formGroup.fields.name?.value}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.name?.isValid}
                        onTextChange={(value: any) => updateState('name', value)}
                        style={{}}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <label htmlFor="description">Descriere:</label>
                    <textarea
                        id="description"
                        className="text-area"
                        rows={3}
                        placeholder="Descriere"
                        value={formGroup.fields.description?.value ?? ""}
                        onChange={e => updateState('description', e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <BasicSelect
                        placeholder="Tip *"
                        options={batchTypes?.map((m: any) => ({
                            label: m?.name,
                            value: m?.id
                        }))}
                        selectFilterValue={formGroup.fields.type?.value}
                        onValueChange={e => updateState('type', e)}
                        loading={isLoading}
                        error={formGroup?.shouldDisplayError && !formGroup.fields.type?.isValid}
                        customStyle={{
                            marginRight: "0"
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end"
                        }}
                    >
                        <ButtonComponent 
                            onClick={createBatchAction} 
                            isLoading={isSaving}
                            sx={{
                                width: "5rem"
                            }}
                        >
                            Salveaza
                        </ButtonComponent>
                    </div>
                </Grid>
            </Grid>
        </BatchPanelComponent>
    )
});

export default BatchPanel;