import styled from "styled-components";
import { themeVariables } from "../../../../theme/variables";

const SelectReceiptsComponentStyled = styled.div`
    .product-name {
        color: gray;
        font-size: ${themeVariables.fontSizes.smaller};
    }

    .button-container {
        margin-top: 1rem;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1.5rem;
    }
`;

export default SelectReceiptsComponentStyled;